import * as React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import Rating from './Rating';
import HeartButton from 'components/elements/HeartButton';
import Checkbox from 'components/elements/Checkbox';
import './Thumbnail.scss';

interface ThumbnailProps {
  src: string | null;
  alt: string;
  caption?: string | React.ReactChild;
  subcaption?: string;
  variant?: 'rectangle' | 'square' | 'original';
  size?: 'small' | 'default';
  background?: 'default' | 'dark';
  className?: string;
  rating?: number;
  isFavourite?: boolean;
  markFavouritePhoto?: () => void;
  showCheckbox?: boolean;
  isChecked?: boolean;
  linkTo?: string;
  onCheckboxChange?: (e: React.MouseEvent) => void;
  onImageClick?: (e: React.MouseEvent) => void;
}
const Thumbnail = (props: ThumbnailProps) => {
  const {
    src,
    alt,
    isFavourite,
    markFavouritePhoto,
    caption = '',
    subcaption = '',
    variant = 'rectangle',
    size = 'default',
    className = '',
    background = 'default',
    rating = null,
    showCheckbox = false,
    isChecked = false,
    linkTo = '',
    onCheckboxChange = (e: React.MouseEvent) => {},
    onImageClick = (e: React.MouseEvent) => {},
  } = props;

  const image = (
    <div
      className={classNames('image-container', {
        'no-image': !src,
      })}
      onClick={onImageClick}
    >
      {src && <img src={src} alt={alt} />}
    </div>
  );

  return (
    <figure
      className={classNames(
        'Thumbnail',
        variant,
        className,
        `size-${size}`,
        `background-${background}`,
      )}
    >
      {!!linkTo ? <Link to={linkTo}>{image}</Link> : image}
      <div className="bottom-row">
        <div className="left-group">
          {!!showCheckbox && (
            <Checkbox onChange={onCheckboxChange} checked={isChecked} />
          )}
          {(caption || subcaption) && (
            <figcaption>
              {caption}
              {subcaption && <small>{subcaption}</small>}
            </figcaption>
          )}
        </div>
        {rating !== null && <Rating value={rating} />}

        {isFavourite && !!markFavouritePhoto && (
          <HeartButton
            inline
            fill={isFavourite}
            className="thumbnail-favourite"
          />
        )}
      </div>
    </figure>
  );
};
export default Thumbnail;
