import { gql } from '@apollo/client';
import { PHOTO_FRAGMENT } from 'types/photos';
import { PHOTOSHOOT_FREE_PRODUCT_FRAGMENT } from 'types/photoshoots';

export const ADMIN_GET_USERS = gql`
  query AdminGetUsers {
    users {
      id
      displayName
      email
      isAdmin
    }
  }
`;

export const ADMIN_GET_USER = gql`
  query AdminGetUser($id: Int!) {
    user(id: $id) {
      id
      displayName
      email
      isAdmin
      firstName
      lastName
      addressDetails {
        firstName
        lastName
        companyName
        streetAddress
        postalCode
        postalLocation
        phoneNumber
      }
      photoshoots {
        id
        name
        slug
        thumbnailUrl
        photos {
          id
        }
      }
    }
  }
`;

export const ADMIN_GET_PHOTOSHOOTS = gql`
  query AdminGetPhotoshoots($searchInput: String) {
    photoshoots(searchInput: $searchInput) {
      id
      slug
      name
      user {
        displayName
      }
    }
  }
`;

export const ADMIN_GET_PAGINATED_PHOTOSHOOTS = gql`
  query AdminGetPaginatedPhotoshoots(
    $searchInput: String
    $page: Int
    $pageSize: Int
    $filterWithoutOrders: Boolean
  ) {
    paginatedPhotoshoots(
      searchInput: $searchInput
      page: $page
      pageSize: $pageSize
      filterWithoutOrders: $filterWithoutOrders
    ) {
      photoshoots {
        id
        slug
        name
        publishedAt
        user {
          displayName
        }
      }
      pagination {
        currentPage
        totalPages
        pageSize
        recordCount
      }
    }
  }
`;

export const ADMIN_GET_PHOTOSHOOT = gql`
  query AdminGetPhotoshoot($id: Int!) {
    photoshoot(id: $id) {
      id
      slug
      name
      fastOrderDiscountEnabled
      user {
        displayName
      }
      priceList {
        id
        code
        name
      }
      photos {
        ...Photo
      }
      freeProducts {
        ...PhotoshootFreeProduct
      }
      message
    }
  }
  ${PHOTO_FRAGMENT}
  ${PHOTOSHOOT_FREE_PRODUCT_FRAGMENT}
`;

export const ADMIN_GET_ORDERS = gql`
  query AdminGetOrders {
    orders {
      __typename
      id
      quantity
      totalPrice
      totalVat
      submittedAt
      processedAt
      status
      orderedGallery
      user {
        id
        displayName
        email
      }
    }
  }
`;

export const ADMIN_GET_ORDER = gql`
  query AdminGetOrder($id: Int!) {
    order(id: $id) {
      __typename
      id
      quantity
      totalPrice
      totalVat
      totalRetouchingFee
      submittedAt
      processedAt
      status
      orderedGallery
      orderedGalleryFee
      additionalInfo
      addressDetails {
        firstName
        lastName
        companyName
        streetAddress
        postalCode
        postalLocation
        phoneNumber
      }
      user {
        id
        displayName
        firstName
        lastName
        email
        addressDetails {
          firstName
          lastName
          companyName
          streetAddress
          postalCode
          postalLocation
          phoneNumber
        }
      }
      discounts {
        type
        name
        percentage
      }
      items {
        id
        quantity
        price
        vat
        photoColor
        product {
          category {
            name
          }
          properties {
            keyName
            key
            name
          }
        }
        orderItemPhotos {
          photo {
            code
            previewUrl
            photoshoot {
              name
            }
          }
          photoColor
        }
      }
      orderPhotos {
        photo {
          code
          previewUrl
          photoshoot {
            name
          }
        }
        needsRetouching
        retouchingFee
      }
    }
  }
`;

export const ADMIN_GET_PRODUCT_CATEGORIES = gql`
  query AdminGetProductCategories {
    productCategories {
      id
      name
      description
      image {
        url
      }
      priceList {
        id
      }
      availableProperties {
        key
        name
        values {
          value
          name
          isDefault
        }
      }
      products {
        id
        minQuantity
        image {
          url
        }
        properties {
          key
          keyName
          value
          name
          isDefault
        }
        priceGroups {
          minQuantity
          unitPrice
        }
      }
    }
  }
`;

export const ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES = gql`
  query AdminGetPriceListsWithCategories {
    priceLists {
      id
      code
      name
      productCategories {
        id
        name
        description
        image {
          url
        }
        priceList {
          id
        }
        availableProperties {
          key
          name
          values {
            value
            name
            isDefault
          }
        }
        products {
          id
          minQuantity
          minPhotos
          maxPhotos
          image {
            url
          }
          properties {
            key
            keyName
            value
            name
            isDefault
          }
          priceGroups {
            minQuantity
            unitPrice
          }
        }
      }
    }
  }
`;

export const ADMIN_GET_PRICE_LISTS = gql`
  query AdminGetPriceLists {
    priceLists {
      id
      code
      name
    }
  }
`;
