import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { Link, useMatch } from '@reach/router';
import { gql, useQuery } from '@apollo/client';

//import 'bootstrap-icons/font/bootstrap-icons.css';

import './AdminLayout.scss';
import { AdminGetCurrentUser } from './__generated__/AdminGetCurrentUser';
import { CURRENT_USER_FRAGMENT } from '../../types/users';
import LoadingIndicator from '../../components/LoadingIndicator';

const ADMIN_GET_CURRENT_USER = gql`
  query AdminGetCurrentUser {
    me {
      ...CurrentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

interface LinksProps {
  links: string[][];
}
const AdminLayout: React.FC<LinksProps> = ({ links, children }) => {
  const { data, loading } = useQuery<AdminGetCurrentUser>(
    ADMIN_GET_CURRENT_USER,
  );
  const isAdmin = data?.me?.isAdmin;
  if (loading) {
    return (
      <div className="AdminLayout">
        <Container fluid>
          <LoadingIndicator />
        </Container>
      </div>
    );
  }
  if (!isAdmin) {
    return (
      <div className="AdminLayout">
        <Container fluid>Admin user required.</Container>
      </div>
    );
  }
  return (
    <div className="AdminLayout">
      <Container fluid>
        <Row>
          <Col as="aside" sm={3} lg={2} className="admin-sidebar-container">
            <AdminSidebar links={links} />
          </Col>
          <Col as="section" sm={9} lg={10} className="admin-main">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const AdminSidebar = ({ links }: LinksProps) => {
  const match = useMatch(':part/*');
  const activePart = match?.part || '';

  return (
    <div className="AdminSidebar my-2">
      <Nav
        defaultActiveKey={activePart}
        variant="pills"
        className="flex-column"
      >
        <Nav.Item>
          <Nav.Link as={Link} to="" eventKey="">
            Hallinta
          </Nav.Link>
        </Nav.Item>
        {links.map(([urlPart, title]) => (
          <Nav.Item key={urlPart}>
            <Nav.Link as={Link} to={urlPart} eventKey={urlPart}>
              {title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};
export default AdminLayout;
