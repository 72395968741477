import * as React from 'react';

export interface Position {
  x: number;
  y: number;
}

/**
 * Replicates useState functionality with the addition of localStorage
 * for session persistence.
 *
 * @param {string} key - localStorage cache key.
 * @param {Object} initialValue - JSON object or any other value to initialize the state with.
 */
export const useLocalStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
};

/**
 * Tracks mouse cursor or touch position and returns the current value pair.
 *
 * @param {boolean} condition - set to "false" to pause state updates and cursor tracking.
 */
export const useMousePosition = (condition: boolean = true) => {
  const [position, setPosition] = React.useState<Position>({ x: 0, y: 0 });

  React.useEffect(() => {
    const setMousePosition = (e: MouseEvent) =>
      condition && setPosition({ x: e.clientX, y: e.clientY });
    const setTouchPosition = (e: TouchEvent) => {
      return (
        condition &&
        setPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY })
      );
    };
    window.addEventListener('mousemove', setMousePosition);
    window.addEventListener('touchmove', setTouchPosition);
    window.addEventListener('touchstart', setTouchPosition);

    return () => {
      window.removeEventListener('mousemove', setMousePosition);
      window.removeEventListener('touchmove', setTouchPosition);
      window.removeEventListener('touchstart', setTouchPosition);
    };
  }, [condition]);

  return position;
};
