import * as React from 'react';
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { getDataUrl } from '../utils/images';
import Thumbnail from '../../components/Thumbnail';

import './UploadPreviewThumbnail.scss';

export type UploadStatus = 'pending' | 'uploading' | 'uploaded' | 'error';
interface UploadPreviewThumbnailProps {
  file: File;
  status: UploadStatus;
  caption?: string;
}
const UploadPreviewThumbnail = ({
  file,
  caption,
  status,
}: UploadPreviewThumbnailProps) => {
  const [dataUrl, setDataUrl] = useState('');
  useEffect(() => {
    getDataUrl(file).then((dataUrl) => {
      setDataUrl(dataUrl);
    });
  }, [file]);

  if (!dataUrl) {
    return (
      <div className="UploadPreviewThumbnail loading">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className={`UploadPreviewThumbnail ${status}`}>
      <Thumbnail
        src={dataUrl}
        alt={caption || 'kuva'}
        caption={caption}
        variant="square"
      />
    </div>
  );
};
export default UploadPreviewThumbnail;
