import * as React from 'react';
import Container from 'react-bootstrap/Container';
import './SiteFooter.scss';

const SiteFooter = () => {
  return (
    <footer className="SiteFooter">
      <Container fluid>&copy; Oma P.S.V. Kaikki oikeudet pidätetään</Container>
    </footer>
  );
};

export default SiteFooter;
