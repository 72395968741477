import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { PhotoColor } from 'types/__generated__/globalTypes';

import {
  GetProductCategories_productCategories as ProductCategory,
  GetProductCategories_productCategories_products as Product,
} from 'types/__generated__/GetProductCategories';
import { filterMatchingProducts } from 'utils/products';
import { photoColors } from 'utils/colors';
import './ProductPicker.scss';
import PropertySelectors from 'components/elements/PhotoPropertySelectors';

const arePropertySelectorsHidden = (productCategories: ProductCategory[]) =>
  productCategories?.length === 1 &&
  productCategories[0]?.products.length === 1;

interface ProductPickerProps {
  productCategories: ProductCategory[];
  hiddenSelectors?: boolean | undefined;
  selectedProduct?: Product | null;
  setSelectedProduct?: (product: Product | null) => void;
  setQuantity?: (quantity: number) => void;
  setPhotoColor?: (photoColor: PhotoColor | null) => void;
  openCategoryInfoModal?: (category: ProductCategory | null) => void;
  selectedCategory?: ProductCategory | null;
  selectedProperties: Record<string, string>;
  setSelectedProperties: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  setHiddenSelectors?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductPicker = (props: ProductPickerProps) => {
  const {
    productCategories,
    hiddenSelectors,
    setSelectedProduct,
    openCategoryInfoModal,
    selectedProduct,
    selectedProperties,
    setSelectedProperties,
    setQuantity,
    setPhotoColor,
    selectedCategory,
    setSelectedCategoryId,
    setHiddenSelectors,
  } = props;
  const selectedCategoryId = selectedCategory?.id || null;

  React.useEffect(() => {
    if (!setSelectedProduct) {
      return;
    }

    if (!selectedCategory && productCategories.length === 1) {
      setSelectedCategoryId(productCategories[0].id);
    }

    if (!Object.keys(selectedProperties).length && selectedCategory) {
      const defaultProperties: Record<string, string> = {};
      selectedCategory.products.forEach((product) =>
        product.properties
          .filter((property) => property.isDefault)
          .forEach((property) => {
            defaultProperties[property.key] = property.value;
          }),
      );
      if (Object.keys(defaultProperties).length) {
        setSelectedProperties({
          ...defaultProperties,
        });
      }
    }

    const matchingProducts: Product[] = selectedCategory
      ? filterMatchingProducts(selectedCategory.products, selectedProperties)
      : [];

    if (matchingProducts.length === 1) {
      setSelectedProduct(matchingProducts[0]);
      if (selectedProduct?.id !== matchingProducts[0].id) {
        setQuantity?.(matchingProducts[0].minQuantity);
        setPhotoColor?.(photoColors[0]);
      }
    } else {
      setSelectedProduct(null);
      setQuantity?.(0); // Reset value back to zero
      setPhotoColor?.(null);
    }

    setHiddenSelectors?.(arePropertySelectorsHidden(productCategories));
  }, [
    productCategories,
    selectedCategory,
    selectedProduct,
    selectedProperties,
    setSelectedProduct,
    setQuantity,
    setPhotoColor,
    setHiddenSelectors,
    setSelectedProperties,
    setSelectedCategoryId,
  ]);

  if (hiddenSelectors) {
    return <></>;
  }

  return (
    <div>
      <Form.Control
        as="select"
        required
        className="mt-2"
        value={selectedCategoryId || ''}
        onChange={(e) => {
          setSelectedCategoryId(parseInt(e.target.value));
          setSelectedProperties({});
        }}
      >
        <option disabled value="">
          Valitse kuvatuote
        </option>
        {productCategories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </Form.Control>
      {selectedCategory && (
        <React.Fragment>
          {openCategoryInfoModal && (
            <div className="categoryButton">
              <Button
                variant="primary"
                block
                onClick={() => openCategoryInfoModal(selectedCategory)}
              >
                Kuvatuotteen tiedot
              </Button>
            </div>
          )}
          <PropertySelectors
            selectedCategory={selectedCategory}
            selectedProperties={selectedProperties}
            setSelectedProperties={setSelectedProperties}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductPicker;
