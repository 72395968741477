import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useNavigate,
  useParams,
} from '@reach/router';
import Nav from 'react-bootstrap/Nav';
import { useApolloClient } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { capitalize } from '../../utils/text';
import Price from 'components/Price';
import Checkbox from 'components/elements/Checkbox';
import { ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES } from '../queries';
import {
  AdminGetPriceListsWithCategories,
  AdminGetPriceListsWithCategories_priceLists as PriceListWithCategories,
  AdminGetPriceListsWithCategories_priceLists_productCategories as ProductCategory,
  AdminGetPriceListsWithCategories_priceLists_productCategories_availableProperties as ProductCategoryProperty,
  AdminGetPriceListsWithCategories_priceLists_productCategories_products as Product,
  AdminGetPriceListsWithCategories_priceLists_productCategories_products_priceGroups as PriceGroup,
  AdminGetPriceListsWithCategories_priceLists_productCategories_products_properties as ProductProperty,
} from '../__generated__/AdminGetPriceListsWithCategories';

import QueryLoader from './QueryLoader';
import Table from './Table';
import Collapsible from './Collapsible';
import ImageInput from './ImageInput';
import { apiRequest } from '../api';
import { filterMatchingProducts } from '../../utils/products';

interface AdminProductsPageProps extends RouteComponentProps {}
const AdminProductsPage: React.FC<AdminProductsPageProps> = () => {
  const params = useParams();
  const action = params?.action;
  const categoryId = params.categoryId ? parseInt(params.categoryId) : null;

  return (
    <div>
      <h1>Kuvatuotteet</h1>
      <Nav defaultActiveKey={''} variant="pills" className="my-3">
        <Nav.Item>
          <Nav.Link as={Link} to="/hallinta/kuvatuotteet" eventKey="">
            Kuvatuotelista
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/hallinta/kuvatuotteet/lisaa-tuoteryhma"
            eventKey="lisaa-tuoteryhma"
          >
            Lisää tuoteryhmä
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/hallinta/kuvatuotteet/lisaa-kuvatuote"
            eventKey="lisaa-kuvatuote"
          >
            Lisää kuvatuote
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {action === 'lisaa-tuoteryhma' ? (
        <AddCategory />
      ) : action === 'tuoteryhmat' && categoryId ? (
        <CategoryDetails categoryId={categoryId} />
      ) : action === 'lisaa-kuvatuote' ? (
        <AddProduct categoryId={categoryId} />
      ) : action ? (
        <ProductDetails productId={parseInt(action)} />
      ) : (
        <ProductList />
      )}
    </div>
  );
};

const AddCategory = () => {
  return (
    <QueryLoader<AdminGetPriceListsWithCategories>
      query={ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES}
    >
      {(data) => <CategoryForm category={null} priceLists={data.priceLists} />}
    </QueryLoader>
  );
};

interface CategoryDetailsProps {
  categoryId: number;
}
const CategoryDetails = (props: CategoryDetailsProps) => {
  const { categoryId } = props;
  return (
    <QueryLoader<AdminGetPriceListsWithCategories>
      query={ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES}
    >
      {(data) => {
        const category = findProductCategory(
          getProductCategories(data),
          categoryId,
        );
        if (!category) {
          return <div>Tuoteryhmää ei löydy.</div>;
        }
        return (
          <CategoryForm category={category} priceLists={data.priceLists} />
        );
      }}
    </QueryLoader>
  );
};

function getProductCategories(
  data: AdminGetPriceListsWithCategories,
): ProductCategory[] {
  const categories: ProductCategory[] = [];
  for (let priceList of data.priceLists) {
    categories.push.apply(categories, priceList.productCategories);
  }
  return categories;
}

function findProductCategory(
  productCategories: ProductCategory[],
  categoryId: number,
): ProductCategory | null {
  return productCategories.find((c) => c.id === categoryId) || null;
}

interface CategoryFormProps {
  category: ProductCategory | null;
  priceLists: PriceListWithCategories[];
}
const CategoryForm = (props: CategoryFormProps) => {
  const { category, priceLists } = props;
  const [name, setName] = useState(category?.name || '');
  const [description, setDescription] = useState(category?.description || '');
  const [priceListId, setPriceListId] = useState(category?.priceList?.id || '');
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const imageInput = useRef<ImageInput>(null);
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const hasChanges =
    !category || category.name !== name || category.description !== description;

  const onSubmit = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setShowSuccessMessage(false);
    let response;
    let categoryId = category ? category.id : undefined;
    if (hasChanges) {
      try {
        response = await apiRequest(
          category ? `/categories/${category.id}/` : '/categories/',
          {
            method: category ? 'PUT' : 'POST',
            data: {
              name,
              description,
            },
          },
        );
        categoryId = response.categoryId;
        if (!categoryId) {
          alert('Invalid response: ' + response);
          setSubmitting(false);
          return;
        }
      } catch (e) {
        setSubmitting(false);
        alert(e);
        return;
      }
    }

    if (imageInput.current && imageInput.current.file) {
      try {
        const path = await imageInput.current.upload(
          `product-category/${categoryId}/`,
        );
        await apiRequest(`/categories/${categoryId}/image/`, {
          method: 'POST',
          data: {
            path,
          },
        });
      } catch (e) {
        console.error(e);
        alert('Error setting category image!');
      }
    }

    await apolloClient.query({
      query: ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES,
      fetchPolicy: 'network-only',
    });
    setSubmitting(false);
    setShowSuccessMessage(true);
    await navigate(`/hallinta/kuvatuotteet/tuoteryhmat/${categoryId}`);
  };
  return (
    <div>
      {showSuccessMessage && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          Tuoteryhmä tallennettu onnistuneesti.
        </Alert>
      )}
      <h2>{category ? `Tuoteryhmä: ${category.name}` : 'Uusi tuoteryhmä'}</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>Nimi</td>
            <td>
              <input value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Kuvaus</td>
            <td>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Hinnasto</td>
            <td>
              <select
                value={priceListId}
                onChange={(e) => setPriceListId(parseInt(e.target.value))}
              >
                {priceLists.map((priceList) => (
                  <option key={priceList.id} value={priceList.id}>
                    {priceList.name}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>Kuva</td>
            <td>
              <ImageInput ref={imageInput} initialUrl={category?.image?.url} />
            </td>
          </tr>
        </tbody>
      </table>
      <Button variant="primary" disabled={!name} onClick={onSubmit}>
        {submitting && <Spinner size="sm" animation="border" />}{' '}
        {category ? 'Tallenna muutokset' : 'Luo tuoteryhmä'}
      </Button>
      {category && (
        <div className="my-2">
          <Link to={`/hallinta/kuvatuotteet/lisaa-kuvatuote/${category.id}`}>
            Lisää kuvatuote &raquo;
          </Link>
        </div>
      )}
    </div>
  );
};

interface ProductDetailsProps {
  productId: number;
}
const ProductDetails = (props: ProductDetailsProps) => {
  const { productId } = props;

  return (
    <QueryLoader<AdminGetPriceListsWithCategories>
      query={ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES}
    >
      {(data) => {
        const found = findProductAndCategory(
          getProductCategories(data),
          productId,
        );
        if (!found) {
          return <div>Tuotetta ei löydy.</div>;
        }
        const { product, category } = found;
        return <ProductForm product={product} category={category} />;
      }}
    </QueryLoader>
  );
};
interface AddProductProps {
  categoryId: number | null;
}
const AddProduct = (props: AddProductProps) => {
  const { categoryId } = props;
  const navigate = useNavigate();

  return (
    <QueryLoader<AdminGetPriceListsWithCategories>
      query={ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES}
    >
      {(data) => {
        const productCategories = getProductCategories(data);

        if (!categoryId) {
          return (
            <table className="table">
              <tbody>
                <tr>
                  <td>Tuoteryhmä</td>
                  <td>
                    <select
                      value=""
                      onChange={async (e) => {
                        await navigate(
                          `/hallinta/kuvatuotteet/lisaa-kuvatuote/${e.target.value}`,
                        );
                      }}
                    >
                      <option value="">Valitse tuoteryhmä</option>
                      {productCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }

        const category = findProductCategory(productCategories, categoryId);
        if (!category) {
          return <div>Tuoteryhmää ei löydy.</div>;
        }
        return <ProductForm product={null} category={category} />;
      }}
    </QueryLoader>
  );
};

interface ProductAttributes {
  minPhotos: number;
  maxPhotos: number;
  [key: string]: number;
}
interface ChangedProperty {
  value: string;
  name?: string;
  keyName?: string;
  isDefault?: boolean;
  valueCreated?: boolean;
  propertyCreated?: boolean;
}
interface ProductFormProps {
  product: Product | null;
  category: ProductCategory;
}
const ProductForm = ({ product, category }: ProductFormProps) => {
  const [changedProperties, setChangedProperties] = useState<
    Record<string, ChangedProperty>
  >({});
  const [productAttributes, setProductAttributes] = useState<ProductAttributes>(
    {
      minPhotos: product?.minPhotos || 1,
      maxPhotos: product?.maxPhotos || 1,
    },
  );
  const [newPriceGroups, setNewPriceGroups] = useState<PriceGroup[] | null>(
    null,
  );
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [newProperties, setNewProperties] = useState<ProductCategoryProperty[]>(
    [],
  );
  const imageInput = useRef<ImageInput>(null);
  const [imageInputChanged, setImageInputChanged] = useState(false);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const attributesChanged =
    !product ||
    Object.entries(productAttributes).filter(
      ([key, value]) =>
        (product as unknown as ProductAttributes)[key] !== value,
    ).length > 0;
  const hasChanges =
    Object.entries(changedProperties).length > 0 ||
    newPriceGroups ||
    imageInputChanged ||
    attributesChanged;

  const getSubmit =
    (method?: 'PUT' | 'POST' | 'GET' | 'DELETE' | 'PATCH') => async () => {
      if (!method) {
        method = product ? 'PUT' : 'POST';
      }
      const isDelete = method === 'DELETE';

      if ((!isDelete && !hasChanges) || submitting) {
        return;
      }
      setSubmitting(true);
      setShowSuccessMessage(false);
      let response;
      try {
        response = await apiRequest(
          product ? `/products/${product.id}/` : '/products/',
          {
            method,
            data: {
              ...productAttributes,
              changedProperties,
              newPriceGroups,
              categoryId: category.id,
            },
          },
        );
      } catch (e) {
        alert(e);
        setSubmitting(false);
        return;
      }

      if (imageInput.current && imageInput.current.file && !isDelete) {
        try {
          const path = await imageInput.current.upload(
            `product/${response.productId}/`,
          );
          await apiRequest(`/products/${response.productId}/image/`, {
            method: 'POST',
            data: {
              path,
            },
          });
        } catch (e) {
          console.error(e);
          alert('Error setting product image!');
        }
      }

      setShowSuccessMessage(true);
      setChangedProperties({});
      setNewProperties([]);
      setNewPriceGroups(null);
      await apolloClient.query({
        query: ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES,
        fetchPolicy: 'network-only',
      });
      setSubmitting(false);
      if (!product || isDelete) {
        await navigate(`/hallinta/kuvatuotteet/${response.productId || ''}`);
      }
    };

  const onSubmit = getSubmit();
  const onDelete = () => {
    const confirm = window.confirm('Haluatko varmasti poistaa tämän tuotteen?');
    if (confirm) {
      getSubmit('DELETE')();
    }
  };

  const addNewProperty = () => {
    setNewProperties([
      ...newProperties,
      {
        __typename: 'ProductProperty',
        key: '',
        name: '',
        values: [],
      },
    ]);
  };
  const newPropertyChangeHandler =
    (i: number, k: 'key' | 'name') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const props = [...newProperties];
      props[i] = {
        ...props[i],
        [k]: e.target.value,
      };
      setNewProperties(props);
    };
  const productAttributeChangeHandler =
    (key: string, typeConstructor: Function) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setProductAttributes({
        ...productAttributes,
        [key]: typeConstructor(e.target.value),
      });

  const propertyMap = product ? buildPropertyMap(product) : {};
  const otherProductsWithMatchingProperties =
    findOtherProductsWithMatchingProperties(
      category.products,
      product,
      propertyMap,
      changedProperties,
    );
  return (
    <div>
      {showSuccessMessage && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          Kuvatuote tallennettu onnistuneesti.
        </Alert>
      )}
      <h2>{product ? <>Kuvatuote: {product.id}</> : <>Uusi kuvatuote</>}</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>Tuoteryhmä</td>
            <td>
              {category.name} &nbsp;{' '}
              <small>
                <Link to={`/hallinta/kuvatuotteet/tuoteryhmat/${category.id}`}>
                  Muokkaa &raquo;
                </Link>
              </small>
            </td>
          </tr>
          {category.availableProperties.map((prop) => (
            <tr key={prop.key}>
              <td>{capitalize(prop.name)}</td>
              <td>
                <PropertySelect
                  productPropertyMap={propertyMap}
                  property={prop}
                  changedProperties={changedProperties}
                  setChangedProperties={setChangedProperties}
                  submitting={submitting}
                />
              </td>
            </tr>
          ))}
          {newProperties.map((prop, index) => (
            <tr key={index}>
              <td>
                <input
                  value={prop.key}
                  placeholder="avain"
                  onChange={newPropertyChangeHandler(index, 'key')}
                />
                <br />
                <input
                  value={prop.name}
                  placeholder="ominaisuuden nimi"
                  onChange={newPropertyChangeHandler(index, 'name')}
                />
              </td>
              <td>
                <PropertySelect
                  productPropertyMap={{}}
                  property={prop}
                  propertyCreated={true}
                  changedProperties={changedProperties}
                  setChangedProperties={setChangedProperties}
                  submitting={submitting}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2}>
              <a href="#add-property" onClick={addNewProperty}>
                Lisää ominaisuus
              </a>
            </td>
          </tr>
          <tr>
            <td>Hinta</td>
            <td>
              <PriceInput
                existingPriceGroups={product?.priceGroups}
                setNewPriceGroups={setNewPriceGroups}
              />
            </td>
          </tr>
          <tr>
            <td>Kuvien määrä (vähintään)</td>
            <td>
              <input
                value={productAttributes.minPhotos}
                onChange={productAttributeChangeHandler('minPhotos', Number)}
              />
            </td>
          </tr>
          <tr>
            <td>Kuvien määrä (enintään)</td>
            <td>
              <input
                value={productAttributes.maxPhotos}
                onChange={productAttributeChangeHandler('maxPhotos', Number)}
              />
            </td>
          </tr>
          <tr>
            <td>Kuva</td>
            <td>
              <ImageInput
                ref={imageInput}
                initialUrl={product?.image?.url}
                onChange={(file) => {
                  let changed;
                  if (file) {
                    // new image given
                    changed = true;
                  } else {
                    // image unset -> changed if product previously had an image
                    changed = !!product?.image;
                  }
                  setImageInputChanged(changed);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {otherProductsWithMatchingProperties.length !== 0 && (
        <Alert variant="danger">
          Tällä konfiguraatiolla löytyy jo{' '}
          {otherProductsWithMatchingProperties.length === 1
            ? '1 tuote'
            : `${otherProductsWithMatchingProperties.length} tuotetta`}{' '}
          {otherProductsWithMatchingProperties.length === 1 && (
            <Link
              to={`/hallinta/kuvatuotteet/${otherProductsWithMatchingProperties[0].id}`}
            >
              Muokkaa olemassaolevaa tuotetta &raquo;
            </Link>
          )}
        </Alert>
      )}
      <div className="button-row">
        <Button
          variant="primary"
          disabled={
            !hasChanges || otherProductsWithMatchingProperties.length !== 0
          }
          onClick={onSubmit}
        >
          {submitting && <Spinner size="sm" animation="border" />} Tallenna
          muutokset
        </Button>
        {!!product && (
          <Button variant="warning" onClick={onDelete}>
            {submitting && <Spinner size="sm" animation="border" />} Poista
          </Button>
        )}
      </div>
      <div className={'clearfix'}>
        <Collapsible
          header="Näytä debug-tiedot"
          headerTag="div"
          headerClassName="text-muted text-right"
        >
          <pre>
            <strong>Changed properties:</strong>
            <br />
            {JSON.stringify(changedProperties, null, 2)}
          </pre>
          <pre>
            <strong>New price groups:</strong>
            <br />
            {JSON.stringify(newPriceGroups, null, 2)}
          </pre>
          <pre>
            <strong>Matching:</strong>
            <br />
            {JSON.stringify(otherProductsWithMatchingProperties, null, 2)}
          </pre>
        </Collapsible>
      </div>
    </div>
  );
};

function findOtherProductsWithMatchingProperties(
  otherProducts: Product[],
  product: Product | null,
  propertyMap: PropertyMap,
  changedProperties: Record<string, ChangedProperty>,
): Product[] {
  // Map propertyMap and changedProperties to a single {key: value} map
  const propertyKeyValueMap = Object.fromEntries(
    Object.entries(propertyMap).map(([key, data]) => [key, data.value]),
  );
  // update with changed properties
  Object.assign(
    propertyKeyValueMap,
    Object.fromEntries(
      Object.entries(changedProperties).map(([key, data]) => [key, data.value]),
    ),
  );
  const matchingProducts = filterMatchingProducts<Product>(
    otherProducts,
    propertyKeyValueMap,
  );
  if (product) {
    return matchingProducts.filter((p) => p.id !== product.id);
  } else {
    return matchingProducts;
  }
}

const ADD_NEW = 'ADD_NEW';
interface PropertySelectProps {
  productPropertyMap: PropertyMap;
  property: ProductCategoryProperty;
  changedProperties: Record<string, ChangedProperty>;
  setChangedProperties: React.Dispatch<
    React.SetStateAction<Record<string, ChangedProperty>>
  >;
  propertyCreated?: boolean;
  submitting?: boolean;
}
const PropertySelect = (props: PropertySelectProps) => {
  const {
    productPropertyMap,
    property,
    changedProperties,
    setChangedProperties,
    propertyCreated = false,
    submitting = false,
  } = props;
  const key = property.key;
  const defaultValue = productPropertyMap[key]?.value || '';
  const [value, setValue] = useState(propertyCreated ? ADD_NEW : defaultValue);
  const [newValue, setNewValue] = useState('');
  const [newName, setNewName] = useState('');
  const [newIsDefault, setNewIsDefault] = useState<boolean>(false);

  useEffect(() => {
    setChangedProperties((oldChangedProperties) => {
      const newChangedProperties = {
        ...oldChangedProperties,
      };
      if (value === defaultValue) {
        delete newChangedProperties[key];
      } else if (value === ADD_NEW) {
        if (newValue && newName) {
          newChangedProperties[key] = {
            value: newValue,
            name: newName,
            isDefault: newIsDefault,
            keyName: property.name,
            valueCreated: true,
            propertyCreated,
          };
        } else {
          delete newChangedProperties[key];
        }
      } else {
        newChangedProperties[key] = { value };
      }
      return newChangedProperties;
    });
  }, [
    defaultValue,
    value,
    setChangedProperties,
    key,
    newName,
    newValue,
    newIsDefault,
    property.name,
    propertyCreated,
  ]);

  if (submitting) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  return (
    <div className="property-list-form">
      <select value={value} onChange={(e) => setValue(e.target.value)}>
        <option value="">(ei valintaa)</option>
        {property.values.map((propValue) => (
          <option key={propValue.value} value={propValue.value}>
            {propValue.name}
          </option>
        ))}
        <option value={ADD_NEW}>(lisää uusi)</option>
      </select>
      {value !== ADD_NEW && (
        <Checkbox
          onChange={() => {
            setChangedProperties((oldChangedProperties) => {
              const previousValues = property.values[0];
              const previousDefault =
                oldChangedProperties[key]?.isDefault ??
                previousValues.isDefault;
              const newChangedProperties = {
                ...oldChangedProperties,
                [key]: {
                  ...previousValues,
                  isDefault: !previousDefault,
                },
              };

              if (
                newChangedProperties[key]?.isDefault ===
                  previousValues.isDefault &&
                defaultValue === value
              ) {
                delete newChangedProperties[key];
              }
              return newChangedProperties;
            });
          }}
          checked={
            changedProperties[key]?.isDefault ??
            !!property.values.find((item) => item.value === value)?.isDefault
          }
        >
          Valittu oletusarvoisesti
        </Checkbox>
      )}
      {value === ADD_NEW && (
        <div>
          <div>
            <input
              placeholder="arvo"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </div>
          <div>
            <input
              placeholder="vaihtoehdon nimi"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div>
            <Checkbox
              onChange={() => setNewIsDefault(!newIsDefault)}
              checked={newIsDefault}
            >
              Valittu oletusarvoisesti
            </Checkbox>
          </div>
        </div>
      )}
    </div>
  );
};

//<PriceInput defaultPriceGroups={product?.priceGroups} setNewPriceGroups={setNewPriceGroups()} />
interface PriceInputProps {
  existingPriceGroups?: PriceGroup[];
  setNewPriceGroups: (groups: PriceGroup[] | null) => void;
}
const PriceInput = (props: PriceInputProps) => {
  const { existingPriceGroups, setNewPriceGroups } = props;
  const [priceGroups, setPriceGroups] = useState(existingPriceGroups || []);

  useEffect(() => {
    let priceGroupsDiffer = true;
    if (
      existingPriceGroups &&
      priceGroups.length === existingPriceGroups.length
    ) {
      priceGroupsDiffer = false;
      for (const i in existingPriceGroups) {
        if (
          existingPriceGroups[i].minQuantity !== priceGroups[i].minQuantity ||
          existingPriceGroups[i].unitPrice !== priceGroups[i].unitPrice
        ) {
          priceGroupsDiffer = true;
          break;
        }
      }
    }
    if (!priceGroupsDiffer) {
      setNewPriceGroups(null);
      return;
    }
    setNewPriceGroups(priceGroups);
  }, [priceGroups, setNewPriceGroups, existingPriceGroups]);

  const unitPriceHandler =
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const unitPrice = e.target.value;
      setPriceGroups((oldGroups) => {
        const newGroups = [...oldGroups];
        //console.log('change', i, e.target.value);
        newGroups[i] = {
          ...newGroups[i],
          unitPrice,
        };
        return newGroups;
      });
    };
  const unitPriceFormatterHandler = (i: number) => () => {
    setPriceGroups((oldGroups) => {
      const raw = oldGroups[i]?.unitPrice;
      let unitPrice: string;
      if (typeof raw === 'undefined') {
        unitPrice = '';
      } else {
        const parsed = parseFloat(raw.replace(',', '.'));
        if (typeof parsed === 'undefined' || isNaN(parsed)) {
          unitPrice = '';
        } else {
          unitPrice = parsed.toFixed(2);
        }
      }
      //console.log('formatting', i, raw, unitPrice);
      const newGroups = [...oldGroups];
      newGroups[i] = {
        ...newGroups[i],
        unitPrice,
      };
      return newGroups;
    });
  };
  const minQuantityHandler =
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const minQuantity = parseInt(e.target.value);
      if (!minQuantity) {
        return;
      }
      const newGroups = [...priceGroups];
      newGroups[i] = {
        ...newGroups[i],
        minQuantity,
      };
      setPriceGroups(newGroups);
    };
  const removePriceGroup = (i: number) => {
    const newGroups = [...priceGroups];
    newGroups.splice(i, 1);
    setPriceGroups(newGroups);
  };
  const addPriceGroup = () => {
    setPriceGroups([
      ...priceGroups,
      {
        __typename: 'PriceGroup', // LOL
        minQuantity: 0,
        unitPrice: '',
      },
    ]);
  };

  return (
    <div style={{ width: 400 }}>
      {priceGroups.map((group, i) => (
        <div key={i}>
          <input
            type="text"
            className="text-right"
            style={{ display: 'inline-block', width: 100 }}
            value={group.unitPrice}
            onChange={unitPriceHandler(i)}
            onBlur={unitPriceFormatterHandler(i)}
          />
          &nbsp;&euro;{' '}
          <input
            type="number"
            className="text-right"
            style={{ display: 'inline-block', width: 80 }}
            value={group.minQuantity || ''}
            onChange={minQuantityHandler(i)}
          />
          +&nbsp;kpl{' '}
          {i > 0 && (
            <a
              href="#poista"
              onClick={() => {
                removePriceGroup(i);
              }}
            >
              Poista
            </a>
          )}
        </div>
      ))}
      <a href="#lisaa" onClick={addPriceGroup}>
        Lisää hintaryhmä
      </a>
    </div>
  );
};

type ProductAndCategory = {
  product: Product;
  category: ProductCategory;
};
const findProductAndCategory = (
  categories: ProductCategory[],
  productId: number,
): ProductAndCategory | null => {
  for (const category of categories) {
    for (const product of category.products) {
      if (product.id === productId) {
        return { product, category };
      }
    }
  }
  return null;
};

//type ProductWithCategory = Product & {
//  category: Exclude<ProductCategory, "products">;
//}
//const products: ProductWithCategory[] = [];
//for(const category of productCategories) {
//  for(const product of category.products) {
//    products.push({
//      ...product,
//      category,
//    })
//  }
//}

const ProductList = () => {
  return (
    <QueryLoader<AdminGetPriceListsWithCategories>
      query={ADMIN_GET_PRICE_LISTS_WITH_CATEGORIES}
    >
      {({ priceLists }) => (
        <div>
          {priceLists.map((priceList) => (
            <Collapsible
              headerTag="h2"
              key={priceList.id}
              header={priceList.name}
              defaultOpen
            >
              {priceList.productCategories.map((category) => {
                const columns: any[] = [
                  {
                    field: 'id',
                    title: 'ID',
                    link: (row: any) => `/hallinta/kuvatuotteet/${row.id}`,
                  },
                ];
                for (const prop of category.availableProperties) {
                  columns.push({
                    field: `propertyMap.${prop.key}.name`,
                    title: capitalize(prop.name),
                  });
                }
                columns.push({
                  field: 'priceGroups',
                  title: 'Hinta',
                  formatter: formatPriceGroups,
                });
                columns.push({
                  field: '',
                  title: 'Toiminnot',
                  formatter: (cell: any, row: any) => (
                    <Link to={`/hallinta/kuvatuotteet/${row.id}`}>muokkaa</Link>
                  ),
                });
                return (
                  <div key={category.id}>
                    <Collapsible header={category.name}>
                      <div className={'mt-0'}>
                        <Link
                          to={`/hallinta/kuvatuotteet/lisaa-kuvatuote/${category.id}`}
                        >
                          Lisää kuvatuote {category.name}
                          -tuoteryhmään &raquo;
                        </Link>
                      </div>
                      <div className={'mb-2'}>
                        <Link
                          to={`/hallinta/kuvatuotteet/tuoteryhmat/${category.id}`}
                        >
                          Muokkaa {category.name}
                          -tuoteryhmää &raquo;
                        </Link>
                      </div>
                      <Table
                        keyField="id"
                        data={category.products.map((product) => ({
                          ...product,
                          propertyMap: buildPropertyMap(product),
                        }))}
                        search={false}
                        columns={columns}
                      />
                    </Collapsible>
                  </div>
                );
              })}
            </Collapsible>
          ))}
        </div>
      )}
    </QueryLoader>
  );
};

type PropertyMap = Record<string, ProductProperty>;
const buildPropertyMap = (product: Product): PropertyMap => {
  // map product.properties (prop[]) array to a {prop.key: prop} map that's easy to use in tables etc
  const ret: PropertyMap = {};
  for (const prop of product.properties) {
    ret[prop.key] = prop;
  }
  return ret;
};

const formatPriceGroups = (priceGroups: PriceGroup[]): any => {
  if (priceGroups.length === 1) {
    if (priceGroups[0].minQuantity === 1) {
      return (
        <span>
          <Price>{priceGroups[0].unitPrice}</Price>
        </span>
      );
    }
  }
  return (
    <span>
      {priceGroups.map((group) => (
        <React.Fragment key={group.minQuantity}>
          {' '}
          <Price>{group.unitPrice}</Price> ({group.minQuantity}+ kpl)
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

export default AdminProductsPage;
