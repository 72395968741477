import * as React from 'react';
import Form from 'react-bootstrap/Form';

import {
  GetProductCategories_productCategories as ProductCategory,
  GetProductCategories_productCategories_products,
} from 'types/__generated__/GetProductCategories';
import { filterMatchingProducts } from 'utils/products';

interface PropertySelectorsProps {
  editable?: boolean;
  selectedCategory?: ProductCategory;
  selectedProperties: Record<string, string>;
  setSelectedProperties: (p: Record<string, string>) => void;
  onAllPropertiesSelected?: (
    matchedProduct: GetProductCategories_productCategories_products,
  ) => void;
}
const PropertySelectors = (props: PropertySelectorsProps) => {
  const {
    editable,
    selectedCategory,
    selectedProperties,
    setSelectedProperties,
    onAllPropertiesSelected,
  } = props;

  const propertySelectors = [];
  const previousSelectedProperties: Record<string, string> = {};
  let pendingSelect = false;
  let allPropertiesSelected = false;
  if (selectedCategory) {
    for (const property of selectedCategory.availableProperties) {
      const selectedPropertiesUpToHere = {
        ...previousSelectedProperties,
      };
      const valuesWithMatchingProducts = property.values.filter(
        (propertyValue) =>
          filterMatchingProducts(selectedCategory.products, {
            ...selectedPropertiesUpToHere,
            [property.key]: propertyValue.value,
          }).length > 0,
      );

      const selectedPropertyValue = selectedProperties[property.key];

      let selectedValue = selectedPropertyValue;
      if (!selectedValue) {
        if (valuesWithMatchingProducts.length === 1) {
          selectedValue = valuesWithMatchingProducts[0].value;
        } else {
          selectedValue = '';
        }
      }

      if (valuesWithMatchingProducts.length) {
        const propertySelector = (
          <Form.Control
            as="select"
            disabled={editable === false}
            required
            className="my-2"
            key={property.key}
            value={selectedValue}
            onChange={(e) => {
              setSelectedProperties({
                ...selectedPropertiesUpToHere,
                [property.key]: e.target.value,
              });
            }}
          >
            <option disabled value="">
              Valitse {property.name}
            </option>
            {valuesWithMatchingProducts.map((propertyValue) => (
              <option key={propertyValue.value} value={propertyValue.value}>
                {propertyValue.name}
              </option>
            ))}
          </Form.Control>
        );
        propertySelectors.push(propertySelector);

        // next step is only visible after this has been selected (or is the only possible option),
        if (!selectedPropertyValue && valuesWithMatchingProducts.length !== 1) {
          pendingSelect = true;
          break;
        }
      }

      // the next step only has the options that are compatible with this selection
      // XXX: too tired to figure out why this works now but the commented one didn't... the logic is complicated
      //previousSelectedProperties[property.key] = selectedPropertyValue;
      previousSelectedProperties[property.key] = selectedValue;
    }
  }

  if (Object.keys(previousSelectedProperties).length && !pendingSelect) {
    allPropertiesSelected = true;
  }

  /**
   * Perform a callback once all the available properties have been selected.
   * Used for asynchronous order saving (without relying on a manual submit).
   */
  React.useEffect(() => {
    if (!allPropertiesSelected || !selectedCategory) {
      return;
    }
    const products = filterMatchingProducts(selectedCategory.products, {
      ...previousSelectedProperties,
    });
    if (products.length !== 1) {
      return;
    }
    const matchedProduct: GetProductCategories_productCategories_products = {
      ...products[0],
    };

    onAllPropertiesSelected?.(matchedProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProperties,
    selectedCategory,
    allPropertiesSelected,
    onAllPropertiesSelected,
  ]);

  if (!selectedCategory) {
    return null;
  }

  return <React.Fragment>{propertySelectors}</React.Fragment>;
};

export default PropertySelectors;
