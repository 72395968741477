import * as React from 'react';

interface MultiLineTextProps {
  children: string;
}
const MultiLineText = (props: MultiLineTextProps) => {
  const text = props.children || '';
  const parts = text.split('\n');
  return (
    <React.Fragment>
      {parts.map((part, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <br />}
          {part}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
export default MultiLineText;
