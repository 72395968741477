import React from 'react';
import { Router } from '@reach/router';
import 'typeface-montserrat';

import PhotoshootListPage from './components/PhotoshootListPage';
import PhotoshootDetailsPage from './components/PhotoshootDetailsPage';
import LoginContainer from './components/LoginContainer';
import MainLayout from './components/MainLayout';
import ShoppingCartSidebar from './components/ShoppingCartSidebar';
import CustomerServiceModal from './components/CustomerServiceModal';
import SharePhotoshootModal from './components/SharePhotoshootModal';
import ProductInfoModal from './components/ProductInfoModal';
import CategoryInfoModal, {
  ProductImageModal,
} from './components/CategoryInfoModal';
import SubmitOrderPage from './components/SubmitOrderPage';
import ProductsPage from './components/ProductsPage';

import { ApolloProvider } from '@apollo/client';
import { client } from './apollo';
import './scss/main.scss';
import MyDetailsPage from './components/MyDetailsPage';
import LoadingIndicator from './components/LoadingIndicator';
import AdminRoutesInner from '@admin-routes';

const AdminRoutes = (props: any) => (
  <React.Suspense fallback={<LoadingIndicator />}>
    <AdminRoutesInner />
  </React.Suspense>
);

const ErrorTriggerTestRoute = (props: any) => {
  throw new Error('Test error triggered');
};

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <LoginContainer>
          <MainLayout>
            <React.StrictMode>
              <Router className="Router">
                <PhotoshootListPage path="/" />
                <PhotoshootListPage path="/kuvat/" />
                <PhotoshootDetailsPage path="/kuvat/:slug" />
                <PhotoshootDetailsPage path="/kuvat/:slug/:code" />
                <SubmitOrderPage path="/tilaa/" />
                <ProductsPage path="/kuvatuotteet/" />
                <MyDetailsPage path="/omat-tiedot/" />
                <ErrorTriggerTestRoute path="/error-trigger/" />
              </Router>
            </React.StrictMode>
            {/* TODO: re-enable StrictMode for admin routes too */}
            <Router className="Router">
              <AdminRoutes path="/hallinta/*" />
            </Router>
          </MainLayout>
          <React.StrictMode>
            <ShoppingCartSidebar />
          </React.StrictMode>
          <CustomerServiceModal />
          <SharePhotoshootModal />
          <CategoryInfoModal />
          <ProductImageModal />
          <ProductInfoModal />
        </LoginContainer>
      </ApolloProvider>
    </div>
  );
}

export default App;
