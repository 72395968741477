import { gql } from '@apollo/client';

export const PHOTO_FRAGMENT = gql`
  fragment Photo on Photo {
    __typename
    id
    code
    previewUrl
    fullSizeUrL: previewUrl(type: full)
    rating
    isFavourite
  }
`;

export const RATE_PHOTO = gql`
  mutation RatePhoto($photo: Int!, $rating: Int!) {
    ratePhoto(photo: $photo, rating: $rating) {
      photo {
        ...Photo
      }
    }
  }
  ${PHOTO_FRAGMENT}
`;

export const MARK_FAVOURITE_PHOTO = gql`
  mutation MarkFavouritePhoto($photoId: Int!) {
    markFavouritePhoto(photoId: $photoId) {
      ok
    }
  }
`;
