import * as React from 'react';
import './SecondaryHeader.scss';
import { Link, useLocation } from '@reach/router';
import Container from 'react-bootstrap/Container';
import Checkbox from 'components/elements/Checkbox';

interface SecondaryHeaderProps {
  path?: string[] | string;
  setFavouriteOnly?: (val: boolean) => Promise<void>;
  favouriteOnly?: boolean;
  withBottomMargin?: boolean;
}
const SecondaryHeader: React.FC<SecondaryHeaderProps> = (props) => {
  const location = useLocation();

  let path: string[];
  if (typeof props.path === 'string') {
    path = [props.path];
  } else {
    path = props.path || [];
  }

  const locationParts = location.pathname.split('/');

  const {
    children,
    setFavouriteOnly,
    favouriteOnly,
    withBottomMargin = true,
  } = props;
  return (
    <div
      className={`SecondaryHeader ${withBottomMargin ? 'margin-bottom' : ''}`}
    >
      <Container fluid>
        <ol>
          {path.map((item, i) => (
            <li key={i}>
              <Link to={locationParts.slice(0, i + 2).join('/')}>{item}</Link>
            </li>
          ))}
        </ol>
        {children && <div className="float-right">{children}</div>}

        {!!setFavouriteOnly && (
          <Checkbox
            onChange={() => setFavouriteOnly(!favouriteOnly)}
            checked={favouriteOnly}
          >
            Näytä vain suosikit
          </Checkbox>
        )}
      </Container>
    </div>
  );
};
export default SecondaryHeader;
