import * as React from 'react';
import { useQuery } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

interface QueryLoaderProps<TData, TVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options?: QueryHookOptions<TData, TVariables>;
  children: (props: TData) => React.ReactElement | null;
  hideLoading?: boolean;
}
function QueryLoader<TData = any, TVariables = OperationVariables>(
  props: QueryLoaderProps<TData, TVariables>,
): React.ReactElement | null {
  const { query, children, options, hideLoading } = props;
  const { data, loading, error } = useQuery<TData, TVariables>(query, options);
  if (loading) {
    if (hideLoading) {
      return children({} as TData);
    }
    return <Spinner animation="border" />;
  }
  if (error) {
    return (
      <Alert variant="danger">
        <strong>Error:</strong> {error.message}
      </Alert>
    );
  }
  if (!data) {
    return (
      <Alert variant="danger">
        <strong>Error:</strong> No data
      </Alert>
    );
  }

  return children(data);
}
export default QueryLoader;
