import * as React from 'react';
import IconButton from './IconButton';
import './HeartButton.scss';

interface HeartButtonProps {
  children?: React.ReactNode;
  fill?: boolean;
  inline?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}
const HeartButton: React.FC<HeartButtonProps> = ({
  fill,
  inline,
  onClick,
  children,
  className,
}: HeartButtonProps) => (
  <IconButton
    icon={fill ? 'heart-fill' : 'heart'}
    onClick={onClick}
    className={`heart-icon ${!!inline && 'heart-icon-inline'} ${className}`}
  >
    {children}
  </IconButton>
);

export default HeartButton;
