import * as React from 'react';
import { useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useNavigate,
  useParams,
} from '@reach/router';
import {
  ApolloClient,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import Nav from 'react-bootstrap/Nav';
import { Form as FormikForm, Formik } from 'formik';
import { Form } from 'react-bootstrap-formik';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import * as yup from 'yup';

import {
  ADMIN_GET_PHOTOSHOOT,
  ADMIN_GET_PHOTOSHOOTS,
  ADMIN_GET_PAGINATED_PHOTOSHOOTS,
  ADMIN_GET_USERS,
  ADMIN_GET_PRICE_LISTS,
} from '../queries';
import { AdminGetUsers } from '../__generated__/AdminGetUsers';
import { AdminGetPriceLists } from '../__generated__/AdminGetPriceLists';
import {
  AdminGetPhotoshoot,
  AdminGetPhotoshoot_photoshoot as Photoshoot,
  AdminGetPhotoshoot_photoshoot_photos as Photo,
  AdminGetPhotoshootVariables,
} from '../__generated__/AdminGetPhotoshoot';
import {
  GetProductCategories,
  GetProductCategoriesVariables,
  GetProductCategories_productCategories_products as Product,
} from 'types/__generated__/GetProductCategories';
import { AdminGetPaginatedPhotoshoots } from '../__generated__/AdminGetPaginatedPhotoshoots';
import { apiRequest } from '../api';

import QueryLoader from './QueryLoader';
import Rating from 'components/Rating';
import Table from './Table';
import PaginationRow, {
  DEFAULT_PAGE_SIZE,
} from 'components/elements/PaginationRow';
import PhotoDisplay from 'components/elements/PhotoDisplay';
import Checkbox from 'components/elements/Checkbox';
import PhotoUploader, {
  emptyUploadBatch,
  PhotoUploadBatch,
} from './PhotoUploader';
import { RATE_PHOTO } from 'types/photos';
import {
  ADD_PHOTOSHOOT_FREE_PRODUCT,
  REMOVE_PHOTOSHOOT_FREE_PRODUCT,
} from 'types/photoshoots';
import { RatePhoto, RatePhotoVariables } from 'types/__generated__/RatePhoto';
import {
  AddPhotoshootFreeProduct,
  AddPhotoshootFreeProductVariables,
} from 'types/__generated__/AddPhotoshootFreeProduct';
import {
  RemovePhotoshootFreeProduct,
  RemovePhotoshootFreeProductVariables,
} from 'types/__generated__/RemovePhotoshootFreeProduct';
import Trash from 'img/icons/trash.svg';
import { delayedValueUpdate } from 'components/elements/ManagedFormControl';
import { GET_PRODUCT_CATEGORIES } from 'types/shoppingCart';
import ProductPicker from 'components/elements/ProductPicker';

const defaultSelectedProduct: Product | null = null;
const defaultSelectedCategoryId: number | null = null;
const defaultSelectedProperties: Record<string, string> = {};

interface AdminPhotoshootsPageProps extends RouteComponentProps {
  editMode?: boolean;
}
const AdminPhotoshootsPage: React.FC<AdminPhotoshootsPageProps> = (props) => {
  const { editMode } = props;
  const params = useParams();
  const photoshootId = params?.photoshootId;
  const userId = params?.userId;

  return (
    <div>
      <h1>Kuvakansiot</h1>
      <Nav
        defaultActiveKey={editMode && !photoshootId ? 'add-photoshoot' : ''}
        variant="pills"
        className="my-3"
      >
        <Nav.Item>
          <Nav.Link as={Link} to="/hallinta/kuvakansiot" eventKey="">
            Kuvakansiolista
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/hallinta/kuvakansiot/uusi"
            eventKey="add-photoshoot"
          >
            Lisää kuvakansio
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {editMode ? (
        photoshootId ? (
          <AddPhotosForm photoshootId={parseInt(photoshootId)} />
        ) : (
          <AddPhotoshootForm defaultUserId={parseInt(userId)} />
        )
      ) : photoshootId ? (
        <PhotoshootDetails photoshootId={parseInt(photoshootId)} />
      ) : (
        <PhotoshootList />
      )}
    </div>
  );
};

const editPhotoshootFormValidationSchema = yup
  .object({
    name: yup.string().required('Syötä kuvakansion nimi'),
    message: yup.string(),
  })
  .required();

interface PhotoshootDetailsProps {
  photoshootId: number;
}
const PhotoshootDetails = (props: PhotoshootDetailsProps) => {
  const { photoshootId } = props;

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(
    defaultSelectedProduct,
  );
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedProperties, setSelectedProperties] = useState<
    Record<string, string>
  >(defaultSelectedProperties);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    defaultSelectedCategoryId,
  );

  // TODO: extract new component for rating?
  const [ratePhoto] = useMutation<RatePhoto, RatePhotoVariables>(RATE_PHOTO);
  const apolloClient = useApolloClient();

  const [addPhotoshootFreeProduct] = useMutation<
    AddPhotoshootFreeProduct,
    AddPhotoshootFreeProductVariables
  >(ADD_PHOTOSHOOT_FREE_PRODUCT);

  const [removePhotoshootFreeProduct] = useMutation<
    RemovePhotoshootFreeProduct,
    RemovePhotoshootFreeProductVariables
  >(REMOVE_PHOTOSHOOT_FREE_PRODUCT);

  const {
    data: photoshootData,
    loading: protoshootLoading,
    error: photoshootError,
    refetch: refretchPhotoshoot,
  } = useQuery<AdminGetPhotoshoot, AdminGetPhotoshootVariables>(
    ADMIN_GET_PHOTOSHOOT,
    {
      variables: {
        id: photoshootId,
      },
    },
  );
  const photoshoot = photoshootData?.photoshoot;
  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery<GetProductCategories, GetProductCategoriesVariables>(
    GET_PRODUCT_CATEGORIES,
    {
      variables: {
        priceList: photoshoot?.priceList.code || '',
      },
    },
  );

  if (protoshootLoading || categoriesLoading) {
    return (
      <div>
        <Spinner size="sm" animation="border" />
      </div>
    );
  }
  const productCategories = categoriesData?.productCategories;
  const selectedCategory =
    productCategories?.find((c) => c.id === selectedCategoryId) || null;
  const error = photoshootError || categoriesError;
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  if (!photoshoot) {
    return <div>Kuvakansiota ei löydy</div>;
  }
  if (!productCategories) {
    return (
      <div>
        Tuoteluokkien lataaminen epäonnistui ({photoshoot?.priceList.code})
      </div>
    );
  }
  return (
    <div>
      <div>
        <h2>Kuvakansio: {photoshoot.name}</h2>
        <Formik
          initialValues={{
            name: photoshoot.name,
            message: photoshoot.message,
            freeProductQuantity: quantity,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={editPhotoshootFormValidationSchema}
          onSubmit={async (
            values,
            { setErrors, setStatus, resetForm, setSubmitting },
          ) => {
            setSubmitting(true);

            try {
              await apiRequest(`/photoshoots/${photoshoot.id}/`, {
                method: 'PUT',
                data: values,
              });
            } catch (e) {
              console.error('Error submitting form', e);
              setSubmitting(false);
              setStatus({ success: false });
              alert(e);
              return;
            }

            refretchPhotoshoot();
            setSubmitting(false);
            resetForm();
          }}
        >
          {({ isSubmitting, values }) => (
            <FormikForm>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Nimi</td>
                    <td>
                      <Form.Input name="name" disabled={isSubmitting} />
                    </td>
                  </tr>
                  <tr>
                    <td>Asiakas</td>
                    <td>{photoshoot.user.displayName}</td>
                  </tr>
                  <tr>
                    <td>Hinnasto</td>
                    <td>{photoshoot.priceList.name}</td>
                  </tr>
                  <tr>
                    <td>Viesti asiakkaalle</td>
                    <td>
                      <Form.Textarea name="message" disabled={isSubmitting} />
                    </td>
                  </tr>
                  <tr>
                    <td>Nopean tilaajan etu</td>
                    <td>
                      {photoshoot.fastOrderDiscountEnabled
                        ? 'Käytössä'
                        : 'Ei käytössä'}
                    </td>
                  </tr>
                  <tr>
                    <td>Hintaan kuuluvat valokuvat</td>
                    <td>
                      {!photoshoot.freeProducts.length ? (
                        'Ei mitään'
                      ) : (
                        <ul>
                          {photoshoot.freeProducts.map((item) => {
                            const sortedProps = [
                              ...item.product.properties,
                            ].sort((a, b) => (a.keyName > b.keyName ? 1 : -1));
                            const productProps = sortedProps.reduce(
                              (prev, property) =>
                                `${prev} ${property.keyName}: ${property.name},`,
                              '',
                            );
                            return (
                              <li key={`free_product_${item.product.id}`}>
                                <div className="free-product-row">
                                  <div>
                                    <b>
                                      x{item.quantity}{' '}
                                      {item.product.category.name}:
                                    </b>
                                    {productProps.slice(0, -1)}.
                                    <br />
                                    Jäljellä oleva määrä:{' '}
                                    {item.quantity - item.quantityUsed}
                                  </div>
                                  <button
                                    className="remove"
                                    aria-label="Poista"
                                    onClick={async () => {
                                      await removePhotoshootFreeProduct({
                                        variables: {
                                          recordId: item.id,
                                        },
                                      });
                                      refretchPhotoshoot();
                                    }}
                                  >
                                    <img src={Trash} alt="Poista" />
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Lisää tuotteita, jotka sisältyvät hintaan</td>
                    <td>
                      <ProductPicker
                        productCategories={productCategories}
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        selectedCategory={selectedCategory}
                        setSelectedCategoryId={setSelectedCategoryId}
                      />
                      {!!selectedProduct && (
                        <React.Fragment>
                          <Form.Input
                            name="freeProductQuantity"
                            type="number"
                            min={1}
                            onChange={(e) => {
                              setQuantity(parseInt(e.target.value));
                            }}
                          />
                          <Button
                            variant="primary"
                            onClick={async () => {
                              await addPhotoshootFreeProduct({
                                variables: {
                                  photoshootId: photoshoot.id,
                                  productId: selectedProduct.id,
                                  quantity,
                                },
                              });
                              await apolloClient.query({
                                query: ADMIN_GET_PHOTOSHOOT,
                                variables: {
                                  id: photoshootId,
                                },
                                fetchPolicy: 'network-only',
                              });
                              // Reset to original values
                              setSelectedCategoryId(null);
                              setQuantity(1);
                            }}
                          >
                            {isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{' '}
                            Tallenna
                          </Button>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <section className="my-2">
                <h3>Toiminnot</h3>
                <div className="my-3 d-flex flex-row">
                  <div className="mr-2">
                    <Link
                      to={`/hallinta/kuvakansiot/${photoshootId}/lisaa-kuvia`}
                    >
                      <Button variant="primary">Lisää kuvia</Button>
                    </Link>
                  </div>
                  <div className="mr-2">
                    <RemovePhotoshootButton photoshoot={photoshoot} />
                  </div>
                  <div>
                    <Button
                      className="height-100"
                      variant="primary"
                      type="submit"
                    >
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Tallenna muutokset
                    </Button>
                  </div>
                </div>
              </section>
            </FormikForm>
          )}
        </Formik>
        <section>
          <h3>Kuvat ({photoshoot.photos.length} kpl)</h3>
          {photoshoot.photos.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Kuva</th>
                  <th>Koodi</th>
                  <th style={{ width: 250 }}>Toiminnot</th>
                </tr>
              </thead>
              <tbody>
                {photoshoot.photos.map((photo) => (
                  <tr key={photo.id}>
                    <td>
                      <div style={{ width: 300, height: 450 }}>
                        <PhotoDisplay selectedPhoto={photo} />
                      </div>
                    </td>
                    <td>{photo.code}</td>
                    <td>
                      <Rating
                        value={photo.rating}
                        onRate={async (rating) => {
                          await ratePhoto({
                            variables: {
                              photo: photo.id,
                              rating,
                            },
                          });
                        }}
                      />

                      <div className="my-3">
                        <RemovePhotoButton
                          photoshoot={photoshoot}
                          photo={photo}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>Kuvakansiossa ei ole kuvia.</div>
          )}
        </section>
      </div>

      <div className="my-3">
        <Link to={'/hallinta/kuvakansiot'}>
          &laquo; Takaisin kuvakansiolistaan
        </Link>
      </div>
    </div>
  );
};

interface RemovePhotoButtonProps {
  photo: Photo;
  photoshoot: Photoshoot;
}
const RemovePhotoButton = (props: RemovePhotoButtonProps) => {
  const { photo, photoshoot } = props;
  const [askingConfirmation, setAskingConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const cache = apolloClient.cache;

  const deletePhoto = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let response;
    try {
      response = await apiRequest(`/photos/${photo.id}/`, {
        method: 'DELETE',
      });
    } catch (e) {
      console.error('Error deleting photo:', e);
      alert(e.toString());
      setLoading(false);
      setAskingConfirmation(false);
      return;
    }
    if (!response.success) {
      console.error('Error deleting photo:', response);
      alert('Error deleting photo.');
      setLoading(false);
      setAskingConfirmation(false);
      return;
    }

    cache.modify({
      id: cache.identify(photoshoot as any), // https://stackoverflow.com/a/60697366/741970
      fields: {
        photos: (existing: Photo[], { readField }) => {
          return existing.filter((p) => readField('id', p as any) !== photo.id);
        },
      },
    });
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <span>
          Poistetaan... <Spinner size="sm" animation="border" />
        </span>
      ) : askingConfirmation ? (
        <span>
          Poistetaanko kuva varmasti?
          <br />
          <a
            href="#yes"
            onClick={(e) => {
              e.preventDefault();
              deletePhoto();
            }}
          >
            Kyllä
          </a>{' '}
          /{' '}
          <a
            href="#no"
            onClick={() => {
              setAskingConfirmation(false);
            }}
          >
            Ei
          </a>
        </span>
      ) : (
        <img
          src={Trash}
          alt="Poista kuva"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setAskingConfirmation(true);
          }}
        />
      )}
    </div>
  );
};

interface RemovePhotoshootButtonProps {
  photoshoot: Photoshoot;
}
const RemovePhotoshootButton = (props: RemovePhotoshootButtonProps) => {
  const { photoshoot } = props;
  const [askingConfirmation, setAskingConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletePhotoshoot = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let response;
    try {
      response = await apiRequest(`/photoshoots/${photoshoot.id}/`, {
        method: 'DELETE',
      });
    } catch (e) {
      console.error('Error deleting photoshoot:', e);
      alert(e.toString());
      setLoading(false);
      setAskingConfirmation(false);
      return;
    }
    if (!response.success) {
      console.error('Error deleting photoshoot:', response);
      alert('Error deleting photoshoot.');
      setLoading(false);
      setAskingConfirmation(false);
      return;
    }

    // Take the easy way out and just refresh
    window.location.href = '/hallinta/kuvakansiot';
  };
  return (
    <div>
      {loading ? (
        <Button variant="warning" disabled>
          <Spinner animation="border" size="sm" /> Poistetaan...
        </Button>
      ) : askingConfirmation ? (
        <div className="px-2">
          Poistetaanko kuvakansio varmasti?
          <br />
          <a
            href="#yes"
            onClick={(e) => {
              e.preventDefault();
              deletePhotoshoot();
            }}
          >
            Kyllä
          </a>{' '}
          /{' '}
          <a
            href="#no"
            onClick={() => {
              setAskingConfirmation(false);
            }}
          >
            Ei
          </a>
        </div>
      ) : (
        <Button variant="warning" onClick={() => setAskingConfirmation(true)}>
          Poista kuvakansio
        </Button>
      )}
    </div>
  );
};

const PhotoshootList = () => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const [page, setPage] = React.useState<number>(1);
  const [filterWithoutOrders, setFilterWithoutOrders] =
    React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_PAGE_SIZE);
  return (
    <QueryLoader<AdminGetPaginatedPhotoshoots>
      hideLoading={
        /**
         * Render empty list instead of showing a spinner when searching.
         * This prevents the search input disappearing/reappearing and losing its focus while typing.
         */
        !!searchInput
      }
      query={ADMIN_GET_PAGINATED_PHOTOSHOOTS}
      options={{
        variables: {
          searchInput,
          page,
          pageSize,
          filterWithoutOrders,
        },
      }}
    >
      {({ paginatedPhotoshoots }) => (
        <div>
          <div className="FilterWithoutOrders">
            <Checkbox
              onChange={() => {
                setFilterWithoutOrders(!filterWithoutOrders);
              }}
              checked={filterWithoutOrders}
            >
              Kuvaukset ilman tilauksia
            </Checkbox>
          </div>
          <Table
            keyField="id"
            data={paginatedPhotoshoots?.photoshoots || []}
            columns={[
              {
                field: 'id',
                title: 'ID',
                link: (row) => `/hallinta/kuvakansiot/${row.id}`,
              },
              {
                field: 'name',
                title: 'Nimi',
                link: (row) => `/hallinta/kuvakansiot/${row.id}`,
              },
              {
                field: 'publishedAt',
                title: 'Luotu',
                link: (row) => `/hallinta/kuvakansiot/${row.id}`,
              },
            ]}
            search
            backendSearch
            searchText={searchInput}
            onBeforeSearch={(value: string) => {
              delayedValueUpdate(value, (newInput: string) => {
                if (!value) {
                  return setSearchInput(undefined);
                }
                setSearchInput(newInput);
                setPage(1);
              });
            }}
          />
          <PaginationRow
            currentPage={page}
            totalPages={paginatedPhotoshoots?.pagination?.totalPages || 1}
            pageSize={pageSize}
            setPage={setPage}
            recordCount={paginatedPhotoshoots?.pagination?.recordCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </QueryLoader>
  );
};

const addPhotoshootFormValidationSchema = yup
  .object({
    user: yup.number().required('Valitse asiakas').integer().min(1),
    name: yup.string().required('Syötä kuvakansion nimi'),
    fastOrderDiscountEnabled: yup.boolean(),
    priceList: yup.number().required('Valitse hinnasto').integer().min(1),
  })
  .required();

interface AddPhotoshootFormProps {
  defaultUserId?: number;
}
const AddPhotoshootForm = (props: AddPhotoshootFormProps) => {
  const apolloClient = useApolloClient();
  const [photoshootId, setPhotoshootId] = useState<number | null>(null);
  const [uploadBatch, setUploadBatch] =
    useState<PhotoUploadBatch>(emptyUploadBatch);
  const navigate = useNavigate();
  const { defaultUserId } = props;

  const photoshootCreated = !!photoshootId;

  return (
    <QueryLoader<AdminGetUsers> query={ADMIN_GET_USERS}>
      {({ users }) => (
        <QueryLoader<AdminGetPriceLists> query={ADMIN_GET_PRICE_LISTS}>
          {({ priceLists }) => (
            <Formik
              initialValues={{
                user: defaultUserId || '',
                name: '',
                fastOrderDiscountEnabled: true,
                priceList: priceLists.find((p) => p.code === 'default')?.id,
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={addPhotoshootFormValidationSchema}
              onSubmit={async (
                values,
                { setErrors, setStatus, resetForm, setSubmitting },
              ) => {
                if (!uploadBatch.hasPhotos) {
                  alert('Valitse valokuvat!');
                  return;
                }
                setSubmitting(true);

                let id = photoshootId;
                if (id === null) {
                  let response;
                  try {
                    response = await apiRequest('/photoshoots/', {
                      method: 'POST',
                      data: values,
                    });
                  } catch (e) {
                    console.error('Error submitting form', e);
                    setSubmitting(false);
                    setStatus({ success: false });
                    alert(e);
                    return;
                  }
                  id = response.id as number;
                  setPhotoshootId(id);
                }

                await uploadPhotos({
                  photoshootId: id,
                  uploadBatch,
                  apolloClient,
                });
                setSubmitting(false);
                setPhotoshootId(null);
                resetForm();

                await navigate(`/hallinta/kuvakansiot/${id}`);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <FormikForm>
                  <Form.Select
                    name="user"
                    label="Asiakas *"
                    disabled={photoshootCreated}
                  >
                    <option disabled value="">
                      Valitse käyttäjä
                    </option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.displayName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Input
                    name="name"
                    label="Kuvakansion nimi *"
                    disabled={photoshootCreated}
                  />
                  <Form.Textarea
                    name="message"
                    label="Viesti asiakkaalle"
                    disabled={photoshootCreated}
                  />
                  <Form.Select name="priceList" label="Hinnasto *">
                    <option disabled value="">
                      Valitse hinnasto
                    </option>
                    {priceLists.map((priceList) => (
                      <option key={priceList.id} value={priceList.id}>
                        {priceList.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Input
                    name="fastOrderDiscountEnabled"
                    label="Nopean tilaajan etu&nbsp;"
                    type="checkbox"
                    checked={values.fastOrderDiscountEnabled}
                    custom
                  />
                  <PhotoUploader onSelect={setUploadBatch} />
                  <div className="my-3">
                    <Button variant="primary" type="submit">
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Luo kuvakansio
                    </Button>
                  </div>
                </FormikForm>
              )}
            </Formik>
          )}
        </QueryLoader>
      )}
    </QueryLoader>
  );
};

interface AddPhotosFormProps {
  photoshootId: number;
}
const AddPhotosForm = (props: AddPhotosFormProps) => {
  const { photoshootId } = props;
  const [uploadBatch, setUploadBatch] =
    useState<PhotoUploadBatch>(emptyUploadBatch);
  const [submitting, setSubmitting] = useState(false);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const addPhotos = async () => {
    if (submitting) {
      return;
    }
    if (!uploadBatch.hasPhotos) {
      alert('Valitse valokuvat!');
      return;
    }
    setSubmitting(true);

    const id = photoshootId;
    await uploadPhotos({
      photoshootId: id,
      uploadBatch,
      apolloClient,
    });
    setSubmitting(false);

    await navigate(`/hallinta/kuvakansiot/${id}`);
  };

  return (
    <div>
      <QueryLoader<AdminGetPhotoshoot, AdminGetPhotoshootVariables>
        query={ADMIN_GET_PHOTOSHOOT}
        options={{
          variables: {
            id: photoshootId,
          },
        }}
      >
        {({ photoshoot }) => {
          if (!photoshoot) {
            return <div>Kuvakansiota ei löydy</div>;
          }

          return (
            <div>
              <h2>Lisää kuvia</h2>
              <h3>
                Kuvakansio: {photoshoot.name}
                <br />
                Asiakas: {photoshoot.user.displayName}
              </h3>
              <PhotoUploader onSelect={setUploadBatch} />
              <div className="my-3">
                <Button variant="primary" type="submit" onClick={addPhotos}>
                  {submitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{' '}
                  Lisää kuvat kuvakansioon
                </Button>
              </div>
            </div>
          );
        }}
      </QueryLoader>

      <div className="my-3">
        <Link to={`/hallinta/kuvakansiot/${photoshootId}`}>
          &laquo; Takaisin kuvakansioon
        </Link>
      </div>
    </div>
  );
};

interface UploadPhotosOpts {
  photoshootId: number;
  uploadBatch: PhotoUploadBatch;
  apolloClient: ApolloClient<object>;
}
async function uploadPhotos(opts: UploadPhotosOpts) {
  const { photoshootId, uploadBatch, apolloClient } = opts;
  await uploadBatch.upload(photoshootId);

  // TODO: optimize this so it doesn't have to fetch ALL photoshoots each time!
  await apolloClient.query({
    query: ADMIN_GET_PHOTOSHOOTS,
    fetchPolicy: 'network-only',
  });
}

//const contentTypes: Record<string, string> = {
//  'jpg': 'image/jpeg',
//  'jpeg': 'image/jpeg',
//  'png': 'image/png',
//}
//function getContentType(filename: string): string {
//  const parts = filename.toLowerCase().split('.');
//  const extension = parts[parts.length - 1];
//  return contentTypes[extension] || '';
//}
export default AdminPhotoshootsPage;
