import { gql } from '@apollo/client';

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUser on User {
    __typename
    id
    email
    displayName
    isAdmin
    addressDetails {
      firstName
      lastName
      companyName
      streetAddress
      postalCode
      postalLocation
      phoneNumber
    }
  }
`;

export const MODIFY_USER = gql`
  mutation ModifyUser(
    $user: Int!
    $firstName: String = null
    $lastName: String = null
    $companyName: String = null
    $phoneNumber: String = null
    $postalCode: String = null
    $postalLocation: String = null
    $streetAddress: String = null
    $oldPassword: String = null
    $password: String = null
    $isAdmin: Boolean = false
  ) {
    modifyUser(
      user: $user
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      phoneNumber: $phoneNumber
      postalCode: $postalCode
      postalLocation: $postalLocation
      streetAddress: $streetAddress
      oldPassword: $oldPassword
      password: $password
      isAdmin: $isAdmin
    ) {
      ok
      user {
        ...CurrentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;
