import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {
  GetProductCategories,
  GetProductCategoriesVariables,
  GetProductCategories_productCategories as ProductCategory,
  GetProductCategories_productCategories_products as Product,
} from 'types/__generated__/GetProductCategories';
import { GetPhotoshoot_photoshoot as Photoshoot } from './__generated__/GetPhotoshoot';
import { PhotoColor } from '../types/__generated__/globalTypes';
import { Photo } from '../types/__generated__/Photo';
import {
  AddShoppingCartItem,
  AddShoppingCartItemVariables,
} from '../types/__generated__/AddShoppingCartItem';
import { GetShoppingCart } from '../types/__generated__/GetShoppingCart';
import {
  ADD_SHOPPING_CART_ITEM,
  GET_SHOPPING_CART,
} from '../types/shoppingCart';
import {
  categoryInfoModalCategoryVar,
  categoryInfoModalOpenVar,
  shoppingCartOpenVar,
  productImageModalOpenVar,
} from '../uiState';
import { photoColorToText } from '../utils/photos';
import { calculatePrice } from '../utils/pricing';
import Price from './Price';
import Rating from './Rating';
import PhotoGrid from './PhotoGrid';
import Thumbnail from './Thumbnail';
import { Link } from '@reach/router';
import LoadingButton from './LoadingButton';
import HeartButton from 'components/elements/HeartButton';
import './PhotoOrderForm.scss';
import { getQuantityPlaceholder } from 'utils/products';
import { GET_PRODUCT_CATEGORIES } from 'types/shoppingCart';
import ProductPicker from 'components/elements/ProductPicker';
import { photoColors } from 'utils/colors';

const defaultSelectedCategoryId: number | null = null;
const defaultSelectedProduct: Product | null = null;
const defaultSelectedProperties: Record<string, string> = {};

type PhotoWithColor = Photo & { color: PhotoColor };
type PhotoWithNullableColor = Photo & {
  color: PhotoColor | null;
};

const getPhotoProductImageUrl = (
  selectedProduct: Product | null,
  data: GetProductCategories | undefined,
  selectedCategory?: ProductCategory | null,
): string | undefined => {
  const possibleProductImages = selectedCategory
    ? data?.productCategories
        .find((c) => c.id === selectedCategory.id)
        ?.products.filter((product) => !!product.image?.url)
        .map((product) => product.image?.url)
    : [];
  const uniqueProductImages = [...Array.from(new Set(possibleProductImages))];
  // If all products have the same image - use that for preview during incomplete product matches
  const sharedProductImage =
    uniqueProductImages.length === 1 ? uniqueProductImages[0] : null;

  /**
   * 1. If a product is found, use its unique image
   * 2. If some selections haven't been made yet, but all products have the same image - use that
   * 3. As a fallback use the default product category image
   */
  return (
    selectedProduct?.image?.url ||
    sharedProductImage ||
    selectedCategory?.image?.url
  );
};

interface PhotoOrderFormProps {
  photo: Photo;
  photoshoot: Photoshoot;
  photoColor: PhotoColor | null;
  setPhotoColor: (photoColor: PhotoColor | null) => void;
  markFavouritePhoto: () => void;
}
const PhotoOrderForm = (props: PhotoOrderFormProps) => {
  const { photo, photoshoot, photoColor, setPhotoColor, markFavouritePhoto } =
    props;
  const { data, loading, error } = useQuery<
    GetProductCategories,
    GetProductCategoriesVariables
  >(GET_PRODUCT_CATEGORIES, {
    variables: {
      priceList: photoshoot.priceList.code,
    },
  });

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(
    defaultSelectedProduct,
  );
  const [selectedProperties, setSelectedProperties] = useState<
    Record<string, string>
  >(defaultSelectedProperties);
  const [_quantity, setQuantity] = useState<number>(0);
  const [hiddenSelectors, setHiddenSelectors] = useState<boolean>(false);
  const [multiplePhotoSelectorOpen, setMultiplePhotoSelectorOpen] =
    useState(false);
  const isMultiplePhotoProduct = !!(
    selectedProduct && selectedProduct.maxPhotos > 1
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    defaultSelectedCategoryId,
  );
  const selectedCategory =
    data?.productCategories.find((c) => c.id === selectedCategoryId) || null;
  const quantity = selectedProduct?.maxQuantity === 1 ? 1 : _quantity;
  const productImageUrl = getPhotoProductImageUrl(
    selectedProduct,
    data,
    selectedCategory,
  );

  const clear = () => {
    setSelectedProduct(null);
    setSelectedProperties({});
    setPhotoColor(null);
    setQuantity(0);
    setMultiplePhotoSelectorOpen(false);
  };
  useEffect(() => {
    // Reset state if the currently viewed photo changed due to thumbnail or next/previous navigation,
    // but allow to continue selecting multi-photo products
    if (!multiplePhotoSelectorOpen) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo, multiplePhotoSelectorOpen]);

  if (multiplePhotoSelectorOpen && selectedProduct) {
    return (
      <div className="PhotoOrderForm">
        <Rating value={photo.rating} />
        <div className="my-2">{photo.code}</div>
        <MultiplePhotoSelector
          product={selectedProduct}
          quantity={quantity}
          currentPhoto={photo}
          photoshoot={photoshoot}
          setPhotoColor={setPhotoColor}
          close={() => setMultiplePhotoSelectorOpen(false)}
          clear={clear}
        />
      </div>
    );
  }
  return (
    <div className="PhotoOrderForm">
      <div className="rating-row">
        <Rating value={photo.rating} />
        <HeartButton
          inline
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            markFavouritePhoto();
          }}
          fill={photo.isFavourite}
        />
      </div>
      <div className="my-2">{photo.code}</div>
      {!hiddenSelectors && <hr />}
      {error ? (
        <p>{error.message}</p>
      ) : data && !loading ? (
        <ProductPicker
          productCategories={data.productCategories}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          setQuantity={setQuantity}
          setPhotoColor={setPhotoColor}
          openCategoryInfoModal={(category) => {
            categoryInfoModalCategoryVar(category);
            categoryInfoModalOpenVar(!!category);
          }}
          selectedCategory={selectedCategory}
          setSelectedCategoryId={setSelectedCategoryId}
          hiddenSelectors={hiddenSelectors}
          setHiddenSelectors={setHiddenSelectors}
        />
      ) : (
        ''
      )}
      {selectedProduct && (
        <React.Fragment>
          {selectedProduct.maxQuantity !== 1 && (
            <Form.Control
              className="my-2"
              placeholder={getQuantityPlaceholder(selectedProduct)}
              type="number"
              value={quantity || ''}
              min={selectedProduct.minQuantity}
              max={selectedProduct.maxQuantity || undefined}
              onChange={(e) => {
                setQuantity(parseInt(e.target.value));
              }}
            />
          )}
          {!isMultiplePhotoProduct && !hiddenSelectors && (
            <PhotoColorSelector
              colorChoice={photoshoot.priceList?.colorChoice}
              photoColor={photoColor}
              setPhotoColor={setPhotoColor}
              className="my-2"
            />
          )}
        </React.Fragment>
      )}
      {selectedProduct && (
        <div>
          <hr />
          <PriceDisplay
            product={selectedProduct}
            quantity={quantity}
            photoshoot={photoshoot}
          />
          {isMultiplePhotoProduct ? (
            <div className="mt-4">
              <Button
                block
                disabled={!quantity}
                onClick={(e) => {
                  e.preventDefault();
                  if (!quantity || quantity < 1) {
                    return;
                  }
                  setMultiplePhotoSelectorOpen(true);
                }}
              >
                Aloita kuvien valinta
              </Button>
            </div>
          ) : (
            <OrderPhotoButton
              photosWithColor={[
                {
                  ...photo,
                  color: photoColor,
                },
              ]}
              product={selectedProduct}
              quantity={quantity || null}
              afterOrder={clear}
            />
          )}
        </div>
      )}
      {!!productImageUrl && (
        <>
          <hr />
          <div
            className="sidebar-image-container"
            onClick={() => {
              productImageModalOpenVar({
                imageSrc: productImageUrl,
                imageAlt: selectedCategory?.name || '',
                title: selectedCategory?.name || '',
              });
            }}
          >
            <img src={productImageUrl} alt={selectedCategory?.name} />
          </div>
        </>
      )}
    </div>
  );
};

interface OrderPhotoButtonProps {
  photosWithColor: PhotoWithNullableColor[];
  product: Product | null;
  quantity: number | null;
  afterOrder?: () => void;
}
const OrderPhotoButton: React.FC<OrderPhotoButtonProps> = (props) => {
  const { photosWithColor, product, quantity, afterOrder } = props;
  const [addShoppingCartItem, { data, loading, error }] = useMutation<
    AddShoppingCartItem,
    AddShoppingCartItemVariables
  >(ADD_SHOPPING_CART_ITEM);
  const { data: shoppingCartData } =
    useQuery<GetShoppingCart>(GET_SHOPPING_CART);
  const [shoppingCartItemAdded, setShoppingCartItemAdded] = useState(false);
  const { shoppingCart } = shoppingCartData || {};

  useEffect(() => {
    // XXX: this is a stupid hack to get around async limitations with
    // apollo reactive vars...
    if (shoppingCartItemAdded) {
      shoppingCartOpenVar(true);
      setShoppingCartItemAdded(false);
    }
  }, [shoppingCartItemAdded, setShoppingCartItemAdded]);

  let valid = !!(product && quantity);
  if (valid) {
    if (product) {
      if (
        photosWithColor.length > product.maxPhotos ||
        photosWithColor.length < product.minPhotos
      ) {
        valid = false;
      }
    }
    for (const photoWithColor of photosWithColor) {
      if (photoWithColor.color === null) {
        valid = false;
      }
    }
  }

  let alreadyAdded = false;
  const maxQuantity = product?.maxQuantity || 0;
  photosWithColor?.forEach((photo) => {
    const existingProductCount = shoppingCart?.items.filter(
      (item) => item.product?.id === product?.id && photo.id === item.photo?.id,
    ).length;

    if (
      existingProductCount &&
      maxQuantity &&
      existingProductCount >= maxQuantity
    ) {
      valid = false;
      alreadyAdded = true;
    }
  });

  const handleAddToShoppingCart = () => {
    if (!valid || !product) {
      return;
    }

    addShoppingCartItem({
      variables: {
        photos: photosWithColor.map((p) => ({
          photo: p.id,
          photoColor: p.color,
        })),
        product: product.id,
        quantity: quantity,
      },
    })
      .then(() => {
        // XXX: we could just do shoppingCartOpenVar(true) here,
        // but it seems there's a bug with doing it asynchronously, which causes it not to work correctly
        // go figure....
        setShoppingCartItemAdded(true);
        if (afterOrder) {
          afterOrder();
        }
      })
      .catch((e) => {
        console.error('Error submitting order:', e);
      });
  };

  return (
    <div className="mt-4">
      <LoadingButton
        variant="primary"
        block
        onClick={handleAddToShoppingCart}
        loading={loading}
        disabled={!valid}
      >
        Lisää ostoskoriin
      </LoadingButton>

      {alreadyAdded && <div>Kuvatuote on jo ostoskorissa</div>}

      {data?.addShoppingCartItem?.ok ? (
        ''
      ) : error ? (
        <div>Virhe: {error.message}</div>
      ) : loading ? (
        //<div>Loading...</div>
        ''
      ) : (
        ''
      )}
    </div>
  );
};

interface PhotoColorSelectorProps {
  photoColor: PhotoColor | null;
  setPhotoColor: (photoColor: PhotoColor) => void;
  className?: string;
  colorChoice?: boolean;
  editable?: boolean;
}
export const PhotoColorSelector = (props: PhotoColorSelectorProps) => {
  const {
    photoColor,
    setPhotoColor,
    className = '',
    colorChoice = true,
    editable = true,
  } = props;

  return (
    <Form.Control
      disabled={editable === false}
      as="select"
      required
      className={className}
      value={photoColor || ''}
      onChange={(e) => {
        // TODO: cast without checks...
        setPhotoColor(e.target.value as PhotoColor);
      }}
    >
      <option disabled value="">
        Kuvan väri
      </option>
      {!colorChoice && (
        <option value={PhotoColor.color}>
          {photoColorToText(PhotoColor.color)}
        </option>
      )}
      {colorChoice &&
        photoColors.map((color) => (
          <option value={color} key={color}>
            {photoColorToText(color)}
          </option>
        ))}
    </Form.Control>
  );
};

interface MultiplePhotoSelectorProps {
  product: Product;
  currentPhoto: Photo;
  photoshoot: Photoshoot;
  quantity: number;
  close: () => void;
  clear?: () => void;
  setPhotoColor: (photoColor: PhotoColor | null) => void;
}
const MultiplePhotoSelector: React.FC<MultiplePhotoSelectorProps> = (props) => {
  const {
    product,
    quantity,
    currentPhoto,
    photoshoot,
    close,
    clear,
    setPhotoColor,
  } = props;
  const [selectedPhotos, setSelectedPhotos] = useState<PhotoWithColor[]>([]);
  const defaultPhotoColor: PhotoColor | null = null;
  const [selectedPhotoColor, setSelectedPhotoColor] =
    useState<PhotoColor | null>(defaultPhotoColor);
  const { minPhotos, maxPhotos } = product;
  const numSelectedPhotos = selectedPhotos.length;
  const canBeOrdered =
    numSelectedPhotos >= minPhotos && numSelectedPhotos <= maxPhotos;
  const currentPhotoWithColor = selectedPhotos.find(
    (p) => p.id === currentPhoto.id,
  );
  const currentPhotoSelected = !!currentPhotoWithColor;

  const selectCurrentPhoto = () => {
    if (!selectedPhotoColor) {
      return;
    }
    setSelectedPhotos((photos) => [
      ...photos,
      {
        ...currentPhoto,
        color: selectedPhotoColor,
      },
    ]);
    setSelectedPhotoColor(defaultPhotoColor);
    setPhotoColor(defaultPhotoColor);
  };

  const deselectCurrentPhoto = () => {
    setSelectedPhotos((photos) => {
      const copy = [...photos];
      const index = copy.findIndex((p) => p.id === currentPhoto.id);
      copy.splice(index, 1);
      return copy;
    });
  };

  return (
    <div className="MultiplePhotoSelector">
      <div>
        {maxPhotos === minPhotos ? (
          <span>
            Valitse <strong>{maxPhotos}</strong> kuvaa.
          </span>
        ) : (
          <span>
            Valitse vähintään <strong>{minPhotos}</strong> ja enintään{' '}
            <strong>{maxPhotos}</strong> kuvaa.
          </span>
        )}
        <br />
        <strong>Kuvia valittu</strong>: {selectedPhotos.length}
        <br />
      </div>
      <PhotoGrid itemSize="medium">
        {selectedPhotos.map((photo) => (
          <Link to={`/kuvat/${photoshoot.slug}/${photo.code}`} key={photo.id}>
            <Thumbnail
              src={photo.previewUrl}
              alt={photo.code}
              variant="square"
              className={`photocolor-${photo.color}`}
            />
          </Link>
        ))}
      </PhotoGrid>
      <hr />
      <div>
        {currentPhotoSelected ? (
          <p>
            Kuva on valittu tuotteelle.
            <br />
            <a href="#remove" onClick={deselectCurrentPhoto}>
              Poista valinta.
            </a>
          </p>
        ) : numSelectedPhotos < maxPhotos ? (
          <div>
            <PhotoColorSelector
              colorChoice={photoshoot.priceList?.colorChoice}
              photoColor={selectedPhotoColor}
              setPhotoColor={(color: PhotoColor) => {
                setSelectedPhotoColor(color); // Update small menu option preview
                setPhotoColor(color); // Update large preview image color
              }}
              className="my-2"
            />
            <Button
              block
              disabled={!selectedPhotoColor}
              onClick={selectCurrentPhoto}
            >
              Valitse kuva
            </Button>
          </div>
        ) : (
          <div>Kuvatuotteelle on valittu jo enimmäismäärä kuvia.</div>
        )}
      </div>
      {canBeOrdered && (
        <>
          <hr />
          <PriceDisplay
            product={product}
            quantity={quantity}
            numPhotos={selectedPhotos.length}
            photoshoot={photoshoot}
          />
          <OrderPhotoButton
            photosWithColor={selectedPhotos}
            product={product}
            quantity={quantity}
            afterOrder={() => {
              close();
              if (clear) {
                clear();
              }
            }}
          />
        </>
      )}
      <hr />
      <div className="my-2">
        <button className="btn btn-link" onClick={close}>
          &laquo; Lopeta kuvien valinta
        </button>
      </div>
    </div>
  );
};

interface PriceDisplayProps {
  product: Product | null;
  photoshoot: Photoshoot;
  quantity: number;
  numPhotos?: number;
}
const PriceDisplay = (props: PriceDisplayProps) => {
  const { product, quantity, numPhotos, photoshoot } = props;
  const { data, loading } = useQuery<GetShoppingCart>(GET_SHOPPING_CART);

  if (!product || !quantity || loading) {
    return null;
  }

  const { shoppingCart } = data || {};
  const freeProductQuantities: Record<string, number> = {};
  photoshoot?.freeProducts?.forEach((freeProduct) => {
    const remainingQuantity = freeProduct.quantity - freeProduct.quantityUsed;
    if (remainingQuantity <= 0) {
      return;
    }
    freeProductQuantities[freeProduct.product.id] = remainingQuantity;
  });
  // Consider free products that are already in the shopping cart
  shoppingCart?.items
    .filter(
      (item) =>
        item.photo.photoshoot.id === photoshoot.id &&
        freeProductQuantities[item.product.id],
    )
    .forEach((item) => {
      const productId: number = item.product.id;
      let freeProductQuantity: number = freeProductQuantities[productId];
      if (freeProductQuantity >= item.quantity) {
        freeProductQuantity -= item.quantity;
      } else {
        freeProductQuantity = 0;
      }
      freeProductQuantities[productId] = freeProductQuantity;
    });
  const freeQuantity: number = freeProductQuantities[product.id] || 0;
  const price: number = calculatePrice(
    product.pricingModel,
    product.priceGroups,
    quantity,
    numPhotos,
    freeQuantity,
  );
  if (isNaN(price)) {
    return null;
  }
  return (
    <div className="total-price-container">
      <span className="total-price">
        <Price>{price.toFixed(2)}</Price>
      </span>
    </div>
  );
};

export default PhotoOrderForm;
