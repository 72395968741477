import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import * as GetLoginDataTypes from './__generated__/GetLoginData';
import LoadingIndicator from './LoadingIndicator';
import LoginPage from './LoginPage';

const GET_LOGIN = gql`
  query GetLogin {
    me {
      id
      displayName
    }
  }
`;

const LoginContainer: React.FC = ({ children }) => {
  const { data } = useQuery<GetLoginDataTypes.GetLoginData>(GET_LOGIN);

  if (!data) {
    return <LoadingIndicator fullScreen />;
  }

  const currentUser = data.me;
  if (!currentUser) {
    return <LoginPage />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default LoginContainer;
