import * as React from 'react';
import { sharePhotoshootModalOpenVar } from 'uiState';
import './SharePhotoshootLink.scss';

interface SharePhotoshootLinkProps {
  children?: string;
  photoshootId: number;
}
const SharePhotoshootLink: React.FC<SharePhotoshootLinkProps> = ({
  photoshootId,
}: SharePhotoshootLinkProps) => (
  <div className="SharePhotoshootLink">
    <button
      className="btn btn-link"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        sharePhotoshootModalOpenVar(photoshootId);
      }}
    >
      Hallinnoi käyttöoikeuksia
    </button>
  </div>
);

export default SharePhotoshootLink;
