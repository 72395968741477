import { gql } from '@apollo/client';
import { PHOTOSHOOT_FREE_PRODUCT_FRAGMENT } from 'types/photoshoots';

export const GET_PRODUCT_CATEGORIES = gql`
  query GetProductCategories($priceList: String!) {
    productCategories(priceList: $priceList) {
      id
      name
      description
      availableProperties {
        key
        name
        values {
          value
          name
        }
      }
      image {
        url
      }
      products {
        id
        minQuantity
        maxQuantity
        simplePrice
        minPhotos
        maxPhotos
        pricingModel
        image {
          url
        }
        priceGroups {
          minQuantity
          unitPrice
        }
        properties {
          key
          value
          isDefault
        }
      }
      priceList {
        colorChoice
      }
    }
  }
`;

export const SHOPPING_CART_FRAGMENT = gql`
  fragment ShoppingCart on Order {
    __typename
    id
    quantity
    totalPrice
    totalVat
    totalRetouchingFee
    orderedGallery
    orderedGalleryFee
    discounts {
      type
      name
      percentage
    }
    items {
      id
      quantity
      price
      product {
        id
        maxPhotos
        minQuantity
        maxQuantity
        category {
          id
          name
          priceList {
            code
            colorChoice
          }
        }
        properties {
          keyName
          key
          name
          value
        }
      }
      # photoColor and photo should be removed
      photoColor
      photo {
        id
        code
        previewUrl
        photoshoot {
          id
          freeProducts {
            ...PhotoshootFreeProduct
          }
        }
      }
      orderItemPhotos {
        photo {
          code
          previewUrl
        }
        photoColor
      }
    }
  }
  ${PHOTOSHOOT_FREE_PRODUCT_FRAGMENT}
`;

export const GET_SHOPPING_CART = gql`
  query GetShoppingCart {
    shoppingCart {
      ...ShoppingCart
    }
  }
  ${SHOPPING_CART_FRAGMENT}
`;

export const ADD_SHOPPING_CART_ITEM = gql`
  mutation AddShoppingCartItem(
    $photos: [OrderItemPhotoInput!]!
    $product: Int!
    $quantity: Int = 1
  ) {
    addShoppingCartItem(
      photos: $photos
      product: $product
      quantity: $quantity
    ) {
      ok
      shoppingCart {
        ...ShoppingCart
      }
    }
  }
  ${SHOPPING_CART_FRAGMENT}
`;

export const EDIT_SHOPPING_CART_ITEM = gql`
  mutation EditShoppingCartItem(
    $item: Int!
    $quantity: Int
    $product: Int
    $photoColor: PhotoColor
  ) {
    editShoppingCartItem(
      item: $item
      quantity: $quantity
      product: $product
      photoColor: $photoColor
    ) {
      ok
      shoppingCart {
        ...ShoppingCart
      }
    }
  }
  ${SHOPPING_CART_FRAGMENT}
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder(
    $order: Int!
    $firstName: String!
    $lastName: String!
    $companyName: String
    $streetAddress: String!
    $postalCode: String!
    $postalLocation: String!
    $phoneNumber: String!
    $email: String!
    $additionalInfo: String
  ) {
    submitOrder(
      order: $order
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      streetAddress: $streetAddress
      postalCode: $postalCode
      postalLocation: $postalLocation
      phoneNumber: $phoneNumber
      email: $email
      additionalInfo: $additionalInfo
    ) {
      ok
      shoppingCart {
        ...ShoppingCart
      }
    }
  }
  ${SHOPPING_CART_FRAGMENT}
`;

export const EDIT_ORDER = gql`
  mutation EditOrder($order: Int!, $orderedGallery: Boolean!) {
    editOrder(order: $order, orderedGallery: $orderedGallery) {
      ok
    }
  }
`;
