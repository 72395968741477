import * as React from 'react';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import './MainLayout.scss';

const MainLayout: React.FC = ({ children }) => {
  return (
    <div className="MainLayout">
      <SiteHeader />
      <main>{children}</main>
      <SiteFooter />
    </div>
  );
};
export default MainLayout;
