import * as React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Login, LoginVariables } from './__generated__/Login';
import Form from 'react-bootstrap/Form';
import Logo from './Logo';
import Button from 'react-bootstrap/Button';
import './LoginForm.scss';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      token
    }
  }
`;

const LoginForm: React.FC<RouteComponentProps> = () => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [login, { loading, error, client }] = useMutation<
    Login,
    LoginVariables
  >(LOGIN, {
    onCompleted: async (data) => {
      const token = data.login?.token;
      if (token) {
        setValidated(true);
        //console.log('token', 'yass');
        localStorage.setItem('omapsv-auth-token', token);
        await client.cache.reset();
      } else {
        //console.log('token', 'nay');
        setLoginError('Virheellinen sähköpostiosoite tai salasana');
        setValidated(false);
      }
    },
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    setLoginError('');
    if (form.checkValidity() === false) {
      setLoginError('Tarkista sähköpostiosoite ja salasana');
      return;
    }
    setValidated(true);
    await login({
      variables: {
        email,
        password,
      },
    });
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="LoginForm"
    >
      <Logo />
      <Form.Group controlId="login-email">
        <Form.Label className="sr-only">Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Sähköposti"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="login-password">
        <Form.Label className="sr-only">Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button block variant="primary" type="submit" disabled={loading}>
        Kirjaudu
      </Button>
      <div className="my-2">
        <Link to="/unohtunut-salasana">Unohtuiko salasana?</Link>
      </div>
      {loginError ? (
        <div className="LoginForm-errors">{loginError}</div>
      ) : error ? (
        <div className="LoginForm-errors">{error.message}</div>
      ) : (
        <div className="LoginForm-errors">&nbsp;</div>
      )}
    </Form>
  );
};
export default LoginForm;
