import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

const TOKEN_STORAGE_KEY = 'omapsv-auth-token';
const GRAHPQL_URI = (process.env.REACT_APP_BACKEND_URI || '') + '/graphql';

const batchHttplink = new BatchHttpLink({
  uri: GRAHPQL_URI,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Token ${token}` : '',
    },
  };
});
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(batchHttplink),
});
