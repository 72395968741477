import * as React from 'react';
import IconButton from 'components/elements/IconButton';
import { GetPhotoshoot_photoshoot } from 'components/__generated__/GetPhotoshoot';
import PhotoshootMessage from 'components/elements/PhotoshootMessage';
import './PhotoshootToolbar.scss';

interface PhotoshootToolbarProps {
  children?: any;
  photoshoot: GetPhotoshoot_photoshoot;
  setSelectCompare?: (e: React.MouseEvent) => void;
}
const PhotoshootToolbar: React.FC<PhotoshootToolbarProps> = ({
  photoshoot,
  setSelectCompare,
}: PhotoshootToolbarProps) => {
  return (
    <div className="PhotoshootToolbar">
      <div className="left-side">
        <PhotoshootMessage>{photoshoot.message}</PhotoshootMessage>
      </div>
      <div className="right-side">
        {!!setSelectCompare && (
          <IconButton
            whiteBackground={false}
            icon="arrow-repeat"
            onClick={setSelectCompare}
          >
            Vertaa kuvia
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default PhotoshootToolbar;
