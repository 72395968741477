import * as React from 'react';
import './IconButton.scss';
import HeartIcon from 'bootstrap-icons/icons/heart.svg';
import HeartFilledIcon from 'bootstrap-icons/icons/heart-fill.svg';
import ShareIcon from 'bootstrap-icons/icons/share.svg';
import ArrowRepeatIcon from 'bootstrap-icons/icons/arrow-repeat.svg';
import FullscreenIcon from 'bootstrap-icons/icons/arrows-fullscreen.svg';
import ArrowsContractIcon from 'bootstrap-icons/icons/arrows-angle-contract.svg';
import PlusIcon from 'bootstrap-icons/icons/plus.svg';
import DashIcon from 'bootstrap-icons/icons/dash.svg';

// NOTE: these need to be imported individually and cached,
// otherwise we get all 4k bootstrap icons in the bundle.
const ICONS = {
  heart: HeartIcon,
  'heart-fill': HeartFilledIcon,
  share: ShareIcon,
  'arrow-repeat': ArrowRepeatIcon,
  'arrows-fullscreen': FullscreenIcon,
  'arrows-contract': ArrowsContractIcon,
  plus: PlusIcon,
  dash: DashIcon,
  minus: DashIcon,
};

interface IconButtonProps {
  children?: React.ReactNode;
  className?: string;
  icon: string;
  onClick?: (e: React.MouseEvent) => void;
  whiteBackground?: boolean;
}
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  children,
  className = '',
  whiteBackground = true,
}: IconButtonProps) => {
  const [iconObject, setIconObject] = React.useState<string | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const loadIcon = async () => {
      const obj = (ICONS as any)[icon] || null;
      setIconObject(obj);
    };

    loadIcon();
  }, [icon, iconObject, setIconObject]);
  return (
    <div
      className={`icon-button-wrapper ${className}`}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        onClick?.(e);
      }}
    >
      {whiteBackground ? (
        <div className={`icon-background`}>
          <img src={iconObject} alt={''} />
        </div>
      ) : (
        <img src={iconObject} alt={''} />
      )}
      {!!children && <div className="label">{children}</div>}
    </div>
  );
};

export default IconButton;
