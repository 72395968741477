import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  categoryInfoModalOpenVar,
  categoryInfoModalCategoryVar,
  productImageModalOpenVar,
} from '../uiState';
import GenericInfoModal from 'components/GenericInfoModal';

export const ProductImageModal: React.FC = () => {
  const productImageProps = useReactiveVar(productImageModalOpenVar);
  if (!productImageProps) {
    return null;
  }
  const { imageSrc, imageAlt, title } = productImageProps;
  const handleClose = () => productImageModalOpenVar(null);
  return (
    <GenericInfoModal
      open={!!imageSrc}
      onHide={handleClose}
      imageUrl={imageSrc}
      imageAlt={imageAlt}
      title={title}
    />
  );
};

const CategoryInfoModal: React.FC = () => {
  const open = useReactiveVar(categoryInfoModalOpenVar);
  const category = useReactiveVar(categoryInfoModalCategoryVar);
  const handleClose = () => categoryInfoModalOpenVar(false);
  if (!category) {
    return null;
  }
  return (
    <GenericInfoModal
      open={open}
      onHide={handleClose}
      title={category.name}
      imageUrl={category.image?.url}
      imageAlt={`Tuotekuva: ${category.name}`}
      description={category.description}
      modalClassName="category-modal"
    />
  );
};
export default CategoryInfoModal;
