import * as React from 'react';
import debounce from 'lodash/debounce';
import Form from 'react-bootstrap/Form';

export const delayedValueUpdate = debounce(
  (value, callback) => callback?.(value),
  500,
);

/**
 * Form.Control instance with its own internal state for displaying a separate defaultValue
 * as a placeholder without binding to it, i.e. allowing to change to another value via onChange
 * without having to modify the defaultValue.
 * Performs a debounced callback for real-time Fetch/XHR calls without relying on form submit.
 */
interface ManagedFormControlProps {
  name?: string | undefined;
  label?: string | undefined;
  children?: any;
  placeholder?: string;
  minQuantity?: number;
  maxQuantity?: number;
  defaultValue?: string | number;
  onChange?: (v: string | number) => void;
  inputType?: string;
  className?: string;
  as?: any | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  FormControlClass?: any | undefined;
}
const ManagedFormControl: React.FC<ManagedFormControlProps> = ({
  name,
  label,
  children,
  placeholder,
  onChange,
  as,
  defaultValue = '',
  minQuantity = 0,
  maxQuantity = 0,
  inputType = 'number',
  className = 'my-2',
  required = false,
  disabled = false,
  FormControlClass = Form.Control,
}: ManagedFormControlProps) => {
  const [value, setValue] = React.useState<string | number>(defaultValue);
  return (
    <FormControlClass
      name={name}
      label={label}
      required={required}
      as={as}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      type={inputType}
      value={value || value === 0 ? value : ''}
      min={minQuantity}
      max={maxQuantity || undefined}
      onChange={(e: Event) => {
        const element = e.currentTarget as HTMLInputElement;
        let value: number | string = element.value;
        if (inputType === 'number') {
          if (minQuantity && Number(value) < minQuantity) {
            value = minQuantity;
          }
          if (maxQuantity && Number(value) > maxQuantity) {
            value = maxQuantity;
          }
        }
        setValue(value);
        delayedValueUpdate(value, onChange);
      }}
    >
      {children}
    </FormControlClass>
  );
};

export default ManagedFormControl;
