import * as React from 'react';
import { useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as yup from 'yup';
import { Form as FormikForm, Formik } from 'formik';
import { Form } from 'react-bootstrap-formik';

import { GetSubmitOrderPageData } from './__generated__/GetSubmitOrderPageData';
import { CurrentUser } from '../types/__generated__/CurrentUser';
import {
  GET_SHOPPING_CART,
  SHOPPING_CART_FRAGMENT,
  SUBMIT_ORDER,
} from '../types/shoppingCart';
import {
  SubmitOrder,
  SubmitOrderVariables,
} from '../types/__generated__/SubmitOrder';
import { CURRENT_USER_FRAGMENT } from '../types/users';
import SecondaryHeader from './SecondaryHeader';
import LoadingButton from './LoadingButton';
import OrderSummary from './OrderSummary';
import { ShoppingCart } from '../types/__generated__/ShoppingCart';
import LoadingIndicator from './LoadingIndicator';

import './SubmitOrderPage.scss';

interface SubmitOrderPageProps extends RouteComponentProps {}

const GET_SUBMIT_ORDER_PAGE_DATA = gql`
  query GetSubmitOrderPageData {
    me {
      ...CurrentUser
    }
    shoppingCart {
      ...ShoppingCart
    }
  }
  ${CURRENT_USER_FRAGMENT}
  ${SHOPPING_CART_FRAGMENT}
`;
const SubmitOrderPage: React.FC<SubmitOrderPageProps> = () => {
  const { data, loading, error } = useQuery<GetSubmitOrderPageData>(
    GET_SUBMIT_ORDER_PAGE_DATA,
  );
  const [submittedOrder, setSubmittedOrder] = useState<ShoppingCart | null>(
    null,
  );
  const [productsWithPendingChanges, setProductsWithPendingChanges] = useState<
    Record<number, boolean>
  >({});
  if (error) {
    return <Container fluid>Virhe: {error}</Container>;
  }
  if (loading) {
    return (
      <Container fluid>
        <LoadingIndicator />
      </Container>
    );
  }
  if (!data) {
    return (
      <Container fluid>
        <LoadingIndicator />
      </Container>
    );
  }
  const { shoppingCart } = data;
  if (!shoppingCart) {
    return <div>No data</div>;
  }
  const currentUser = data.me;
  if (!currentUser) {
    return <div>User is not logged in.</div>;
  }

  return (
    <div className="SubmitOrderPage">
      <SecondaryHeader path="Lähetä tilaus" />
      <Container fluid>
        {submittedOrder ? (
          <ThankYou order={submittedOrder} />
        ) : shoppingCart.items.length > 0 ? (
          <>
            <div className="shopping-cart-container">
              <h3>Ostoskori</h3>
              <OrderSummary
                order={shoppingCart}
                editable
                productsWithPendingChanges={productsWithPendingChanges}
                toggleProductPendingChanges={(id: number, value: boolean) => {
                  setProductsWithPendingChanges({
                    ...productsWithPendingChanges,
                    [id]: value,
                  });
                }}
              />
            </div>
            <div className="order-info-container">
              <OrderInfoForm
                shoppingCart={shoppingCart}
                currentUser={currentUser}
                setSubmittedOrder={setSubmittedOrder}
                productsWithPendingChanges={productsWithPendingChanges}
              />
            </div>
          </>
        ) : (
          <div>
            <p>Ostoskorissa ei ole kuvatuotteita.</p>
            <p>
              <Link to="/kuvat">Takaisin omiin kuviin &raquo;</Link>
            </p>
          </div>
        )}
      </Container>
    </div>
  );
};

interface OrderInfoFormProps {
  shoppingCart: ShoppingCart;
  currentUser: CurrentUser;
  setSubmittedOrder?: (submitted: ShoppingCart) => void;
  productsWithPendingChanges?: Record<number, boolean>;
}
const orderInfoFormValidationSchema = yup
  .object({
    firstName: yup.string().required('Syötä etunimi'),
    lastName: yup.string().required('Syötä sukunimi'),
    companyName: yup.string(),
    streetAddress: yup.string().required('Syötä katuosoite'),
    postalCode: yup
      .string()
      .trim()
      .matches(/^[0-9]{5}$/, 'Virheellinen postinumero')
      .required('Syötä postinumero'),
    postalLocation: yup.string().required('Syötä paikkakunta'),
    phoneNumber: yup.string().required('Syötä puhelinnumero'),
    email: yup
      .string()
      .email('Virheellinen sähköposti')
      .required('Syötä sähköposti'),
    additionalInfo: yup.string(),
  })
  .required();
const OrderInfoForm = ({
  shoppingCart,
  currentUser,
  setSubmittedOrder,
  productsWithPendingChanges = {},
}: OrderInfoFormProps) => {
  const [submitOrder] = useMutation<SubmitOrder, SubmitOrderVariables>(
    SUBMIT_ORDER,
  );
  const client = useApolloClient();
  const orderId = shoppingCart.id;
  const pendingChanges: boolean = !!Object.values(
    productsWithPendingChanges,
  ).filter((v) => v).length;
  return (
    <div>
      <Formik
        initialValues={{
          ...currentUser.addressDetails,
          email: currentUser.email,
          additionalInfo: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={orderInfoFormValidationSchema}
        onSubmit={async (values) => {
          const response = await submitOrder({
            variables: {
              order: orderId,
              ...values,
            } as any,
          });
          const newShoppingCart = response.data?.submitOrder?.shoppingCart;
          client.writeQuery({
            query: GET_SHOPPING_CART,
            data: {
              shoppingCart: newShoppingCart,
            },
          });
          // Clear the cache to ensure all linked items (e.g. photoshoots)
          // are refreshed with the latest data (e.g. free photos)
          client.resetStore();
          if (setSubmittedOrder) {
            setSubmittedOrder(shoppingCart);
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Form.Input name="firstName" placeholder="Etunimi" />
            <Form.Input name="lastName" placeholder="Sukunimi" />
            <Form.Input name="companyName" placeholder="Yrityksen nimi" />
            <Form.Input name="streetAddress" placeholder="Katuosoite" />
            <Row>
              <Col xs={5}>
                <Form.Input name="postalCode" placeholder="Postinro" />
              </Col>
              <Col xs={7}>
                <Form.Input name="postalLocation" placeholder="Paikkakunta" />
              </Col>
            </Row>
            <Form.Input name="phoneNumber" placeholder="Puhelin" />
            <Form.Input name="email" placeholder="Sähköposti" />
            <Form.Textarea name="additionalInfo" placeholder="Lisätietoja" />
            <LoadingButton
              block
              variant="primary"
              type="submit"
              loading={isSubmitting}
              disabled={pendingChanges}
              className={pendingChanges ? 'is-invalid' : undefined}
            >
              Lähetä tilaus
            </LoadingButton>
            <div className="invalid-feedback">
              Ostoskorin muokkaus on kesken.
            </div>
            <span className="tos">Tilaus on sitova</span>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

interface ThankYouProps {
  order: ShoppingCart;
}
const ThankYou = (props: ThankYouProps) => {
  const { order } = props;
  return (
    <div className="ThankYou">
      <h2>Kiitos tilauksesta!</h2>
      <p>Saat tilauksesta vahvistuksen sähköpostiisi.</p>
      <p>Tässä vielä yhteenveto tilauksestasi:</p>
      <OrderSummary order={order} editable={false} />
    </div>
  );
};

export default SubmitOrderPage;
