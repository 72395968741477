/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum OrderStatus {
  delivered = "delivered",
  pending = "pending",
  processed = "processed",
  processing = "processing",
  submitted = "submitted",
}

/**
 * An enumeration.
 */
export enum PhotoColor {
  bw = "bw",
  color = "color",
  sepia = "sepia",
}

/**
 * An enumeration.
 */
export enum PricingModel {
  bracket = "bracket",
  per_photo_bracket = "per_photo_bracket",
  per_photo_unit = "per_photo_unit",
  unit = "unit",
}

export interface OrderItemPhotoInput {
  photo: number;
  photoColor?: PhotoColor | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
