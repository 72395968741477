// TODO: there's a lot of overlap with PhotoUploader... perhaps this could be factored better
import * as React from 'react';
import { apiRequest, getAuthHeader } from '../api';
import { getDataUrl } from '../utils/images';

import './ImageInput.scss';

interface ImageInputProps {
  initialUrl?: string;
  onChange?: (file: File | null) => void;
}
interface ImageInputState {
  file: File | null;
  dataUrl: string;
}
class ImageInput extends React.Component<ImageInputProps, ImageInputState> {
  fileInput: React.Ref<any>;

  constructor(props: ImageInputProps) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      file: null,
      dataUrl: '',
    };
  }

  public get file(): File | null {
    return this.state.file;
  }

  public async upload(pathPrefix: string): Promise<string> {
    const { file } = this.state;
    if (!file) {
      throw new Error('no file to upload');
    }
    const path = `${pathPrefix || ''}${file.name}`;
    await uploadImage({
      file,
      path,
    });
    return path;
  }

  private setFile(file: File | null) {
    const { onChange } = this.props;
    if (file) {
      this.setState({
        file,
      });
      getDataUrl(file).then((newDataUrl) => {
        this.setState({ dataUrl: newDataUrl });
      });
    } else {
      this.setState({
        file,
        dataUrl: '',
      });
    }
    if (onChange) {
      onChange(file || null);
    }
  }

  render() {
    const { dataUrl } = this.state;
    const { initialUrl } = this.props;

    return (
      <div className="ImageInput">
        {dataUrl ? (
          <div className="image-preview uploaded">
            <img src={dataUrl} alt="to upload" />
          </div>
        ) : (
          initialUrl && (
            <div className="image-preview initial">
              <img src={initialUrl} alt="initial" />
            </div>
          )
        )}
        <div>
          <input
            type="file"
            ref={this.fileInput}
            onChange={(e) => {
              const files = e.target.files;
              this.setFile(files ? files[0] : null);
            }}
            accept="image/jpg, image/jpeg, image/png"
          />
        </div>
      </div>
    );
  }
}

interface UploadDetails {
  url: string;
  fields: Record<string, string>;
}
interface UploadImageOpts {
  file: File;
  path: string;
}
async function uploadImage(opts: UploadImageOpts) {
  const { file, path } = opts;

  const uploadDetails = await apiRequest<UploadDetails>(
    `/media/images/upload/`,
    {
      qs: {
        path,
      },
    },
  );
  const { url, fields } = uploadDetails;

  const body = new FormData();
  for (const [field, value] of Object.entries(fields)) {
    body.append(field, value);
  }
  body.append('file', file);

  // another hack to include auth for local urls
  const headers = new Headers();
  if (url.startsWith('/')) {
    headers.set('Authorization', getAuthHeader());
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body,
  });

  if (!response.ok) {
    throw Error('Upload failed!');
  }
}

export default ImageInput;
