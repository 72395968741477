import * as React from 'react';
import './Checkbox.scss';

interface CheckboxProps {
  children?: React.ReactNode;
  checked?: boolean | undefined;
  onChange: (e: any) => void;
}
const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  children,
  checked,
}: CheckboxProps) => (
  <div onClick={onChange} className="checkbox-wrapper">
    <div className="checkbox-slot">
      <input onChange={onChange} type="checkbox" checked={!!checked} />
      <div className="tick"></div>
    </div>
    {!!children && <div className="checkbox-children">{children}</div>}
  </div>
);

export default Checkbox;
