import debounce from 'lodash/debounce';

export function isMobileScreen(): boolean {
  return window.innerWidth <= 575;
}

export function mobileHandler(
  callback: (...args: any) => void,
): (...args: any) => void {
  return (...args: any) => {
    if (isMobileScreen()) {
      callback(...args);
    }
  };
}

export function scrollTo(top: number) {
  if (window['scroll']) {
    window.scroll({
      top,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo(0, top);
  }
}

export const setupCustomVHCssProperty = () => {
  // 100vh sucks for mobile because of the topbar
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // We listen to the resize event
  window.addEventListener(
    'resize',
    debounce(() => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 100),
  );
};
