import * as React from 'react';
import { Link } from '@reach/router';
import { GetPhotoshoot_photoshoot as Photoshoot } from './__generated__/GetPhotoshoot';
import { Photo } from '../types/__generated__/Photo';
import { PhotoColor } from '../types/__generated__/globalTypes';
import { useState } from 'react';
import { mobileHandler, scrollTo } from '../utils/responsive';
import Thumbnail from './Thumbnail';
import PhotoOrderForm from './PhotoOrderForm';
import PhotoDisplay from 'components/elements/PhotoDisplay';

import './PhotoDetails.scss';
import classNames from 'classnames';

interface PhotoDetailsProps {
  photoshoot: Photoshoot;
  selectedPhoto: Photo;
  getMarkFavouriteAction: (photo: Photo) => () => void;
}
const PhotoDetails = (props: PhotoDetailsProps) => {
  const { photoshoot, selectedPhoto, getMarkFavouriteAction } = props;

  const [photoColor, setPhotoColor] = useState<PhotoColor | null>(null);
  const photoIndex = photoshoot.photos.indexOf(selectedPhoto);
  const previousPhoto = photoshoot.photos[photoIndex - 1];
  const nextPhoto = photoshoot.photos[photoIndex + 1];

  return (
    <div className="PhotoDetails">
      <PhotoDisplay
        selectedPhoto={selectedPhoto}
        previousPhoto={previousPhoto}
        nextPhoto={nextPhoto}
        photoColor={photoColor}
        photoshoot={photoshoot}
      />
      <div className="sidebar">
        <PhotoOrderForm
          photo={selectedPhoto}
          photoshoot={photoshoot}
          photoColor={photoColor}
          setPhotoColor={setPhotoColor}
          markFavouritePhoto={getMarkFavouriteAction(selectedPhoto)}
        />
      </div>
      <ul className="thumbnails">
        {photoshoot.photos.map((photo) => (
          <li key={photo.code}>
            <Link
              to={`/kuvat/${photoshoot.slug}/${photo.code}`}
              onClick={mobileHandler(() => scrollTo(0))}
            >
              <Thumbnail
                className={classNames({
                  selected: selectedPhoto?.code === photo.code,
                })}
                src={photo.previewUrl}
                alt={photo.code}
                caption={photo.code}
                isFavourite={photo.isFavourite}
                markFavouritePhoto={getMarkFavouriteAction(photo)}
                size="small"
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default PhotoDetails;
