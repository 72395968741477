import { makeVar } from '@apollo/client';
import { GetProductCategories_productCategories as ProductCategory } from './components/__generated__/GetProductCategories';
import { ProductInfoRecord } from 'types/productInfo';

export const shoppingCartOpenVar = makeVar(false);
export const customerServiceModalOpenVar = makeVar(false);
export const categoryInfoModalCategoryVar = makeVar<ProductCategory | null>(
  null,
);
export const categoryInfoModalOpenVar = makeVar(false);
export const sharePhotoshootModalOpenVar = makeVar<number>(0);

interface ProductImageProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
}

export const productImageModalOpenVar = makeVar<ProductImageProps | null>(null);
export const productInfoModalOpenVar = makeVar<ProductInfoRecord | null>(null);
