import * as React from 'react';
import logo from '../img/logo/oma-psv-logo3.svg';

type LogoProps = Record<string, any>;
const Logo = (props: LogoProps) => {
  const logoProps = {
    className: 'Logo',
    ...props,
    alt: undefined,
  };
  return <img src={logo} alt={props.alt || 'Studio P.S.V.'} {...logoProps} />;
};
export default Logo;
