import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

interface AdminFrontPageProps extends RouteComponentProps {}
const AdminFrontPage: React.FC<AdminFrontPageProps> = (props) => {
  return (
    <div>
      <h1>Hallinta</h1>
      <div>Valitse toiminto vasemmalta.</div>
    </div>
  );
};
export default AdminFrontPage;
