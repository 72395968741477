import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const dsn = process.env.REACT_APP_SENTRY_DSN;

if (dsn) {
  Sentry.init({
    dsn,
    release: 'omapsv@0.0.1',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}
