import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { useReactiveVar } from '@apollo/client';
import studioPsvLogo from '../img/logo/studiopsv-logo.svg';
import { customerServiceModalOpenVar } from '../uiState';

const CustomerServiceModal = () => {
  const open = useReactiveVar(customerServiceModalOpenVar);
  const handleClose = () => customerServiceModalOpenVar(false);

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Asiakaspalvelu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-2">
          <Image
            src={studioPsvLogo}
            alt="Studio P.S.V."
            style={{ maxHeight: 30 }}
          />
        </div>
        <p>
          Oululainen Valokuvaamo Studio P.S.V. tekee muoto- ja mainoskuvauksia
          sekä lehtikuvauksia ja -juttuja. Kuviamme löytyy tuhansista kodeista
          ja yrityksistä.
        </p>
        <p>
          <strong>Studio P.S.V. Oy</strong>
          <br />
          Kajaaninkatu 11
          <br />
          90100 Oulu
          <br />
          (08) 379 289
          <br />
          palvelu@studiopsv.fi
        </p>

        <p>
          <strong>Aukioloajat</strong>
          <br />
          ti–to klo 10–17
          <br />
          pe klo 10–18
          <br />
          la klo 10–13
          <br />
        </p>

        <p>Kuvaamme myös aukioloaikojen ulkopuolella.</p>

        {/*
        <div>
          <a href="tel:08379289">
            <Button block variant="primary">
              Soita
            </Button>
          </a>
        </div>
        */}
      </Modal.Body>
    </Modal>
  );
};
export default CustomerServiceModal;
