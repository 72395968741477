import * as React from 'react';

interface PercentageProps {
  children: string;
  negative?: boolean;
}
const Percentage: React.FC<PercentageProps> = (props) => {
  const sign = props.negative ? '-' : '';
  let value = props.children;
  if (value.endsWith('.00')) {
    value = value.replace('.00', '');
  } else {
    value = value.replace('.', ',');
  }
  return (
    <React.Fragment>
      {sign}
      {value}%
    </React.Fragment>
  );
};
export default Percentage;
