import * as React from 'react';
import { useState } from 'react';
import { useMutation, useApolloClient, useQuery } from '@apollo/client';
import {
  EditOrder,
  EditOrderVariables,
} from '../types/__generated__/EditOrder';
import {
  GET_SHOPPING_CART,
  EDIT_SHOPPING_CART_ITEM,
  EDIT_ORDER,
  GET_PRODUCT_CATEGORIES,
} from 'types/shoppingCart';
import Checkbox from 'components/elements/Checkbox';
import {
  ShoppingCart,
  ShoppingCart_items,
  ShoppingCart_items_product_properties,
} from 'types/__generated__/ShoppingCart';
import {
  EditShoppingCartItem,
  EditShoppingCartItemVariables,
} from 'types/__generated__/EditShoppingCartItem';
import {
  GetProductCategories,
  GetProductCategoriesVariables,
} from 'types/__generated__/GetProductCategories';
import { PhotoColor } from '../types/__generated__/globalTypes';
import Table from 'react-bootstrap/Table';
import Trash from '../img/icons/trash.svg';
import { photoColorToText } from 'utils/photos';
import Price from './Price';
import Percentage from './Percentage';
import { getQuantityPlaceholder } from 'utils/products';
import { PhotoColorSelector } from 'components/PhotoOrderForm';
import ManagedFormControl from 'components/elements/ManagedFormControl';
import PropertySelectors from 'components/elements/PhotoPropertySelectors';

import './OrderSummary.scss';

/**
 * Convert a properties list to the form-friendly properties dictionary
 */
const getSelectedProperties = (
  properties: ShoppingCart_items_product_properties[],
): Record<string, string> => {
  const results: Record<string, string> = {};
  properties.forEach((property) => {
    results[property.key] = property.value;
  });
  return results;
};

interface OrderItemProps {
  item: ShoppingCart_items;
  editable?: boolean;
  productsWithPendingChanges?: Record<number, boolean>;
  toggleProductPendingChanges?: (id: number, value: boolean) => void;
}
const OrderItem = ({
  editable,
  item,
  productsWithPendingChanges = {},
  toggleProductPendingChanges = (id: number, value: boolean) => {},
}: OrderItemProps) => {
  const [editShoppingCartItem] = useMutation<
    EditShoppingCartItem,
    EditShoppingCartItemVariables
  >(EDIT_SHOPPING_CART_ITEM);
  const { data, loading, error } = useQuery<
    GetProductCategories,
    GetProductCategoriesVariables
  >(GET_PRODUCT_CATEGORIES, {
    variables: {
      priceList: item.product.category.priceList.code,
    },
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(
    item.product.category.id,
  );
  const [selectedProperties, setSelectedProperties] = useState<
    Record<string, string>
  >(getSelectedProperties(item.product.properties));
  const isMultiplePhotoProduct = !!(item.product && item.product.maxPhotos > 1);

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <tr key={item.id}>
      {editable && !loading && (
        <td>
          <button
            className="unstyled-button"
            aria-label="Poista"
            onClick={() => {
              editShoppingCartItem({
                variables: {
                  item: item.id,
                  quantity: 0,
                },
              });
            }}
          >
            <img src={Trash} alt="Poista" />
          </button>
        </td>
      )}
      <td>
        {item.orderItemPhotos.map((orderItemPhoto, index) => {
          const { photo, photoColor } = orderItemPhoto;
          return (
            <div key={index} className="d-flex flex-row photo-container">
              <div className="mr-4">
                <div
                  className={`thumbnail photocolor-${photoColor}`}
                  style={{
                    backgroundImage: `url(${photo.previewUrl})`,
                  }}
                />
              </div>
              <div>
                <strong>{photo.code}</strong>
                <br />
                <span className="text-muted">
                  {photoColorToText(photoColor)}
                </span>
              </div>
            </div>
          );
        })}
      </td>
      <td>
        <div className="summary-props">
          {/*
              It is theoretically possible to allow changing the product category
              (available properties will automatically refresh after the category is changed)
              but that would involve introducing another step for products like Kuvakirja
              to choose the associated photos, which requires more development.
              It has been decided to avoid it for now and keep the category selector
              as "disabled".
          */}
          <ManagedFormControl
            disabled
            as="select"
            required
            className="mt-2"
            defaultValue={selectedCategoryId}
            onChange={(category) => {
              toggleProductPendingChanges(item.product.id, true);
              setSelectedProperties({});
              setSelectedCategoryId(Number(category));
            }}
          >
            {data?.productCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </ManagedFormControl>
          <PropertySelectors
            editable={editable}
            selectedCategory={data?.productCategories?.find(
              (cat) => cat.id === selectedCategoryId,
            )}
            selectedProperties={selectedProperties}
            setSelectedProperties={(props: Record<string, string>) => {
              toggleProductPendingChanges(item.product.id, true);
              setSelectedProperties(props);
            }}
            onAllPropertiesSelected={async (matchedProduct) => {
              // Ignore already pre-loaded properties from the unmodified order
              if (!productsWithPendingChanges[item.product.id]) {
                return;
              }
              await editShoppingCartItem({
                variables: {
                  item: item.id,
                  product: matchedProduct.id,
                },
              });
              toggleProductPendingChanges(item.product.id, false);
            }}
          />
          {!isMultiplePhotoProduct && (
            <PhotoColorSelector
              editable={editable}
              colorChoice={
                item.product?.category?.priceList?.colorChoice || false
              }
              photoColor={item.photoColor}
              setPhotoColor={async (color: PhotoColor) => {
                await editShoppingCartItem({
                  variables: {
                    item: item.id,
                    product: item.product.id,
                    photoColor: color,
                  },
                });
              }}
              className="my-2"
            />
          )}
        </div>
      </td>
      <td>
        <div className="summary-quantity">
          <ManagedFormControl
            disabled={editable === false || item.product.maxQuantity === 1}
            placeholder={getQuantityPlaceholder(item.product)}
            defaultValue={item.quantity || 0}
            minQuantity={item.product.minQuantity}
            maxQuantity={item.product.maxQuantity || undefined}
            onChange={(value: string | number) => {
              const quantity: number = parseInt(String(value), 10);
              editShoppingCartItem({
                variables: {
                  item: item.id,
                  quantity,
                },
              });
            }}
          />
          kpl
        </div>
      </td>
      <td className="price">
        <Price>{item.price}</Price>
      </td>
    </tr>
  );
};

interface OrderSummaryProps {
  order: ShoppingCart; // TODO: should maybe be Order
  editable?: boolean;
  productsWithPendingChanges?: Record<number, boolean>;
  toggleProductPendingChanges?: (id: number, value: boolean) => void;
}
const OrderSummary = (props: OrderSummaryProps) => {
  const {
    order,
    editable,
    productsWithPendingChanges,
    toggleProductPendingChanges,
  } = props;
  const [editOrder] = useMutation<EditOrder, EditOrderVariables>(EDIT_ORDER);
  const client = useApolloClient();
  const sortedItems = [...order.items].sort((a, b) => {
    return a.id > b.id ? 1 : -1;
  });

  return (
    <div className="OrderSummary">
      <Table className="order-items" responsive>
        <thead>
          <tr>
            {editable && <th />}
            <th>Kuva</th>
            <th>Kuvatuote</th>
            <th>Määrä</th>
            <th className="price">Summa</th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item) => (
            <OrderItem
              key={`order_item_${item.id}`}
              item={item}
              editable={editable}
              productsWithPendingChanges={productsWithPendingChanges}
              toggleProductPendingChanges={toggleProductPendingChanges}
            />
          ))}
          {editable && (
            <tr>
              <td colSpan={5}>
                <Checkbox
                  onChange={async (val) => {
                    await editOrder({
                      variables: {
                        order: order.id,
                        orderedGallery: !order.orderedGallery,
                      } as any,
                    });
                    await client.query({
                      query: GET_SHOPPING_CART,
                      fetchPolicy: 'network-only',
                    });
                  }}
                  checked={order.orderedGallery}
                >
                  Tilaa PSV-Galleria (netissä toimivat matalaresoluutiokuvat)
                  tilatuista kuvista {/* TODO: don't hardcode price here */}
                  (45&nbsp;&euro;)
                </Checkbox>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {order.discounts.map((discount, i) => (
        <div className="price-details" key={i}>
          {discount.name}{' '}
          <Percentage negative>{discount.percentage}</Percentage>
        </div>
      ))}
      <div className="price-details">
        Kuvien käsittelymaksu <Price>{order.totalRetouchingFee}</Price>
      </div>
      {order.orderedGalleryFee !== '0.00' && (
        <div className="price-details">
          PSV-Galleria <Price>{order.orderedGalleryFee}</Price>
        </div>
      )}
      <div className="price-details">
        <div className="total-price">
          Yhteensä <Price>{order.totalPrice}</Price>
        </div>
        <div className="vat">
          sis. alv 24&nbsp;% (<Price>{order.totalVat}</Price>)
        </div>
      </div>
    </div>
  );
};
export default OrderSummary;
