import * as React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  children,
  disabled,
  ...otherProps
}: LoadingButtonProps) => {
  return (
    <Button {...otherProps} disabled={disabled || loading}>
      {!!loading && <Spinner animation="border" size="sm" />} {children}
    </Button>
  );
};
export default LoadingButton;
