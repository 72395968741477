import * as React from 'react';
import { useEffect, useState } from 'react';
import UploadIcon from 'bootstrap-icons/icons/arrow-down-square.svg';
import PhotoGrid from '../../components/PhotoGrid';
import { apiRequest, getAuthHeader } from '../api';
import UploadPreviewThumbnail from './UploadPreviewThumbnail';

import './PhotoUploader.scss';

export interface PhotoUploadBatch {
  hasPhotos: boolean;
  upload: (photoshootId: number) => Promise<void>;
}
export const emptyUploadBatch: PhotoUploadBatch = {
  hasPhotos: false,
  upload: async (photoshootId: number) => {},
};
interface UploadDetails {
  url: string;
  fields: Record<string, string>;
}

type UploadStatus = 'pending' | 'uploading' | 'uploaded' | 'error';
class UploadablePhoto {
  // helper class that represents a photo that can be uploaded and manages state as react component state
  constructor(
    public file: File,
    private uploaddedMap: Record<string, UploadStatus>,
    private setUploadedMap: (
      map: React.SetStateAction<Record<string, UploadStatus>>,
    ) => void,
  ) {}

  get status(): UploadStatus {
    return this.uploaddedMap[this.file.name] || 'pending';
  }

  private setStatus(status: UploadStatus) {
    // use the functional syntax here to make sure we don't override the map
    this.setUploadedMap((oldMap) => ({
      ...oldMap,
      [this.file.name]: status,
    }));
  }

  get name() {
    return this.file.name;
  }

  async upload({ url, fields }: UploadDetails): Promise<void> {
    if (this.status !== 'pending') {
      return;
    }

    const body = new FormData();
    for (const [field, value] of Object.entries(fields)) {
      body.append(field, value);
    }
    body.append('file', this.file);

    // another hack to include auth for local urls
    const headers = new Headers();
    if (url.startsWith('/')) {
      headers.set('Authorization', getAuthHeader());
    }

    this.setStatus('uploading');
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    });

    if (!response.ok) {
      this.setStatus('error');
      throw Error('Upload failed!');
    }

    this.setStatus('uploaded');
  }
}

interface PhotoUploaderProps {
  onSelect: (photoUploadBatch: PhotoUploadBatch) => void;
}
const PhotoUploader = (props: PhotoUploaderProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedMap, setUploadedMap] = useState<Record<string, UploadStatus>>(
    {},
  );
  const { onSelect } = props;

  const selectedPhotos = React.useMemo(
    () =>
      selectedFiles.map(
        (file) => new UploadablePhoto(file, uploadedMap, setUploadedMap),
      ),
    [selectedFiles, uploadedMap, setUploadedMap],
  );

  useEffect(() => {
    const batch: PhotoUploadBatch = {
      hasPhotos: !!selectedPhotos.length,
      upload: async (photoshootId) => {
        return await uploadPhotos({
          photoshootId,
          photos: selectedPhotos,
        });
      },
    };
    onSelect(batch);
  }, [selectedPhotos, onSelect]);

  const handleChange = (e: any) => {
    const files = filelistToArray(e.target.files);
    setSelectedFiles(files);
  };

  return (
    <div className="PhotoUploader">
      <div className="files">
        <div className="header">
          <div className="icon">
            <img src={UploadIcon} alt={''} />
          </div>
          <label htmlFor="photoUploadInput">
            Raahaa kuvat tähän
            <small>
              <br />
              Tai klikkaa valitaksesi kuvat
            </small>
          </label>
        </div>
        <input
          type="file"
          id="photoUploadInput"
          multiple
          onChange={handleChange}
          accept="image/jpg, image/jpeg, image/png"
        />
      </div>
      {selectedPhotos.length > 0 && (
        <div className="preview">
          <h3>Valitut kuvat ({selectedPhotos.length} kpl)</h3>
          <PhotoGrid itemSize="small">
            {selectedPhotos.map((photo) => (
              <UploadPreviewThumbnail
                key={photo.name}
                file={photo.file}
                status={photo.status}
                caption={photo.name}
              />
            ))}
          </PhotoGrid>
        </div>
      )}
    </div>
  );
};

const filelistToArray = (files: FileList): File[] => {
  const ret: File[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    if (file) {
      ret.push(file);
    }
  }
  return ret;
};

interface UploadPhotosOpts {
  photoshootId: number;
  photos: UploadablePhoto[];
  maxSimultaneous?: number;
}
async function uploadPhotos(opts: UploadPhotosOpts) {
  const { photoshootId, photos, maxSimultaneous = 4 } = opts;

  const uploadDetails = await apiRequest<UploadDetails>(
    `/photoshoots/${photoshootId}/upload/`,
  );

  const remaining = [...photos];
  while (remaining.length > 0) {
    const batch = remaining.splice(0, maxSimultaneous);
    try {
      await Promise.all(
        batch.map((photo) => {
          return photo.upload(uploadDetails);
        }),
      );
    } catch (e) {
      console.error(e);
      alert('Error uploading photos: ' + e);
      throw e;
    }
  }

  // TODO: ugly hack
  // wait a little while for all photos to upload
  await new Promise((resolve) => {
    setTimeout(resolve, 5000);
  });
}

export default PhotoUploader;
