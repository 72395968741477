import { gql } from '@apollo/client';

export const PHOTOSHOOT_FREE_PRODUCT_FRAGMENT = gql`
  fragment PhotoshootFreeProduct on PhotoshootFreeProduct {
    __typename
    id
    photoshootId
    product {
      id
      category {
        id
        name
      }
      properties {
        keyName
        key
        name
        value
      }
    }
    quantity
    quantityUsed
  }
`;

export const ADD_PHOTOSHOOT_FREE_PRODUCT = gql`
  mutation AddPhotoshootFreeProduct(
    $photoshootId: Int!
    $productId: Int!
    $quantity: Int!
  ) {
    addPhotoshootFreeProduct(
      photoshootId: $photoshootId
      productId: $productId
      quantity: $quantity
    ) {
      ok
    }
  }
`;

export const REMOVE_PHOTOSHOOT_FREE_PRODUCT = gql`
  mutation RemovePhotoshootFreeProduct($recordId: Int!) {
    removePhotoshootFreeProduct(recordId: $recordId) {
      ok
    }
  }
`;
