import * as React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import AdminLayout from 'admin/components/AdminLayout';
import AdminUsersPage from 'admin/components/AdminUsersPage';
import AdminPhotoshootsPage from 'admin/components/AdminPhotoshootsPage';
import AdminFrontPage from 'admin/components/AdminFrontPage';
import AdminOrdersPage from 'admin/components/AdminOrdersPage';
import AdminProductsPage from 'admin/components/AdminProductsPage';

const SIDEBAR_LINKS = [
  ['asiakkaat', 'Asiakkaat'],
  ['kuvakansiot', 'Kuvakansiot'],
  ['tilaukset', 'Tilaukset'],
  ['kuvatuotteet', 'Kuvatuotteet'],
];

// TODO: validate user is admin
interface AdminRoutesProps extends RouteComponentProps {}
const AdminRoutes: React.FC<AdminRoutesProps> = () => {
  return (
    <AdminLayout links={SIDEBAR_LINKS}>
      <Router>
        <AdminFrontPage default />

        <AdminUsersPage path="asiakkaat" />
        <AdminUsersPage path="asiakkaat/uusi" showAddUserForm />
        <AdminUsersPage path="asiakkaat/:userId" />

        <AdminPhotoshootsPage path="kuvakansiot" />
        <AdminPhotoshootsPage path="kuvakansiot/uusi" editMode />
        <AdminPhotoshootsPage path="kuvakansiot/uusi/:userId" editMode />
        <AdminPhotoshootsPage path="kuvakansiot/:photoshootId" />
        <AdminPhotoshootsPage
          path="kuvakansiot/:photoshootId/lisaa-kuvia"
          editMode
        />

        <AdminOrdersPage path="tilaukset" />
        <AdminOrdersPage path="tilaukset/:orderId" />

        <AdminProductsPage path="kuvatuotteet" />
        <AdminProductsPage path="kuvatuotteet/:action" />
        <AdminProductsPage path="kuvatuotteet/:action/:categoryId" />
      </Router>
    </AdminLayout>
  );
};

export default AdminRoutes;
