import * as React from 'react';
import classNames from 'classnames';
import './PhotoGrid.scss';

interface PhotoGridProps {
  children?: React.ReactNodeArray | React.ReactElement;
  itemSize?: 'big' | 'small' | 'medium';
}
const PhotoGrid: React.FC<PhotoGridProps> = (props) => {
  const { children = [], itemSize = 'big' } = props;

  // account for the case where a single react element is passed in
  const items = Array.isArray(children) ? children : [children];

  return (
    <ul className={classNames('PhotoGrid', `item-size-${itemSize}`)}>
      {items.map((child, i) => {
        if (!child) {
          return null;
        }

        const key = (child as any).key || i;
        return <li key={key}>{child}</li>;
      })}
    </ul>
  );
};
export default PhotoGrid;
