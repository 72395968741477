import * as React from 'react';
import './BottomToolbar.scss';

interface BottomToolbarProps {
  children?: any;
  isVisible: boolean;
}
const BottomToolbar: React.FC<BottomToolbarProps> = ({
  children,
  isVisible,
}: BottomToolbarProps) => {
  return (
    <div className={`BottomToolbar ${isVisible ? 'visible' : ''}`}>
      <div className="children">{children}</div>
    </div>
  );
};
export default BottomToolbar;
