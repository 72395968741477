import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { gql, useMutation, useQuery } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import BsForm from 'react-bootstrap/Form';
import * as yup from 'yup';
import { Form as FormikForm, Formik } from 'formik';
import { Form } from 'react-bootstrap-formik';
import { CURRENT_USER_FRAGMENT, MODIFY_USER } from '../types/users';
import {
  GetMyDetailsData,
  GetMyDetailsData_me as User,
} from './__generated__/GetMyDetailsData';
import SecondaryHeader from './SecondaryHeader';
import LoadingIndicator from './LoadingIndicator';
import LoadingButton from './LoadingButton';
import {
  ModifyUser,
  ModifyUserVariables,
} from '../types/__generated__/ModifyUser';

const GET_MY_DETAILS_DATA = gql`
  query GetMyDetailsData {
    me {
      ...CurrentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

interface MyDetailsPageProps extends RouteComponentProps {}

const MyDetailsPage = (props: MyDetailsPageProps) => {
  const { data, loading, error } =
    useQuery<GetMyDetailsData>(GET_MY_DETAILS_DATA);
  if (loading) {
    return <LoadingIndicator fullScreen />;
  }
  if (error) {
    return <Container fluid>Error: {error.message}</Container>;
  }
  const user = data?.me;
  if (!user) {
    return <Container fluid>Kirjautuminen vaaditaan</Container>;
  }

  return (
    <div className="MyDetailsPage">
      <SecondaryHeader path={['Omat tiedot']} />
      <Container fluid>
        <Row>
          <Col md className="mb-4">
            <h2>Muokkaa omaa profiilia</h2>
            <ProfileForm user={user} />
          </Col>
          <Col md className="mb-4">
            <h2>Vaihda salasana</h2>
            <PasswordForm user={user} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// NOTE: there's some overlap with OrderInfoForm
interface ProfileFormProps {
  user: User;
}
const profileFormValidationSchema = yup
  .object({
    firstName: yup.string().required('Syötä etunimi'),
    lastName: yup.string().required('Syötä sukunimi'),
    companyName: yup.string(),
    streetAddress: yup.string().required('Syötä katuosoite'),
    postalCode: yup
      .string()
      .trim()
      .matches(/^[0-9]{5}$/, 'Virheellinen postinumero')
      .required('Syötä postinumero'),
    postalLocation: yup.string().required('Syötä paikkakunta'),
    phoneNumber: yup.string().required('Syötä puhelinnumero'),
  })
  .required();
const ProfileForm = ({ user }: ProfileFormProps) => {
  const [modifyUser] = useMutation<ModifyUser, ModifyUserVariables>(
    MODIFY_USER,
  );
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  return (
    <div>
      <Formik
        initialValues={{
          ...user.addressDetails,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={profileFormValidationSchema}
        onSubmit={async (values) => {
          setMessage('');
          setMessageType('');
          try {
            await modifyUser({
              variables: {
                user: user.id,
                ...values,
              } as any,
            });
          } catch (e) {
            setMessage('Virhe tietojen päivityksessä: ' + e.toString());
            setMessageType('danger');
            return;
          }
          setMessage('Tiedot päivitetty onnistuneesti');
          setMessageType('success');
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            {!!message && <Alert variant={messageType}>{message}</Alert>}
            <BsForm.Group>
              <BsForm.Label>Sähköposti</BsForm.Label>
              <BsForm.Control type="text" value={user.email} disabled />
            </BsForm.Group>
            <Row>
              <Col>
                <Form.Input name="firstName" label="Etunimi" />
              </Col>
              <Col>
                <Form.Input name="lastName" label="Sukunimi" />
              </Col>
            </Row>
            <Form.Input name="companyName" label="Yrityksen nimi" />
            <Form.Input name="streetAddress" label="Katuosoite" />
            <Row>
              <Col xs={5}>
                <Form.Input name="postalCode" label="Postinro" />
              </Col>
              <Col xs={7}>
                <Form.Input name="postalLocation" label="Paikkakunta" />
              </Col>
            </Row>
            <Form.Input name="phoneNumber" label="Puhelin" />
            <div className="mt-4">
              <LoadingButton
                block
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Päivitä omat tiedot
              </LoadingButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

interface PasswordFormProps {
  user: User;
}
const passwordFormValidationSchema = yup
  .object({
    oldPassword: yup.string().required('Syötä nykyinen salasana'),
    password: yup
      .string()
      .required('Syötä salasana')
      .min(12, 'Salasanan täytyy olla vähintään 12 merkkiä pitkä'),
    passwordConfirmation: yup
      .string()
      .required('Syötä salasana uudestaan')
      .oneOf([yup.ref('password')], 'Salasana ei täsmää'),
  })
  .required();
const PasswordForm = ({ user }: PasswordFormProps) => {
  const [modifyUser] = useMutation<ModifyUser, ModifyUserVariables>(
    MODIFY_USER,
  );
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  return (
    <div>
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={passwordFormValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          setMessage('');
          setMessageType('');
          try {
            await modifyUser({
              variables: {
                user: user.id,
                oldPassword: (values as any).oldPassword,
                password: (values as any).password,
              },
            });
          } catch (e) {
            setMessage('Virhe: ' + e.toString().replace('Error: ', ''));
            setMessageType('danger');
            return;
          }
          setMessage('Salasana päivitetty onnistuneesti');
          setMessageType('success');
          resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            {!!message && <Alert variant={messageType}>{message}</Alert>}
            <Form.Input
              name="oldPassword"
              type="password"
              label="Nykyinen salasana"
            />
            <Form.Input name="password" type="password" label="Uusi salasana" />
            <Form.Input
              name="passwordConfirmation"
              type="password"
              label="Toista uusi salasana"
            />
            <div className="mt-4">
              <LoadingButton
                block
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Vaihda salasana
              </LoadingButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default MyDetailsPage;
