import * as React from 'react';
import {
  Link,
  RouteComponentProps,
  useNavigate,
  useParams,
} from '@reach/router';
import { useApolloClient } from '@apollo/client';
import Nav from 'react-bootstrap/Nav';
import { useMutation } from '@apollo/client';
import { Form as FormikForm, Formik } from 'formik';
import { Form } from 'react-bootstrap-formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import * as yup from 'yup';

import LoadingButton from 'components/LoadingButton';
import { ADMIN_GET_USER, ADMIN_GET_USERS } from 'admin/queries';
import { AdminGetUsers } from 'admin/__generated__/AdminGetUsers';
import { AdminGetUser_user as User } from 'admin/__generated__/AdminGetUser';
import {
  AdminGetUser,
  AdminGetUserVariables,
} from 'admin/__generated__/AdminGetUser';
import { MODIFY_USER } from 'types/users';
import {
  ModifyUser,
  ModifyUserVariables,
} from 'types/__generated__/ModifyUser';
import { apiRequest } from 'admin/api';

import QueryLoader from './QueryLoader';
import Table from './Table';

interface AdminUsersPageProps extends RouteComponentProps {
  showAddUserForm?: boolean;
}
const AdminUsersPage: React.FC<AdminUsersPageProps> = (props) => {
  const { showAddUserForm } = props;
  const params = useParams();
  const userId = params?.userId;

  return (
    <div>
      <h1>Asiakkaat</h1>
      <Nav
        defaultActiveKey={showAddUserForm ? 'add-user' : ''}
        variant="pills"
        className="my-3"
      >
        <Nav.Item>
          <Nav.Link as={Link} to="/hallinta/asiakkaat" eventKey="">
            Asiakaslista
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/hallinta/asiakkaat/uusi" eventKey="add-user">
            Lisää asiakas
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {showAddUserForm ? (
        <AddUserForm />
      ) : userId ? (
        <UserDetails userId={parseInt(userId)} />
      ) : (
        <UserList />
      )}
    </div>
  );
};

interface UserDetailsProps {
  userId: number;
}
const UserDetails = (props: UserDetailsProps) => {
  const { userId } = props;
  const [editingUser, setEditingUser] = React.useState(false);

  return (
    <div>
      <QueryLoader<AdminGetUser, AdminGetUserVariables>
        query={ADMIN_GET_USER}
        options={{
          variables: {
            id: userId,
          },
        }}
      >
        {({ user }) => {
          if (!user) {
            return <div>Käyttäjää ei löydy.</div>;
          }

          if (editingUser) {
            return <EditUserForm user={user} setEditingUser={setEditingUser} />;
          }

          return (
            <div>
              <h2>Asiakas: {user.displayName}</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Etunimi</td>
                    <td>{user.firstName}</td>
                  </tr>
                  <tr>
                    <td>Sukunimi</td>
                    <td>{user.lastName}</td>
                  </tr>
                  <tr>
                    <td>Sähköposti</td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td>Puhelinnumero</td>
                    <td>{user.addressDetails.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td>Osoite</td>
                    <td>
                      {user.addressDetails.streetAddress}
                      <br />
                      {user.addressDetails.postalCode}{' '}
                      {user.addressDetails.postalLocation}
                    </td>
                  </tr>
                  <tr>
                    <td>Ylläpitäjä</td>
                    <td>{user.isAdmin ? 'Kyllä' : 'Ei'}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                variant="primary"
                onClick={() => setEditingUser(!editingUser)}
              >
                Muokkaa asiakasta
              </Button>
              <h2>Asiakkaan kuvakansiot</h2>
              {user.photoshoots.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Kuvakansion nimi</th>
                      <th>Kuvia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.photoshoots.map((photoshoot) => (
                      <tr key={photoshoot.id}>
                        <td>
                          <Link to={`/hallinta/kuvakansiot/${photoshoot.id}`}>
                            {photoshoot.name}
                          </Link>
                        </td>
                        <td>{photoshoot.photos.length}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>Asiakkaalla ei ole kuvakansioita.</div>
              )}
              <div className="my-3">
                <Link to={`/hallinta/kuvakansiot/uusi/${userId}`}>
                  <Button variant="primary">Lisää kuvakansio</Button>
                </Link>
              </div>
            </div>
          );
        }}
      </QueryLoader>

      <div className="my-3">
        <Link to={'/hallinta/asiakkaat'}>&laquo; Takaisin asiakaslistaan</Link>
      </div>
    </div>
  );
};

const UserList = () => {
  return (
    <QueryLoader<AdminGetUsers> query={ADMIN_GET_USERS}>
      {({ users }) => (
        <div>
          <Table
            keyField="id"
            data={users}
            columns={[
              {
                field: 'id',
                title: 'ID',
                link: (row) => `/hallinta/asiakkaat/${row.id}`,
              },
              {
                field: 'displayName',
                title: 'Nimi',
                link: (row) => `/hallinta/asiakkaat/${row.id}`,
              },
              {
                field: 'email',
                title: 'Sähköposti',
              },
              {
                field: 'isAdmin',
                title: 'Ylläpitäjä?',
              },
            ]}
            search
            pagination
          />
        </div>
      )}
    </QueryLoader>
  );
};

const editUserFormValidationSchema = yup
  .object({
    firstName: yup.string().required('Syötä etunimi'),
    lastName: yup.string().required('Syötä sukunimi'),
    password: yup.string().min(12, 'Salasana on liian lyhyt'),
    companyName: yup.string(),
    streetAddress: yup.string(),
    postalCode: yup
      .string()
      .trim()
      .matches(/^[0-9]{5}$/, 'Virheellinen postinumero'),
    postalLocation: yup.string(),
    phoneNumber: yup.string(),
    email: yup
      .string()
      .email('Virheellinen sähköposti')
      .required('Syötä sähköposti'),
  })
  .required();

interface EditUserProps {
  user: User;
  setEditingUser: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditUserForm: React.FC<EditUserProps> = (props) => {
  const apolloClient = useApolloClient();
  const { user, setEditingUser } = props;
  const { email, isAdmin } = user;
  const [modifyUser] = useMutation<ModifyUser, ModifyUserVariables>(
    MODIFY_USER,
  );

  return (
    <div>
      <Formik
        initialValues={{
          email,
          password: '',
          isAdmin,
          ...user.addressDetails,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={editUserFormValidationSchema}
        onSubmit={async (
          values,
          { setErrors, setStatus, resetForm, setSubmitting },
        ) => {
          setSubmitting(true);
          try {
            await modifyUser({
              variables: {
                user: user.id,
                ...values,
              } as any,
            });
          } catch (e) {
            console.error('Failed to edit user: ' + e.toString());
            return;
          }

          // refetch query and store to cache
          await apolloClient.query({
            query: ADMIN_GET_USERS,
            fetchPolicy: 'network-only',
          });
          await apolloClient.query({
            query: ADMIN_GET_USER,
            variables: {
              id: user.id,
            },
            fetchPolicy: 'network-only',
          });

          setSubmitting(false);
          resetForm();

          setEditingUser(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <FormikForm>
            <Form.Input name="email" label="Sähköposti *" />
            <Form.Input name="password" label="Salasana" type="password" />
            <Row>
              <Col>
                <Form.Input name="firstName" label="Etunimi *" />
              </Col>
              <Col>
                <Form.Input name="lastName" label="Sukunimi *" />
              </Col>
            </Row>
            <Form.Input name="streetAddress" label="Katuosoite" />
            <Row>
              <Col xs={5}>
                <Form.Input name="postalCode" label="Postinumero" />
              </Col>
              <Col xs={7}>
                <Form.Input name="postalLocation" label="Postitoimipaikka" />
              </Col>
            </Row>
            <Form.Input name="phoneNumber" label="Puhelinnumero" />
            <Form.Input
              name="isAdmin"
              label="Ylläpitäjä?"
              type="checkbox"
              checked={values.isAdmin}
              custom
            />
            <LoadingButton
              variant="primary"
              type="submit"
              loading={isSubmitting}
            >
              Tallenna muutokset
            </LoadingButton>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

const addUserFormValidationSchema = yup
  .object({
    firstName: yup.string().required('Syötä etunimi'),
    lastName: yup.string().required('Syötä sukunimi'),
    generatePassword: yup.boolean(),
    password: yup
      .string()
      .ensure()
      .when('generatePassword', {
        is: false,
        then: yup
          .string()
          .required('Syötä salasana')
          .min(8, 'Salasana on liian lyhyt'),
      }),
    companyName: yup.string(),
    streetAddress: yup.string(),
    postalCode: yup
      .string()
      .trim()
      .matches(/^[0-9]{5}$/, 'Virheellinen postinumero'),
    postalLocation: yup.string(),
    phoneNumber: yup.string(),
    email: yup
      .string()
      .email('Virheellinen sähköposti')
      .required('Syötä sähköposti'),
  })
  .required();
const AddUserForm = () => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          generatePassword: true,
          password: '',
          streetAddress: '',
          postalCode: '',
          postalLocation: '',
          phoneNumber: '',
          isAdmin: false,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={addUserFormValidationSchema}
        onSubmit={async (
          values,
          { setErrors, setStatus, resetForm, setSubmitting },
        ) => {
          setSubmitting(true);
          let response;
          try {
            response = await apiRequest('/users/', {
              method: 'POST',
              data: values,
            });
          } catch (e) {
            console.error('Error submitting form', e);
            setSubmitting(false);
            setStatus({ success: false });
            alert(e);
            return;
          }

          // refetch query and store to cache
          await apolloClient.query({
            query: ADMIN_GET_USERS,
            fetchPolicy: 'network-only',
          });
          setSubmitting(false);
          resetForm();

          await navigate(`/hallinta/asiakkaat/${response.userId}`);
        }}
      >
        {({ values, isSubmitting, handleChange }) => (
          <FormikForm>
            <Form.Input name="email" label="Sähköposti *" />
            <Form.Input
              name="generatePassword"
              label="Luo salasana automaattisesti&nbsp;"
              type="checkbox"
              checked={values.generatePassword}
              custom
            />
            <Form.Input
              name="password"
              label="Salasana *"
              type="password"
              disabled={values.generatePassword}
            />
            <Row>
              <Col>
                <Form.Input name="firstName" label="Etunimi *" />
              </Col>
              <Col>
                <Form.Input name="lastName" label="Sukunimi *" />
              </Col>
            </Row>
            <Form.Input name="streetAddress" label="Katuosoite" />
            <Row>
              <Col xs={5}>
                <Form.Input name="postalCode" label="Postinumero" />
              </Col>
              <Col xs={7}>
                <Form.Input name="postalLocation" label="Postitoimipaikka" />
              </Col>
            </Row>
            <Form.Input name="phoneNumber" label="Puhelinnumero" />
            <Form.Input
              name="isAdmin"
              label="Ylläpitäjä?"
              type="checkbox"
              checked={values.isAdmin}
              custom
            />
            <LoadingButton
              variant="primary"
              type="submit"
              loading={isSubmitting}
            >
              Luo asiakas
            </LoadingButton>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
export default AdminUsersPage;
