import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import Spinner from 'react-bootstrap/Spinner';
import { productInfoModalOpenVar } from '../uiState';
import { ProductInfoRecord, ProductImage } from 'types/productInfo';
import SecondaryHeader from './SecondaryHeader';
import './ProductsPage.scss';

const refreshProductData = async (
  setProductData: (value: ProductInfoRecord[]) => void,
  setError: (value: string) => void,
) => {
  try {
    const cacheBuster = Math.floor(Date.now() / (60 * 60 * 1000)); // Refresh cache every hour
    // Use cached response when available to avoid repeat external calls
    const rawResponse = await fetch(
      (process.env.REACT_APP_BACKEND_URI || '') + '/api/v1/products/json/?cache=' + cacheBuster,
      {
        cache: 'force-cache',
      },
    );
    const response = await rawResponse.json();
    setProductData(response.data);
  } catch (e) {
    console.error(e);
    setError(e.message);
  }
};

const getProductImage = (product: ProductInfoRecord): ProductImage|null =>
  product.acf.tuotekuvat ? product.acf.tuotekuvat[0] : null; // TODO: determine the selection logic for multiple image products

const getProductImageSrcSet = (product: ProductInfoRecord): string => {
  let srcSet: string = '';
  const image = getProductImage(product);
  if (!image) {
    return '';
  }
  const sizes: Record<string, Record<string, number>> = {};
  Object.keys(image.sizes)
    .filter((key) => key.includes('-width') || key.includes('-height'))
    .forEach((key) => {
      const size = key.split('-');
      const [sizeName, sizeAttribute] = size;
      if (!sizes.hasOwnProperty(sizeName)) {
        sizes[sizeName] = {};
      }
      sizes[sizeName][sizeAttribute] = Number(image.sizes[key]);
    });
  Object.keys(sizes)
    .filter((sizeName) => !!image.sizes[sizeName])
    .forEach((sizeName) => {
      const url: string = String(image.sizes[sizeName]);
      const sizeData = sizes[sizeName];
      srcSet += `${url} ${sizeData.width}w,`;
    });
  return srcSet;
};

interface ProductsPageProps extends RouteComponentProps {}
export const ProductsPage: React.FC<ProductsPageProps> = () => {
  const [productData, setProductData] = React.useState<
    ProductInfoRecord[] | null
  >(null);
  const [error, setError] = React.useState<string>('');

  React.useEffect(() => {
    refreshProductData(setProductData, setError).catch((e) => {
      console.error('Error refreshing product data');
      console.error(e);
    })
  }, [setProductData, setError]);

  return (
    <div className="ProductsPage">
      <SecondaryHeader path="Kuvatuotteet" withBottomMargin={false} />
      <div className="banner-wrap">
        <div id="banner">
          <div className="spacer"></div>
        </div>
      </div>
      <div id="content">
        <div id="inner-content" className="container-fluid no-padding">
          <div className="row no-gutters row-main no sidebars">
            <div id="main" className="main clearfix" role="main">
              <section
                className="flexible-content columns-2"
                data-aos="fade-up"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        Tervetuloa tutustumaan Studio P.S.V:n monipuoliseen
                        kuvatuotevalikoimaan! Laajasta valikoimastamme löydät
                        mm. eri kokoiset pöytä- ja seinäkuvat, erilaiset
                        kansiot, useita kiitoskorttimalleja, upeat canvas- ja
                        metallitaulut ja yksilölliset kuvakirjat sekä käsitellyt
                        digitiedostot.
                      </p>
                    </div>

                    <div className="col-md-6">
                      <p>
                        Kuvankäsittelijämme aukaisee jokaisen tilaamasi kuvan ja
                        viimeistelee sen&nbsp;kuvankäsittelyssä.
                        Kuvanvalmistuksessa käytämme maan parhaita osaajia.
                        Huolehdimme siitä, että tilaamiesi kuvien laatu vastaa
                        loppuun saakka laatuvaatimuksiamme.
                      </p>
                      <p>
                        Kaikki hinnat sisältävät arvonlisäveron 24%. Oikeus
                        hinnanmuutoksiin pidätetään.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="flexible-content jumbo" data-aos="fade-up">
                <div className="container">
                  <div
                    className="jumbotron"
                    style={{
                      background: '#494949 url() center / cover no-repeat',
                      color: '#ffffff',
                    }}
                  >
                    <div className="jumbotron-inner">
                      <h2>
                        Tilaa kuvatuotteet nopeasti – saat jopa 10% alennuksen!
                      </h2>

                      <p>
                        Saat nopean tilaajan alennuksen alla olevista
                        kuvatuotehinnoista, kun teet tilauksesi 14 vuorokauden
                        kuluessa koevedosten julkaisemisesta. Edun saat
                        fyysisistä kuvatuotteista, korkearesoluutiotiedostoista
                        ja käsittelymaksusta.
                      </p>
                      <p>
                        - etusi on 5 %, kun tilauksen arvo on vähintään 150 e
                        <br />- etusi on 10 %, kun tilauksen arvo on vähintään
                        600 e
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="flexible-content kuvatuotteet"
                data-aos="fade-up"
              >
                <div className="container">
                  <div className="row">
                    {!!error && <div className="error">{error}</div>}
                    {!productData && (
                      <div className="full-width flex-center">
                        <Spinner animation="border" />
                      </div>
                    )}
                    {!!productData &&
                      productData.map((product) => {
                        return (
                          <div
                            className="col-md-6 col-lg-3"
                            key={`product_${product.id}`}
                          >
                            <div className="kuvatuote">
                              <div className="product-thumbnail">
                                <img
                                  width="533"
                                  height="800"
                                  src={getProductImage(product)?.url}
                                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                  alt=""
                                  loading="lazy"
                                  srcSet={getProductImageSrcSet(product)}
                                  sizes="(max-width: 533px) 100vw, 533px"
                                />{' '}
                              </div>

                              <div className="product-details text-center">
                                <p className="h3 text-center">
                                  {product.title.rendered}
                                </p>

                                <a
                                  href="#0"
                                  className="product-readmore sc-btn sc-btn--primary sc-btn--full"
                                  data-id={product.id}
                                  onClick={() =>
                                    productInfoModalOpenVar(product)
                                  }
                                >
                                  Hinnasto ja lisätiedot
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </section>

              <section
                className="flexible-content columns-1"
                data-aos="fade-up"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2>Kuvankäsittely</h2>
                      <p>
                        Koevedokset ovat raakatiedostoja. Taitava ja huolellinen
                        kuvankäsittelijämme aukaisee jokaisen tilaamasi kuvan
                        koneellaan ja viimeistelee valokuvaajan työn
                        kuvankäsittelyllä – aivan kuten retusoijat aikoinaan
                        tekivät pimiössä. Meiltä ei lähde yhtään valokuvaa
                        tuotantoon ilman huolellista kuvankäsittelyä.
                      </p>
                      <p>
                        Tästä käsittelytyöstä veloitamme retusointi- eli{' '}
                        <strong>käsittelymaksun</strong>
                      </p>
                      <p>
                        <strong>7,90 e / otos</strong>
                      </p>
                      <p>
                        Käsittelymaksu koskee kaikkia kuvatuotteita: pöytä- ja
                        seinäkuvia, kansioita, kiitoskortteja, canvas- ja
                        metallitauluja, kuvakirjoja, kuvaboksia,
                        seinäkalentereita, palapelejä, kuvamukeja ja
                        joulupalloja sekä korkearesoluutiotiedostoja.
                      </p>
                      <p>
                        Käsittelymaksua ei peritä niistä kuvatilauksista, joissa
                        kuvauksen hintaan sisältyy käsitellyt digitiedostot
                        (esim. vihkimisen kuvat, hautajaiskuvat, häiden ja
                        perhejuhlan tilannekuvat).
                      </p>
                      <p>
                        Kun tilaat samasta tiedostosta (otoksesta) eri kokoisia
                        kuvia (esim. 50 kpl kiitoskortteja, 5 kpl 14×18 cm kuvia
                        ja taulukuvan), käsittelymaksu peritään vain yhden
                        kerran.
                      </p>
                      <p>
                        Kun tilaat samasta tiedostosta väri- ja mustavalkokuvia,
                        käsittelymaksu peritään vain yhden kerran.
                      </p>
                      <p>
                        <strong>Käsittelymaksu sisältää:</strong>
                      </p>
                      <ul>
                        <li>värisävyjen säätö</li>
                        <li>kuvan lopullinen rajaus</li>
                        <li>näkyvien, yksittäisten finnien poisto</li>
                        <li>ihon kiiltojen vähentäminen</li>
                        <li>
                          kuvasta riippuen softausta, vinjetointia yms.
                          korostuksia
                        </li>
                        <li>
                          roskien yms. kuvaan kuulumattomien elementtien
                          poistaminen
                        </li>
                        <li>
                          pienten luomien ja arpien poistaminen eri pyynnöstä
                        </li>
                        <li>
                          logon ja valokuvaajan signeerauksen lisääminen
                          <br />
                          <strong>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          </strong>
                        </li>
                      </ul>
                      <p>
                        Huonosti istuvia vaatteita tai asusteita tai
                        purkautunutta kampausta korjaamme lähtökohtaisesti eri
                        maksua vastaan. Siksi on hyvä huolehtia jo ennen
                        kuvausta, että vaatteet istuvat hyvin, asusteet ovat
                        oikein päällä ja että kampaus on kunnossa.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="flexible-content columns-1"
                data-aos="fade-up"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h4>Valokuvien erikoiskäsittelyt</h4>
                      <p>
                        Muut kuin peruskäsittelyyn sisältyvät kuvankäsittelyt,
                        tunti á 80,00 e. Veloitus 15 minuutin sykkeissä.
                        Erikoiskäsittelyssä esim. vaihdetaan tausta.
                      </p>
                      <p>
                        Värillinen elementti mustavalko- tai ruskeasävykuvassa á
                        15,00 e.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="flexible-content columns-1"
                data-aos="fade-up"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2>Kuvatuotteiden valmistuminen</h2>
                      <p>
                        Kuvatuotteet valmistuvat noin neljän viikon kuluessa
                        kuvatilauksen jättämisestä. Ilmoitamme kuvien
                        valmistumisesta erikseen. Kuvat maksetaan ennakkoon
                        laskulla.
                      </p>
                      <p>
                        Toivomme, että noudat valmiit kuvat
                        <strong>&nbsp;kuukauden kuluessa</strong> siitä, kun
                        olemme lähettäneet viestin (email/txt-viesti) kuvien
                        valmistumisesta. Halutessasi toimitamme valmiit kuvat
                        sinulle postitse tai esim. autoon studion pihalla.
                        Tällöin veloitamme postitustyöstä 10 e + postikulut.
                      </p>
                      <h2>
                        <strong>Valokuvaajan tekijänoikeus</strong>
                      </h2>
                      <p>
                        Studio P.S.V:n kuvaamien kuvien kopiointioikeus on vain
                        Studio P.S.V:llä (paitsi ne kuvatiedostot, joista
                        asiakas ostaa korkearesoluutiotiedoston mukana kuvan
                        käyttöoikeuden).
                      </p>
                      <p>
                        Tekijänoikeuslain mukaan valokuvaajalla on myös oikeus
                        kuviensa julkaisuun ja esittämiseen esitellääkseen
                        työtään. Kuviamme julkaistaan Studio P.S.V.n tiloissa ja
                        kanavissa nimettöminä, ellei muusta sovita. Mikäli et
                        halua kuviasi julkaistavan, kerro siitä viimeistään
                        kuvatilauksen yhteydessä.
                      </p>
                      <p>
                        Tekijänoikeuslain mukaan valokuvaan merkitään myös
                        tiedot tekijästä. Liitämme kuviin
                        kuvankäsittelyvaiheessa valokuvaajan sähköisen
                        signeerauksen ja studion logon (ei koske
                        korkearesoluutiotiedostoja). Signeeraus kertoo myös
                        siitä, että valokuvaaja seisoo työnsä takana ja arvostaa
                        sitä. Studio P.S.V:n valokuvaajan signeeraus on laadun
                        tae.
                      </p>
                      <p>
                        <strong>
                          Hinnat sis. alv 24 %. Oikeus hinnamuutoksiin
                          pidätetään.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductsPage;
