import * as React from 'react';
import { Router, Redirect } from '@reach/router';
import LoginForm from './LoginForm';

import './LoginPage.scss';
import ForgotPasswordForm from './ForgotPasswordForm';

const LoginPage: React.FC = () => {
  return (
    <div className="LoginPage">
      <div className="LoginPage-image" />
      <div className="LoginPage-form-wrapper">
        <Router className="Router">
          <ForgotPasswordForm path="/unohtunut-salasana/:resetToken" />
          <ForgotPasswordForm path="/unohtunut-salasana" />
          <LoginForm path="/" />
          <Redirect from="/*" to="/" noThrow />
        </Router>
      </div>
    </div>
  );
};

export default LoginPage;
