import Spinner from 'react-bootstrap/Spinner';
import * as React from 'react';
import './LoadingIndicator.scss';

interface LoadingSpinnerProps {
  fullScreen?: boolean;
}
const LoadingIndicator = (props: LoadingSpinnerProps) => {
  if (props.fullScreen) {
    return (
      <div className="LoadingIndicator fullscreen">
        <Spinner animation="border" />
      </div>
    );
  }
  return <Spinner animation="border" />;
};

export default LoadingIndicator;
