import * as React from 'react';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';
import './Collapsible.scss';

interface CollapsibleProps {
  header: React.ReactChild | React.ReactChildren;
  defaultOpen?: boolean;
  headerTag?: string;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
}
const Collapsible: React.FC<CollapsibleProps> = (props) => {
  const {
    header,
    children,
    className = '',
    defaultOpen = false,
    headerTag = 'h3',
    headerClassName = '',
    bodyClassName = '',
  } = props;
  const [open, setOpen] = React.useState(defaultOpen);

  const HeaderTag = headerTag as keyof JSX.IntrinsicElements;

  return (
    <div
      className={classNames('Collapsible', { open, closed: !open }, className)}
    >
      <HeaderTag
        className={classNames('header', headerClassName)}
        onClick={() => setOpen((o) => !o)}
      >
        {header}
      </HeaderTag>
      <Collapse in={open}>
        <div className={classNames('body', bodyClassName)}>{children}</div>
      </Collapse>
    </div>
  );
};
export default Collapsible;
