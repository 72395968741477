const photoColors: Record<string, string> = {
  color: 'värikuva',
  bw: 'mustavalkoinen',
  sepia: 'seepia',
};
const inlineStyles: Record<string, {}> = {
  bw: {
    filter: 'grayscale(95%)',
  },
  sepia: {
    filter: 'sepia(1) contrast(80%) hue-rotate(-20deg)',
  },
};

export const photoColorToText = (photoColor: string) =>
  photoColors[photoColor] || photoColor;

export const photoColorInlineStyle = (photoColor: string) =>
  inlineStyles[photoColor] || {};
