import * as React from 'react';
import { useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { Form as FormikForm, Formik } from 'formik';
import { Form } from 'react-bootstrap-formik';
import Alert from 'react-bootstrap/Alert';

import {
  RequestPasswordReset,
  RequestPasswordResetVariables,
} from './__generated__/RequestPasswordReset';
import {
  ResetPassword,
  ResetPasswordVariables,
} from './__generated__/ResetPassword';
import LoadingButton from './LoadingButton';
import Logo from './Logo';

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($newPassword: String!, $resetToken: String!) {
    resetPassword(newPassword: $newPassword, resetToken: $resetToken) {
      success
    }
  }
`;

interface ForgotPasswordFormProps extends RouteComponentProps {
  resetToken?: string;
}
const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { resetToken } = props;
  return resetToken ? (
    <ResetPasswordForm resetToken={resetToken} />
  ) : (
    <RequestPasswordResetForm />
  );
};

interface ResetPasswordFormProps {
  resetToken: string;
}
const resetPasswordFormValidationSchema = yup
  .object({
    newPassword: yup
      .string()
      .required('Syötä salasana')
      .min(12, 'Salasanan täytyy olla vähintään 12 merkkiä pitkä'),
    passwordConfirmation: yup
      .string()
      .required('Syötä salasana uudestaan')
      .oneOf([yup.ref('newPassword')], 'Salasana ei täsmää'),
  })
  .required();
const ResetPasswordForm = ({ resetToken }: ResetPasswordFormProps) => {
  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
  );
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  return (
    <Formik
      initialValues={{
        newPassword: '',
        passwordConfirmation: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={resetPasswordFormValidationSchema}
      onSubmit={async (values) => {
        setError('');
        try {
          await resetPassword({
            variables: {
              resetToken,
              newPassword: (values as any).newPassword,
            },
          });
        } catch (e) {
          setError(e.toString().replace('Error: ', ''));
          return;
        }
        setSuccess(true);
      }}
    >
      {({ isSubmitting }) => (
        <FormikForm>
          <Logo />
          {success ? (
            <>
              <Alert variant="success">
                Salasanasi on vaihdettu onnistuneesti.
              </Alert>
              <div>
                <Link to="/">Kirjaudu sivuille &raquo;</Link>
              </div>
            </>
          ) : (
            <>
              {error && <Alert variant="danger">Virhe: {error}</Alert>}
              <p>Syötä uusi salasana, niin pääset kirjautumaan palveluun.</p>
              <Form.Input
                name="newPassword"
                type="password"
                placeholder="Uusi salasana"
              />
              <Form.Input
                name="passwordConfirmation"
                type="password"
                placeholder="Toista uusi salasana"
              />
              <div className="mt-4">
                <LoadingButton
                  block
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  Vaihda salasana
                </LoadingButton>
              </div>
            </>
          )}
        </FormikForm>
      )}
    </Formik>
  );
};

const requestPasswordResetFormValidationSchema = yup
  .object({
    email: yup
      .string()
      .required('Syötä sähköposti')
      .email('Virheellinen sähköpostiosoite'),
  })
  .required();
const RequestPasswordResetForm = () => {
  const [requestPasswordReset] = useMutation<
    RequestPasswordReset,
    RequestPasswordResetVariables
  >(REQUEST_PASSWORD_RESET);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={requestPasswordResetFormValidationSchema}
      onSubmit={async (values) => {
        setError('');
        try {
          await requestPasswordReset({
            variables: {
              email: (values as any).email,
            },
          });
        } catch (e) {
          setError(e.toString().replace('Error: ', ''));
          return;
        }
        setSuccess(true);
      }}
    >
      {({ isSubmitting }) => (
        <FormikForm>
          <Logo />
          {success ? (
            <Alert variant="success">
              Linkki salasanan vaihtamiseksi on lähetetty sähköpostiisi.
            </Alert>
          ) : (
            <>
              {error && <Alert variant="danger">Virhe: {error}</Alert>}
              <p>
                Anna käyttäjätiliisi liitetty sähköpostiosoite, niin lähetämme
                linkin, jonka kautta voit vaihtaa salasanasi.
              </p>
              <Form.Input name="email" type="text" placeholder="Sähköposti" />
              <div className="mt-4">
                <LoadingButton
                  block
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  Lähetä palautuslinkki sähköpostiin
                </LoadingButton>
              </div>
            </>
          )}
          <div className="my-4">
            <Link to="/">Takaisin kirjautumiseen</Link>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
