import * as React from 'react';
// TODO: get rid of react-bootstrap-table-next and react-bootstrap-table2-toolkit
// they show deprecationwarnings in strict mode
import BootstrapTable from 'react-bootstrap-table-next';
import ReactBootstrapToolkitProvider, {
  Search,
  TableSearchProps,
  TableToolkitProps,
} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from '@reach/router';

const { SearchBar } = Search;

interface SearchProps<T extends object = any> extends TableSearchProps {
  afterSearch?: (results: T[]) => void;
}

interface ToolkitProps<T extends object = any> extends TableToolkitProps {
  search?: SearchProps<T> | boolean;
}

const ToolkitProvider = (props: ToolkitProps) => (
  <ReactBootstrapToolkitProvider {...props} />
);

export interface MatchResult {
  searchText: string;
  value: string;
  column: object;
  row: object;
}

interface Column {
  field: string;
  title: string;
  link?: (row: any) => string;
  formatter?: (col: any, row: any) => any;
}

interface TableProps {
  keyField: string;
  data: any[];
  columns: Column[];
  search: boolean;
  searchText?: string;
  pagination?: boolean;
  backendSearch?: boolean;
  onBeforeSearch?: (value: string) => void;
  afterSearch?: (results: object[]) => void;
  onColumnMatch?: (match: MatchResult, apiSearchResults?: any[]) => boolean;
}

const Table = (props: TableProps) => {
  const {
    columns,
    data,
    keyField,
    search,
    pagination,
    searchText,
    afterSearch,
    backendSearch,
    onColumnMatch,
    onBeforeSearch,
  } = props;

  const bootstrapTableColumns = columns.map((c) => {
    const ret: any = {
      dataField: c.field,
      text: c.title,
    };
    if (c.formatter) {
      ret.formatter = c.formatter;
    } else if (c.link) {
      ret.formatter = (cellStr: string, row: any) => {
        return <Link to={c.link ? c.link(row) : '#'}>{cellStr}</Link>;
      };
    }
    return ret;
  });

  const toolkitProps = {
    keyField,
    data,
    columns: bootstrapTableColumns,
    search: search && {
      afterSearch,
      onColumnMatch: (match: MatchResult) => {
        let { value, searchText } = match;
        value = value?.toString().toLowerCase();
        if (value && value.indexOf(searchText) > -1) {
          return true;
        }

        return !!onColumnMatch?.(match);
      },
    },
    bootstrap4: true,
  };
  const paginationConfig =
    pagination &&
    paginationFactory({
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: 'Kaikki',
          value: data.length,
        },
      ],
    });
  return (
    <ToolkitProvider {...toolkitProps}>
      {(p) => (
        <>
          {search ? (
            <SearchBar
              {...p.searchProps}
              searchText={p.searchProps.searchText || searchText}
              onSearch={(value: string) => {
                const { onSearch } = p.searchProps;
                onBeforeSearch?.(value);
                return !backendSearch && onSearch(value);
              }}
            />
          ) : null}
          <BootstrapTable
            {...p.baseProps}
            pagination={paginationConfig || undefined}
          />
        </>
      )}
    </ToolkitProvider>
  );
};
export default Table;
