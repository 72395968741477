import * as React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { gql, useQuery } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import * as GetPaginatedPhotoshootsTypes from './__generated__/GetPaginatedPhotoshoots';
import SecondaryHeader from './SecondaryHeader';
import Thumbnail from './Thumbnail';
import PhotoGrid from './PhotoGrid';
import './PhotoshootListPage.scss';
import LoadingIndicator from './LoadingIndicator';
import ShareButton from 'components/elements/ShareButton';
import PaginationRow, {
  DEFAULT_PAGE_SIZE,
} from 'components/elements/PaginationRow';
import { sharePhotoshootModalOpenVar } from 'uiState';

const GET_PAGINATED_PHOTOSHOOTS = gql`
  query GetPaginatedPhotoshoots($page: Int, $pageSize: Int) {
    paginatedPhotoshoots(page: $page, pageSize: $pageSize) {
      photoshoots {
        __typename
        id
        slug
        name
        thumbnailUrl
        isOwner
      }
      pagination {
        currentPage
        totalPages
        pageSize
        recordCount
      }
    }
  }
`;

interface PhotoshootListPageProps extends RouteComponentProps {}

const PhotoshootListPage: React.FC<PhotoshootListPageProps> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_PAGE_SIZE);
  const { data, loading, error } =
    useQuery<GetPaginatedPhotoshootsTypes.GetPaginatedPhotoshoots>(
      GET_PAGINATED_PHOTOSHOOTS,
      {
        variables: {
          page,
          pageSize,
        },
      },
    );
  if (error) {
    return <Container fluid>Virhe: {error}</Container>;
  }
  if (loading) {
    return (
      <Container fluid>
        <LoadingIndicator />
      </Container>
    );
  }
  if (!data) {
    return (
      <Container fluid>
        <LoadingIndicator />
      </Container>
    );
  }
  const {
    photoshoots,
    pagination: { totalPages, recordCount },
  } = data.paginatedPhotoshoots;
  return (
    <div className="PhotoshootListPage">
      <SecondaryHeader path="Omat kuvat" />
      <Container fluid>
        <PhotoGrid>
          {photoshoots.map((photoshoot) => (
            <React.Fragment key={photoshoot.slug}>
              {photoshoot.isOwner && (
                <ShareButton
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    sharePhotoshootModalOpenVar(photoshoot.id);
                  }}
                />
              )}
              <Link to={`/kuvat/${photoshoot.slug}`}>
                <Thumbnail
                  src={photoshoot.thumbnailUrl}
                  alt={photoshoot.name}
                  caption={photoshoot.name}
                  subcaption={/*"1.1.2021" TODO: get actual date!*/ ''}
                />
              </Link>
            </React.Fragment>
          ))}
        </PhotoGrid>
        <PaginationRow
          currentPage={page}
          totalPages={totalPages}
          pageSize={pageSize}
          recordCount={recordCount}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Container>
    </div>
  );
};

export default PhotoshootListPage;
