import * as React from 'react';
import IconButton from './IconButton';

interface ShareButtonProps {
  children?: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
}
const ShareButton: React.FC<ShareButtonProps> = ({
  onClick,
  children,
}: ShareButtonProps) => (
  <IconButton icon="share" onClick={onClick}>
    {children}
  </IconButton>
);

export default ShareButton;
