import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import { productInfoModalOpenVar } from '../uiState';
import { ProductInfoRecord } from 'types/productInfo';
import './ProductInfoModal.scss';

const ProductInfoModal: React.FC = () => {
  const product: ProductInfoRecord | null = useReactiveVar(
    productInfoModalOpenVar,
  );
  if (product === null) {
    return <></>;
  }
  const handleClose = () => productInfoModalOpenVar(null);
  return (
    <Modal
      show={!!product}
      onHide={handleClose}
      dialogClassName="generic-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{product.title?.rendered}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row product-info">
          <div className="col-lg-6">
            <h3>Hinnasto</h3>

            {product.acf.hintataulukko?.map((priceTable: any) => (
              <div className="table-responsive">
                <table className="hinnasto">
                  {!!priceTable.hinnaston_otsikko && (
                    <thead>
                      <tr>
                        <td colSpan={2}>
                          <strong>{priceTable.hinnaston_otsikko}</strong>
                        </td>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {priceTable.hinnasto_repeater.map((row: any) => (
                      <tr>
                        <td>{row.tekstikentta}</td>

                        <td className="hinta1">{row.hinta}</td>

                        <td className="hinta2">{row.hinta_2}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>

          <div className="col-lg-6 more-information">
            <h3>Lisätietoa</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: product.acf.tuotteen_pitka_kuvaus,
              }}
            />
          </div>

          {!!product.acf.tuotekuvat && (
            <div className="col-12">
              <h3>Tuotekuvat</h3>
              <div className="gallery-block">
                {product.acf.tuotekuvat.map((image) => (
                  <div className="gallery-item">
                    <a href={image.url} target="_blank" rel="noreferrer">
                      <img
                        src={String(image.sizes.thumbnail)}
                        alt={image.alt}
                        title={image.title}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ProductInfoModal;
