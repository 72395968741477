import * as React from 'react';
import './PhotoshootMessage.scss';

interface PhotoshootMessageProps {
  children: string | null;
}
const PhotoshootMessage: React.FC<PhotoshootMessageProps> = (props) => {
  let message = (props.children || '')
    .split('\n')
    .filter((str) => !!str) // Ensure that empty strings don't create more space
    .map((str) => <div key={str}>{str}</div>);
  return (
    <>
      {!!message.length && <div className="PhotoshootMessage">{message}</div>}
    </>
  );
};
export default PhotoshootMessage;
