import * as React from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import Moment from 'react-moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form as FormikForm, Formik } from 'formik';
import {
  gql,
  useMutation,
  useQuery,
  useReactiveVar,
  useApolloClient,
} from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap-formik';
import { GetPhotoshootAccessList } from './__generated__/GetPhotoshootAccessList';
import {
  AddPhotoshootAccess,
  AddPhotoshootAccessVariables,
} from './__generated__/AddPhotoshootAccess';
import {
  RemovePhotoshootAccess,
  RemovePhotoshootAccessVariables,
} from './__generated__/RemovePhotoshootAccess';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';
import LoadingButton from './LoadingButton';
import LoadingIndicator from './LoadingIndicator';
import { sharePhotoshootModalOpenVar } from 'uiState';
import './SharePhotoshootModal.scss';

const GET_PHOTOSHOOT_ACCESS_LIST = gql`
  query GetPhotoshootAccessList($photoshootId: Int!) {
    photoshootAccessList(photoshootId: $photoshootId) {
      __typename
      id
      user {
        id
        displayName
      }
      photoshoot {
        id
      }
      isOwner
      createdAt
    }
  }
`;

export const ADD_PHOTOSHOOT_ACCESS = gql`
  mutation AddPhotoshootAccess(
    $email: String!
    $photoshootId: Int!
    $firstName: String = null
    $lastName: String = null
    $phoneNumber: String = null
  ) {
    addPhotoshootAccess(
      email: $email
      photoshootId: $photoshootId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      ok
    }
  }
`;

export const REMOVE_PHOTOSHOOT_ACCESS = gql`
  mutation RemovePhotoshootAccess($photoshootId: Int!, $userId: Int!) {
    removePhotoshootAccess(photoshootId: $photoshootId, userId: $userId) {
      ok
    }
  }
`;

interface SharePhotoshootModalBodyProps {
  photoshootId: number;
}
const SharePhotoshootModalBody: React.FC<SharePhotoshootModalBodyProps> = ({
  photoshootId,
}: SharePhotoshootModalBodyProps) => {
  const apolloClient = useApolloClient();
  const { data, loading, error } = useQuery<GetPhotoshootAccessList>(
    GET_PHOTOSHOOT_ACCESS_LIST,
    { variables: { photoshootId } },
  );
  const [removePhotoshootAccess] = useMutation<
    RemovePhotoshootAccess,
    RemovePhotoshootAccessVariables
  >(REMOVE_PHOTOSHOOT_ACCESS);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  if (loading) {
    return (
      <div className="flex-center">
        <LoadingIndicator />
      </div>
    );
  }
  if (error) {
    return <Container fluid>Virhe: {error}</Container>;
  }

  return (
    <Container fluid>
      <Row>
        <Col md className="mb-12">
          <InviteForm photoshootId={photoshootId} />
        </Col>
      </Row>
      <Row>
        <Col md className="mb-12">
          <h3>Käyttöoikeudet</h3>
        </Col>
      </Row>
      <Row>
        <Col md className="mb-12">
          {!!message && <Alert variant={messageType}>{message}</Alert>}
          {!data?.photoshootAccessList.length && (
            <Row>
              <Col>Vain sinä voit tarkastella tätä kuvakansiota.</Col>
            </Row>
          )}
          {data?.photoshootAccessList.map(({ user, createdAt }) => {
            return (
              <Row key={`access_${user.id}`}>
                <Col>
                  <div className="title">{user.displayName}</div>
                  <div className="subtitle">
                    Lisätty&nbsp;
                    <Moment>{createdAt}</Moment>
                  </div>
                </Col>
                <Col xs={2}>
                  <div
                    className="delete-icon"
                    onClick={async () => {
                      setMessage('');
                      setMessageType('');
                      try {
                        await removePhotoshootAccess({
                          variables: {
                            photoshootId,
                            userId: user.id,
                          } as any,
                        });
                      } catch (e) {
                        setMessage(
                          'Virhe tietojen päivityksessä: ' + e.toString(),
                        );
                        setMessageType('danger');
                        return;
                      }

                      await apolloClient.query({
                        query: GET_PHOTOSHOOT_ACCESS_LIST,
                        variables: { photoshootId },
                        fetchPolicy: 'network-only',
                      });
                    }}
                  >
                    <img src={TrashIcon} alt={''} />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

const SharePhotoshootModal = () => {
  const photoshootId: number = useReactiveVar(sharePhotoshootModalOpenVar);
  const handleClose = () => sharePhotoshootModalOpenVar(0);

  return (
    <Modal
      className="SharePhotoshootModal"
      show={!!photoshootId}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Lisää käyttöoikeuksia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SharePhotoshootModalBody photoshootId={photoshootId} />
      </Modal.Body>
    </Modal>
  );
};
export default SharePhotoshootModal;

interface InviteFormProps {
  photoshootId: number;
}
const inviteFormValidationSchema = yup
  .object({
    firstName: yup.string().required('Syötä etunimi'),
    lastName: yup.string().required('Syötä sukunimi'),
    email: yup.string().required('Syötä sähköposti'),
    phoneNumber: yup.string().required('Syötä puhelinnumero'),
  })
  .required();
const InviteForm = ({ photoshootId }: InviteFormProps) => {
  const apolloClient = useApolloClient();
  const [addPhotoshootAccess] = useMutation<
    AddPhotoshootAccess,
    AddPhotoshootAccessVariables
  >(ADD_PHOTOSHOOT_ACCESS);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          photoshootId,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={inviteFormValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          setMessage('');
          setMessageType('');
          try {
            await addPhotoshootAccess({
              variables: {
                ...values,
              } as any,
            });
          } catch (e) {
            setMessage('Virhe tietojen päivityksessä: ' + e.toString());
            setMessageType('danger');
            return;
          }
          setMessage('Käyttäjä lisätty luetteloon');
          setMessageType('success');
          resetForm();

          await apolloClient.query({
            query: GET_PHOTOSHOOT_ACCESS_LIST,
            variables: { photoshootId },
            fetchPolicy: 'network-only',
          });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            {!!message && <Alert variant={messageType}>{message}</Alert>}
            <Form.Input name="firstName" placeholder="Etunimi" />
            <Form.Input name="lastName" placeholder="Sukunimi" />
            <Form.Input name="email" placeholder="Sähköposti" />
            <Form.Input name="phoneNumber" placeholder="Puhelinnumero" />
            <div className="mt-4">
              <LoadingButton
                block
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Lisää käyttäjä
              </LoadingButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
