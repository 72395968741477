import Modal from 'react-bootstrap/Modal';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import Image from 'react-bootstrap/Image';

interface GenericInfoModalProps {
  open: boolean;
  onHide: () => void;
  title?: string;
  imageUrl?: string;
  imageAlt?: string;
  description?: string;
  modalClassName?: string;
}
export const GenericInfoModal: React.FC<GenericInfoModalProps> = ({
  open,
  title,
  onHide,
  imageUrl,
  imageAlt,
  description,
  modalClassName = 'generic-modal',
}: GenericInfoModalProps) => {
  if (!imageUrl && !description) {
    return null;
  }
  return (
    <Modal show={open} onHide={onHide} dialogClassName={modalClassName}>
      <Modal.Header closeButton>
        {!!title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        {imageUrl && (
          <div className="my-2">
            <Image src={imageUrl} alt={imageAlt || title} />
          </div>
        )}
        {!!description && (
          <ReactMarkdown linkTarget="_blank">{description}</ReactMarkdown>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenericInfoModal;
