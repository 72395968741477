import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import * as GetPhotoshootTypes from './__generated__/GetPhotoshoot';
import { Photo } from '../types/__generated__/Photo';
import { PHOTO_FRAGMENT, MARK_FAVOURITE_PHOTO } from 'types/photos';
import LoadingIndicator from './LoadingIndicator';
import SecondaryHeader from './SecondaryHeader';
import PhotoGrid from './PhotoGrid';
import Thumbnail from './Thumbnail';
import PhotoDetails from './PhotoDetails';
import PhotoCompare from './PhotoCompare';
import './PhotoshootDetailsPage.scss';
import PhotoshootToolbar from 'components/elements/PhotoshootToolbar';
import SharePhotoshootLink from 'components/elements/SharePhotoshootLink';
import HeartButton from 'components/elements/HeartButton';
import {
  MarkFavouritePhoto,
  MarkFavouritePhotoVariables,
} from './__generated__/MarkFavouritePhoto';
import { useLocalStorage } from 'utils/hooks';
import { PHOTOSHOOT_FREE_PRODUCT_FRAGMENT } from 'types/photoshoots';
import BottomToolbar from './elements/BottomToolbar';

const GET_PHOTOSHOOT = gql`
  query GetPhotoshoot($slug: String!, $favouriteOnly: Boolean!) {
    photoshoot(slug: $slug, favouriteOnly: $favouriteOnly) {
      __typename
      id
      slug
      name
      priceList {
        id
        code
        colorChoice
      }
      photos {
        ...Photo
      }
      message
      isOwner
      freeProducts {
        ...PhotoshootFreeProduct
      }
    }
  }
  ${PHOTO_FRAGMENT}
  ${PHOTOSHOOT_FREE_PRODUCT_FRAGMENT}
`;

const toggleSelectCompare = (
  selectCompare: boolean,
  setSelectCompare: (v: boolean) => void,
  setViewingCompare: (v: boolean) => void,
  setSelectedForCompare: (v: number[]) => void,
) => {
  if (selectCompare) {
    // Clear previous selections when disabling photo comparison select
    setSelectedForCompare([]);
    setViewingCompare(false);
  }
  setSelectCompare(!selectCompare);
};

interface PhotoshootDetailsPageProps extends RouteComponentProps {
  slug?: string;
  code?: string;
}
const PhotoshootDetailsPage: React.FC<PhotoshootDetailsPageProps> = ({
  slug,
  code,
}) => {
  const [favouriteOnly, setFavouriteOnly] = useLocalStorage(
    'favouriteOnly',
    false,
  );
  const [selectCompare, setSelectCompare] = React.useState<boolean>(false);
  const [selectedForCompare, setSelectedForCompare] = React.useState<number[]>(
    [],
  );
  const [viewingCompare, setViewingCompare] = React.useState<boolean>(false);
  const [replacePhotoId, setReplacePhotoId] = React.useState<number>(0);
  const apolloClient = useApolloClient();
  const { data, loading, error } = useQuery<
    GetPhotoshootTypes.GetPhotoshoot,
    GetPhotoshootTypes.GetPhotoshootVariables
  >(GET_PHOTOSHOOT, {
    variables: { slug: slug || '', favouriteOnly },
    // poll every N minutes to update image urls
    pollInterval: 45 * 60 * 60 * 1000,
  });
  const [markFavouritePhoto] = useMutation<
    MarkFavouritePhoto,
    MarkFavouritePhotoVariables
  >(MARK_FAVOURITE_PHOTO);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }
  if (error) {
    return <Container fluid>Error: {error.message}</Container>;
  }

  const { photoshoot } = data || {};
  if (!photoshoot) {
    return <Container fluid>Kuvausta ei löydy</Container>;
  }

  let selectedPhoto = null;
  if (code) {
    selectedPhoto = photoshoot.photos.find((p) => p.code === code);
  }
  // Preserve order of selected items when comparing. Needed for correct substitution.
  const comparingPhotos: GetPhotoshootTypes.GetPhotoshoot_photoshoot_photos[] =
    selectedForCompare.map<GetPhotoshootTypes.GetPhotoshoot_photoshoot_photos>(
      (photoId) =>
        photoshoot.photos.find((p) => p.id === photoId) || photoshoot.photos[0],
    );
  const cancelCompare = () => {
    toggleSelectCompare(
      true,
      setSelectCompare,
      setViewingCompare,
      setSelectedForCompare,
    );
    setReplacePhotoId(0);
  };
  const bottomToolbarVisible =
    !!replacePhotoId || (selectCompare && !viewingCompare);

  const breadcrumbPath = ['Omat kuvat', photoshoot.name];
  if (selectedPhoto) {
    breadcrumbPath.push(selectedPhoto.code);
  }
  const getMarkFavouriteAction = (photo: Photo) => () => {
    markFavouritePhoto({
      variables: {
        photoId: photo.id,
      },
    });
    apolloClient.query({
      query: GET_PHOTOSHOOT,
      variables: {
        slug: slug || '',
        favouriteOnly,
      },
      fetchPolicy: 'network-only',
    });
  };

  const onCompareCheckboxChange = (photo: Photo) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (selectedForCompare.includes(photo.id)) {
      setSelectedForCompare([
        ...selectedForCompare.filter((id: number) => id !== photo.id),
      ]);
    } else {
      setSelectedForCompare([...selectedForCompare, photo.id]);
    }
  };
  return (
    <div className="PhotoshootDetailsPage">
      <SecondaryHeader
        path={breadcrumbPath}
        favouriteOnly={favouriteOnly}
        withBottomMargin={!!selectedPhoto}
        setFavouriteOnly={async (val: boolean) => {
          // Update the state and forcefully reload the photos
          setFavouriteOnly(val);
          await apolloClient.query({
            query: GET_PHOTOSHOOT,
            variables: { slug: slug || '', favouriteOnly: val },
            fetchPolicy: 'network-only',
          });
        }}
      />
      <Container fluid>
        {selectedPhoto ? (
          <PhotoDetails
            selectedPhoto={selectedPhoto}
            photoshoot={photoshoot}
            getMarkFavouriteAction={getMarkFavouriteAction}
          />
        ) : viewingCompare && !replacePhotoId ? (
          <PhotoCompare
            photos={comparingPhotos}
            cancelCompare={cancelCompare}
            onReplacePhoto={setReplacePhotoId}
          />
        ) : (
          <>
            <PhotoshootToolbar
              photoshoot={photoshoot}
              setSelectCompare={
                !replacePhotoId
                  ? () =>
                      toggleSelectCompare(
                        selectCompare,
                        setSelectCompare,
                        setViewingCompare,
                        setSelectedForCompare,
                      )
                  : undefined
              }
            />
            {photoshoot.isOwner && (
              <SharePhotoshootLink photoshootId={photoshoot.id} />
            )}
            <PhotoGrid>
              {photoshoot.photos
                .filter(
                  (photo) =>
                    /**
                     * In "Vaihda kuva" mode only show the remaining pictures
                     * that aren't being compared currently.
                     */
                    !replacePhotoId || !selectedForCompare.includes(photo.id),
                )
                .map((photo) => {
                  const selected =
                    selectCompare && selectedForCompare.includes(photo.id);
                  return (
                    <div key={photo.code}>
                      <HeartButton
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          getMarkFavouriteAction(photo)();
                        }}
                        fill={photo.isFavourite}
                      />
                      <Thumbnail
                        variant="square"
                        linkTo={
                          replacePhotoId || selectCompare
                            ? undefined
                            : `/kuvat/${photoshoot.slug}/${photo.code}`
                        }
                        background="dark"
                        src={photo.previewUrl}
                        alt={photo.code}
                        caption={photo.code}
                        rating={photo.rating}
                        showCheckbox={selectCompare && !replacePhotoId}
                        isChecked={selected}
                        onImageClick={(e: React.MouseEvent) => {
                          if (!replacePhotoId) {
                            // Shortcut to checkbox action:
                            // it is easier to click on the picture than a checkbox
                            return (
                              selectCompare && onCompareCheckboxChange(photo)(e)
                            );
                          }
                          e.preventDefault();
                          setReplacePhotoId(0);
                          // Replace photo ID at its original index with the new photo ID
                          selectedForCompare[
                            selectedForCompare
                              .map((photoId, i) => [i, photoId])
                              .filter((row) => row[1] === replacePhotoId)[0][0]
                          ] = photo.id;
                          setSelectedForCompare([...selectedForCompare]);
                        }}
                        onCheckboxChange={onCompareCheckboxChange(photo)}
                      />
                    </div>
                  );
                })}
            </PhotoGrid>
          </>
        )}
      </Container>
      <BottomToolbar isVisible={bottomToolbarVisible}>
        {!replacePhotoId && (
          <>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                cancelCompare();
              }}
            >
              Poistu vertailusta
            </Button>
            <Button
              disabled={bottomToolbarVisible && selectedForCompare.length < 2}
              onClick={(e) => {
                e.preventDefault();
                setViewingCompare(true);
              }}
            >
              Vertaa valittuja kuvia
            </Button>
          </>
        )}
        {!!replacePhotoId && (
          <>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                setReplacePhotoId(0);
              }}
            >
              Peruuttaa
            </Button>
            <div className="toolbar-text">
              Valitse uusi kuva napsauttamalla sitä
            </div>
          </>
        )}
      </BottomToolbar>
    </div>
  );
};

export default PhotoshootDetailsPage;
