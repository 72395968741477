import * as React from 'react';
import Button from 'react-bootstrap/Button';
import IconButton from 'components/elements/IconButton';
import { Photo } from '../types/__generated__/Photo';
import PhotoDisplay from 'components/elements/PhotoDisplay';

import './PhotoCompare.scss';

interface PhotoCompareProps {
  photos: Photo[];
  cancelCompare: () => void;
  onReplacePhoto: (id: number) => void;
}
const PhotoCompare = (props: PhotoCompareProps) => {
  const { photos, cancelCompare, onReplacePhoto } = props;

  return (
    <div className="PhotoCompare">
      {photos.map((photo: Photo, idx: number) => (
        <div key={`compare_${photo.id}`} className="photo-item">
          <div className="top-row">
            {!idx ? (
              <Button variant="secondary" onClick={cancelCompare}>
                Poistu vertailusta
              </Button>
            ) : (
              <div />
            )}
            <IconButton
              whiteBackground={false}
              icon="arrow-repeat"
              onClick={() => onReplacePhoto(photo.id)}
            >
              Vaihda kuva
            </IconButton>
          </div>
          <PhotoDisplay selectedPhoto={photo} />
          <div className="bottom-row">
            <span>{photo.code}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default PhotoCompare;
