import { GetProductCategories_productCategories_products as Product } from 'components/__generated__/GetProductCategories';
import { ShoppingCart_items_product as ShoppingCartProduct } from 'types/__generated__/ShoppingCart';

interface PropertyStub {
  key: string;
  value: string;
}
interface ProductStub {
  properties: PropertyStub[];
}

export function filterMatchingProducts<T extends ProductStub>(
  products: T[],
  properties: Record<string, string>,
): T[] {
  return products.filter((product) => {
    for (const key of Object.keys(properties)) {
      const value = properties[key];
      const property = product.properties.find((p) => p.key === key);
      if (!property) {
        if (!value) {
          // special case: empty value means it didn't exist after all
          continue;
        }
        return false;
      }
      if (property.value !== value) {
        return false;
      }
    }
    return true;
  });
}

export function getQuantityPlaceholder(product: Product | ShoppingCartProduct) {
  let ret = 'Määrä';
  if (product.maxQuantity) {
    ret += ` (${product.minQuantity} - ${product.maxQuantity})`;
  } else if (product.minQuantity > 1) {
    ret += ` (vähintään ${product.minQuantity})`;
  }
  return ret;
}
