import * as React from 'react';

interface PriceProps {
  children: string;
}
const Price: React.FC<PriceProps> = (props) => {
  let price = props.children;
  price = price.replace('.', ',');
  return <React.Fragment>{price}&nbsp;&euro;</React.Fragment>;
};
export default Price;
