import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import './PaginationRow.scss';

interface PaginationRowProps extends RouteComponentProps {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  recordCount?: number;
  setPage: (value: number) => void;
  setPageSize?: (value: number) => void;
}

// Number of next/previous pages displayed around the current page
const SURROUNDING_PAGES_COUNT = 2;
const PAGE_SIZES = [10, 25, 50];

export const DEFAULT_PAGE_SIZE = 25;

const PaginationRow: React.FC<PaginationRowProps> = ({
  currentPage,
  totalPages,
  pageSize,
  setPage,
  recordCount,
  setPageSize,
}) => {
  // Do not show pagination for a single page, unless page size was manually adjusted
  // (i.e. pagination was visible originally)
  if (totalPages <= 1 && pageSize === DEFAULT_PAGE_SIZE) {
    return <></>;
  }

  let minPage = currentPage - SURROUNDING_PAGES_COUNT;
  let maxPage = currentPage + SURROUNDING_PAGES_COUNT;
  if (minPage < 1) {
    minPage = 1;
  }
  if (maxPage > totalPages) {
    maxPage = totalPages;
  }
  return (
    <div className="PaginationRow">
      <div>
        <div className="page-size-selector">
          {!!setPageSize && (
            <select
              value={pageSize}
              onChange={(e) => {
                const newPageSize: number = parseInt(e.target.value);
                setPageSize(newPageSize);
                setPage(1);
              }}
            >
              {PAGE_SIZES.map((sizeOption) => (
                <option key={`page_size_${sizeOption}`} value={sizeOption}>
                  {sizeOption}
                </option>
              ))}
              {/**
               * If there are more records than the maximum page size
               * add total count as an option to display all records on one page.
               * */}
              {!!recordCount &&
                recordCount > PAGE_SIZES[PAGE_SIZES.length - 1] && (
                  <option value={recordCount}>{recordCount}</option>
                )}
            </select>
          )}
        </div>
        <ul className="pagination">
          {currentPage > 1 && (
            <>
              <li
                className="page-item"
                title="next page"
                onClick={() => setPage(currentPage - 1)}
              >
                <button className="page-link">&lt;</button>
              </li>
              {minPage > 1 && (
                <>
                  <li
                    className="page-item"
                    title="next page"
                    onClick={() => setPage(1)}
                  >
                    <button className="page-link">1</button>
                  </li>
                  {minPage > 2 && <span>...</span>}
                </>
              )}
            </>
          )}
          {[...Array(totalPages)]
            .map((_, idx) => idx + 1)
            .filter((page) => page >= minPage && page <= maxPage)
            .map((page) => (
              <li
                key={`pagination-li-${page}`}
                className={`page-item ${
                  Number(page) === Number(currentPage) ? 'active' : ''
                }`}
                title={`${page}`}
                onClick={() => setPage(page)}
              >
                <button className="page-link">{page}</button>
              </li>
            ))}
          {currentPage < totalPages && (
            <>
              {maxPage < totalPages && (
                <>
                  {maxPage < totalPages - 1 && <span>...</span>}
                  <li
                    className="page-item"
                    title="next page"
                    onClick={() => setPage(totalPages)}
                  >
                    <button className="page-link">{totalPages}</button>
                  </li>
                </>
              )}
              <li
                className="page-item"
                title="next page"
                onClick={() => setPage(currentPage + 1)}
              >
                <button className="page-link">&gt;</button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default PaginationRow;
