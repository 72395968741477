import * as React from 'react';
import classNames from 'classnames';

import './Rating.scss';

interface RatingProps {
  value: number;
  max?: number;
  onRate?: (rating: number) => Promise<void>;
}
const Rating = (props: RatingProps) => {
  const { value, max = 5, onRate } = props;

  const [hoverValue, setHoverValue] = React.useState<number | null>(null);
  const [ratedValue, setRatedValue] = React.useState<number | null>(null);

  const starArray: number[] = [];
  for (let i = 1; i <= max; i++) {
    starArray.push(i);
  }

  let visibleValue: number;
  if (ratedValue !== null) {
    visibleValue = ratedValue;
  } else if (hoverValue !== null) {
    visibleValue = hoverValue;
  } else {
    visibleValue = value;
  }

  const handleRate = async (rating: number) => {
    setRatedValue(rating);
    try {
      if (onRate) {
        await onRate(rating);
      }
    } catch (e) {
      console.error('error rating:', e);
    }
    setRatedValue(null);
  };

  return (
    <div
      className={classNames('Rating', {
        editable: onRate,
      })}
    >
      {starArray.map((i) => {
        let editableProps = {};
        if (onRate) {
          editableProps = {
            onMouseEnter: () => setHoverValue(i),
            onMouseLeave: () => setHoverValue(null),
            onClick: () => handleRate(i),
          };
        }
        return (
          <span
            key={i}
            className={classNames('star', {
              filled: i <= visibleValue,
            })}
            {...editableProps}
          />
        );
      })}
      {onRate && (
        <span
          className="remove"
          onMouseEnter={() => setHoverValue(0)}
          onMouseLeave={() => setHoverValue(null)}
          onClick={() => handleRate(0)}
        />
      )}
    </div>
  );
};
export default Rating;
