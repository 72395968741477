import * as React from 'react';
import { useState } from 'react';
import { Link, RouteComponentProps, useParams } from '@reach/router';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Moment from 'react-moment';
import * as htmlToImage from 'html-to-image';

import { ADMIN_GET_ORDER, ADMIN_GET_ORDERS } from '../queries';
import { AdminGetOrders } from '../__generated__/AdminGetOrders';
import {
  AdminGetOrder,
  AdminGetOrderVariables,
} from '../__generated__/AdminGetOrder';

import QueryLoader from './QueryLoader';
import Table from './Table';
import MultiLineText from './MultiLineText';
import PhotoGrid from 'components/PhotoGrid';
import Thumbnail from 'components/Thumbnail';
import Price from 'components/Price';
import Percentage from 'components/Percentage';
import { capitalize } from 'utils/text';
import { photoColorToText, photoColorInlineStyle } from 'utils/photos';
import { useApolloClient } from '@apollo/client';
import { apiRequest } from '../api';

interface AdminOrdersPageProps extends RouteComponentProps {}
const AdminOrdersPage: React.FC<AdminOrdersPageProps> = () => {
  const params = useParams();
  const orderId = params?.orderId;

  return (
    <div>
      <div className="admin-page-group-header">
        <h1>Tilaukset</h1>
        <Nav defaultActiveKey={''} variant="pills" className="my-3">
          <Nav.Item>
            <Nav.Link as={Link} to="/hallinta/tilaukset" eventKey="">
              Tilauslista
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      {orderId ? <OrderDetails orderId={parseInt(orderId)} /> : <OrderList />}
    </div>
  );
};

// Non-reactive memory storage for printer-friendly converted images.
// Populated asynchronously by htmlToImage and must not trigger re-renders
// until the process is complete for all visible photos.
const bakedImages: string[] = [];
const onPrint =
  (
    setIsPrinting: (v: boolean) => void,
    setBakedImageURLs: (v: string[]) => void,
  ) =>
  () => {
    // XXX: getElementsByClassName is not ideal, but a ref-powered React version
    // is much more clunky and excessively verbose thanks to TypeScript's quirks.
    const photos = document.getElementsByClassName('printable');
    for (let i = 0; i < photos.length; ++i) {
      const photo = photos[i];
      htmlToImage
        .toPng(photo as unknown as HTMLElement)
        .then((imageBase64: string) => {
          bakedImages[i] = imageBase64;
          if (bakedImages.length === photos.length) {
            setBakedImageURLs([...bakedImages]);

            setTimeout(() => {
              // Delay state change until the next render cycle after setBakedImageURLs.
              // i.e. avoid printing before the baked images are fully rendered.
              setIsPrinting(true);
            }, 200);
          }
        });
    }
  };

interface PhotoThumbnailPreviewProps {
  photoColor?: string;
  photoCode?: string;
  photoUrl?: string;
  children?: React.ReactNode;
}
const PhotoThumbnailPreview = (props: PhotoThumbnailPreviewProps) => {
  const { photoCode, photoUrl, photoColor } = props;
  return (
    <div
      className={`mr-2 printable`}
      style={{
        maxWidth: '200px',
        // Inline style for a reliable DOM to image conversion
        // which can otherwise fail with external CSS files (e.g. due to CORS or a SecurityError)
        ...(!!photoColor && photoColorInlineStyle(photoColor)),
      }}
    >
      {!!photoUrl && (
        <Thumbnail
          variant="original"
          key={photoCode}
          src={photoUrl}
          alt={photoCode || ''}
        />
      )}
    </div>
  );
};

interface OrderDetailsProps {
  orderId: number;
}
const OrderDetails = (props: OrderDetailsProps) => {
  const { orderId } = props;
  const [actionInProgress, setActionInProgress] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const apolloClient = useApolloClient();
  const [bakedImageURLs, setBakedImageURLs] = useState<string[]>([]);
  let photoCounter: number = 0; // Global photo index across all order items

  React.useEffect(() => {
    if (!bakedImageURLs.length || !isPrinting) {
      return;
    }
    window.print();
    setIsPrinting(false);
  }, [bakedImageURLs, isPrinting, setIsPrinting]);

  const updateStatusHandler =
    (newStatus: string) => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (actionInProgress) {
        return;
      }
      setActionInProgress(true);
      try {
        await apiRequest(`/orders/${orderId}/status`, {
          method: 'PUT',
          data: {
            status: newStatus,
          },
        });
      } catch (e) {
        console.error('Error updating order status', e);
        alert(e);
        setActionInProgress(false);
        return;
      }

      await apolloClient.query({
        query: ADMIN_GET_ORDER,
        variables: {
          id: orderId,
        },
        fetchPolicy: 'network-only',
      });
      setActionInProgress(false);
    };

  return (
    <div>
      <QueryLoader<AdminGetOrder, AdminGetOrderVariables>
        query={ADMIN_GET_ORDER}
        options={{
          variables: {
            id: orderId,
          },
        }}
      >
        {({ order }) => {
          if (!order) {
            return <div>Tilausta ei löydy.</div>;
          }

          const addressDetails =
            order.addressDetails || order.user.addressDetails;

          return (
            <div>
              <h2>
                Tilaus: #{order.id} {order.user.displayName}
              </h2>
              <Row>
                <Col>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Asiakas</td>
                        <td>
                          {addressDetails.firstName} {addressDetails.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>Puhelin</td>
                        <td>{addressDetails.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>Sähköposti</td>
                        <td>
                          <a href={`mailto:${order.user.email}`}>
                            {order.user.email}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Yrityksen nimi</td>
                        <td>{addressDetails.companyName}</td>
                      </tr>
                      <tr>
                        <td>Osoite</td>
                        <td>
                          {addressDetails.streetAddress}
                          <br />
                          {addressDetails.postalCode}{' '}
                          {addressDetails.postalLocation}
                        </td>
                      </tr>
                      <tr>
                        <td>Lisätietoja</td>
                        <td>
                          <MultiLineText>{order.additionalInfo}</MultiLineText>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Tilausnumero</td>
                        <td>{order.id}</td>
                      </tr>
                      <tr>
                        <td>Tilausaika</td>
                        <td>
                          <Moment>{order.submittedAt}</Moment>
                        </td>
                      </tr>
                      <tr>
                        <td>Tila</td>
                        <td>
                          {orderStatusToText(order.status)}
                          {order.processedAt && (
                            <>
                              <br />
                              <small>
                                Merkattu valmiiksi{' '}
                                <Moment>{order.processedAt}</Moment>
                              </small>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Käsiteltävät kuvat</td>
                        <td>
                          {
                            order.orderPhotos.filter((p) => p.needsRetouching)
                              .length
                          }
                          &nbsp;kpl
                          <br />
                          <small>
                            (<Price>{order.totalRetouchingFee}</Price>)
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <td>PSV-Galleria</td>
                        <td>
                          {order.orderedGallery ? (
                            <div>
                              kyllä
                              <br />
                              <small>
                                (<Price>{order.orderedGalleryFee}</Price>)
                              </small>
                            </div>
                          ) : (
                            'ei'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Alennukset</td>
                        <td>
                          {order.discounts.map((discount, i) => (
                            <div key={i}>
                              {discount.name}{' '}
                              <Percentage negative>
                                {discount.percentage}
                              </Percentage>
                            </div>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <td>Hinta yhteensä</td>
                        <td>
                          <Price>{order.totalPrice}</Price>
                          <br />
                          <small>
                            (sis. alv <Price>{order.totalVat}</Price>)
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <h2>Tilatut kuvatiedostot</h2>
              <div>
                <PhotoGrid itemSize="small">
                  {order.orderPhotos.map((orderPhoto, i) => {
                    const photo = orderPhoto.photo;
                    return (
                      <div style={{ lineHeight: '0.8' }} key={i}>
                        {photo.photoshoot.name}
                        <br />
                        <br />
                        <strong>{photo.code}</strong>
                        {orderPhoto.needsRetouching && (
                          <>
                            <br />
                            <br />
                            <small>
                              Käsittelymaksu:{' '}
                              <Price>{orderPhoto.retouchingFee}</Price>
                            </small>
                          </>
                        )}
                      </div>
                    );
                  })}
                </PhotoGrid>
              </div>
              <h2>Tilatut kuvatuoteet</h2>
              {order.items.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Kuva(t)</th>
                      <th>Kuvatuote</th>
                      <th>Kpl</th>
                      <th>Hinta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.orderItemPhotos.map((orderItemPhoto, i) => {
                            const { photo, photoColor } = orderItemPhoto;
                            const bakedImageURL = bakedImageURLs[photoCounter];
                            photoCounter++;
                            return (
                              <div key={i} className="d-flex flex-row my-2">
                                <PhotoThumbnailPreview
                                  photoCode={photo.code}
                                  photoColor={photoColor}
                                  photoUrl={bakedImageURL || photo.previewUrl}
                                />

                                <div
                                  style={{
                                    lineHeight: '1.2',
                                  }}
                                >
                                  {item.orderItemPhotos.length > 1 && (
                                    <>
                                      <strong>Kuva #{i + 1}</strong>
                                      <br />
                                    </>
                                  )}
                                  <strong>Kuvakansio:</strong>{' '}
                                  {photo.photoshoot.name}
                                  <br />
                                  <strong>Koodi:</strong> {photo.code}
                                  <br />
                                  <strong>Väri:</strong>{' '}
                                  {photoColorToText(photoColor)}
                                </div>
                              </div>
                            );
                          })}
                        </td>
                        <td>
                          <strong>{item.product.category.name}</strong>
                          {item.product.properties.map((prop) => (
                            <div key={prop.key}>
                              <strong>{capitalize(prop.keyName)}:</strong>{' '}
                              {prop.name}
                            </div>
                          ))}
                        </td>
                        <td>{item.quantity} kpl</td>
                        <td>
                          <Price>{item.price}</Price>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>Tilauksessa ei ole rivejä.</div>
              )}
              <div className="my-3 d-print-none">
                {order.status === 'submitted' ? (
                  <Button
                    variant="primary"
                    disabled={actionInProgress}
                    onClick={updateStatusHandler('processing')}
                  >
                    Ota käsittelyyn
                  </Button>
                ) : order.status === 'processing' ? (
                  <>
                    <Button
                      variant="primary"
                      disabled={actionInProgress}
                      onClick={updateStatusHandler('processed')}
                    >
                      Merkkaa valmiiksi
                      <br />
                      <small>(lähettää sähköpostin asiakkaalle)</small>
                    </Button>
                    <Button
                      variant="default"
                      disabled={actionInProgress}
                      onClick={updateStatusHandler('submitted')}
                    >
                      Palauta uudeksi
                    </Button>
                  </>
                ) : order.status === 'processed' ? (
                  <>
                    <Button
                      variant="primary"
                      disabled={actionInProgress}
                      onClick={updateStatusHandler('delivered')}
                    >
                      Merkkaa toimitetuksi
                    </Button>
                    <Button
                      variant="default"
                      disabled={actionInProgress}
                      onClick={updateStatusHandler('processing')}
                    >
                      Palauta valmiiksi
                    </Button>
                  </>
                ) : order.status === 'delivered' ? (
                  <>
                    <Button
                      variant="default"
                      disabled={actionInProgress}
                      onClick={updateStatusHandler('processed')}
                    >
                      Palauta käsitellyksi
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        }}
      </QueryLoader>
      <div className="mt-3 d-print-none">
        <a href="#print" onClick={onPrint(setIsPrinting, setBakedImageURLs)}>
          Tulosta
        </a>
      </div>
      <div className="my-2 d-print-none">
        <Link to="/hallinta/tilaukset">&laquo; Takaisin tilauslistaan</Link>
      </div>
    </div>
  );
};

const OrderList = () => {
  return (
    <QueryLoader<AdminGetOrders> query={ADMIN_GET_ORDERS}>
      {({ orders }) => (
        <div>
          <Table
            keyField="id"
            data={orders}
            columns={[
              {
                field: 'id',
                title: 'ID',
                link: (row) => `/hallinta/tilaukset/${row.id}`,
              },
              {
                field: 'user.displayName',
                title: 'Asiakas',
                link: (row) => `/hallinta/asiakkaat/${row.user.id}`,
              },
              {
                field: 'status',
                title: 'Tila',
                formatter: (col) => orderStatusToText(col),
              },
              {
                field: 'orderedGallery',
                title: 'PSV-Galleria',
                formatter: (col) => (col ? 'kyllä' : 'ei'),
              },
              {
                field: 'submittedAt',
                title: 'Lähetetty',
              },
            ]}
            search
            pagination
          />
        </div>
      )}
    </QueryLoader>
  );
};

const orderStatuses: Record<string, string> = {
  pending: 'ostoskorissa',
  submitted: 'tilattu',
  processing: 'käsittelyssä',
  processed: 'käsitelty',
  delivered: 'toimitettu',
};
const orderStatusToText = (status: string): string => {
  return orderStatuses[status] || status;
};

export default AdminOrdersPage;
