const TOKEN_STORAGE_KEY = 'omapsv-auth-token'; // TODO: don't duplicate
const API_BASE_URL =
  (process.env.REACT_APP_BACKEND_URI || '') + '/admin/api/v1';

export interface ApiRequestOpts {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: Record<string, any>;
  qs?: Record<string, string | number | boolean>;
  multipart?: boolean;
  cache?:
    | 'default'
    | 'no-store'
    | 'reload'
    | 'no-cache'
    | 'force-cache'
    | 'only-if-cached';
}
export async function apiRequest<T = any>(
  path: string,
  opts: ApiRequestOpts = {},
): Promise<T> {
  let body: any = undefined;
  if (opts.data) {
    if (opts.multipart) {
      body = new FormData();
      for (const [key, value] of Object.entries(opts.data)) {
        body.append(key, value);
      }
    } else {
      body = JSON.stringify(opts.data);
    }
  }
  const requestOpts = {
    method: opts.method || 'GET',
    headers: new Headers([['Accept', 'application/json']]),
    cache: opts?.cache || 'default',
    body,
  };
  if (!opts.multipart) {
    requestOpts.headers.set('Content-Type', 'application/json');
  }

  const authHeader = getAuthHeader();
  if (authHeader) {
    requestOpts.headers.set('Authorization', authHeader);
  }

  const qs = opts.qs || null;
  const urlStr = `${API_BASE_URL}${path}`;
  const url = new URL(urlStr, window.location.toString());
  if (qs) {
    Object.keys(qs).forEach((key) =>
      url.searchParams.append(key, qs[key] as any),
    );
  }

  const response = await fetch(url as any, requestOpts);
  if (!response.ok) {
    console.error('Invalid response:', response);
    let responseText = await response.text();
    try {
      const responseJson = JSON.parse(responseText);
      if (responseJson.message) {
        responseText = responseJson.message;
      }
    } catch (e) {
      console.warn('Response was not JSON:', responseText, response);
    }
    throw Error(responseText);
  }
  return await response.json();
}

export function getAuthHeader(): string {
  const authToken = localStorage.getItem(TOKEN_STORAGE_KEY);
  if (authToken) {
    return `Token ${authToken}`;
  }
  return '';
}
