import * as React from 'react';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import { Link } from '@reach/router';

import { GetShoppingCart } from '../types/__generated__/GetShoppingCart';
import { GET_SHOPPING_CART } from '../types/shoppingCart';
import {
  EditShoppingCartItem,
  EditShoppingCartItemVariables,
} from '../types/__generated__/EditShoppingCartItem';
import { EDIT_SHOPPING_CART_ITEM } from '../types/shoppingCart';
import { shoppingCartOpenVar } from '../uiState';
import Trash from '../img/icons/trash.svg';

import Price from './Price';
import './ShoppingCartSidebar.scss';

const ShoppingCartSidebar = () => {
  const shoppingCartOpen = useReactiveVar(shoppingCartOpenVar);
  const { data } = useQuery<GetShoppingCart>(GET_SHOPPING_CART);
  const ref = useRef(null);
  const [editShoppingCartItem] = useMutation<
    EditShoppingCartItem,
    EditShoppingCartItemVariables
  >(EDIT_SHOPPING_CART_ITEM);

  useEffect(() => {
    if (!shoppingCartOpen) {
      return;
    }

    const handleOutsideClick = (e: MouseEvent) => {
      if (!shoppingCartOpen) {
        return;
      }
      if (ref.current && !(ref.current as any).contains(e.target)) {
        shoppingCartOpenVar(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [shoppingCartOpen, ref]);

  const { shoppingCart } = data || {};
  if (!shoppingCart) {
    return null;
  }

  return (
    <aside
      ref={ref}
      className={classNames([
        'ShoppingCartSidebar',
        {
          open: shoppingCartOpen,
          closed: !shoppingCartOpen,
        },
      ])}
    >
      <h3>Ostoskori</h3>
      <div className="content">
        <ul className="items">
          {shoppingCart.items.map((item) => {
            const photo = item.photo;
            return (
              <li key={item.id} className="item">
                <button
                  className="remove"
                  aria-label="Poista"
                  onClick={() => {
                    editShoppingCartItem({
                      variables: {
                        item: item.id,
                        quantity: 0,
                      },
                    });
                  }}
                >
                  <img src={Trash} alt="Poista" />
                </button>
                <div
                  className={`thumbnail photocolor-${item.photoColor}`}
                  style={{
                    backgroundImage: `url(${photo.previewUrl})`,
                  }}
                />
                <div className="details">
                  <div className="photo-code">{photo.code}</div>
                  <div className="text-muted">{item.product.category.name}</div>
                  <div className="text-muted">Määrä: {item.quantity} kpl</div>
                </div>
                <div className="price">
                  <Price>{item.price}</Price>
                </div>
              </li>
            );
          })}
        </ul>
        {shoppingCart.items.length > 0 && (
          <div className="retouching-fee-container">
            Kuvien käsittelymaksu{' '}
            <Price>{shoppingCart.totalRetouchingFee}</Price>
          </div>
        )}
        <div className="total-price-container">
          <strong>Ostoskori yhteensä</strong>
          <span className="total-price">
            <Price>{shoppingCart.totalPrice}</Price>
          </span>
        </div>
      </div>
      <footer>
        <Button
          variant="light"
          block
          onClick={() => shoppingCartOpenVar(false)}
        >
          Jatka ostoksia
        </Button>
        <Link to="/tilaa">
          <Button
            variant="primary"
            block
            onClick={() => {
              shoppingCartOpenVar(false);
            }}
          >
            Siirry kassalle
          </Button>
        </Link>
      </footer>
    </aside>
  );
};
export default ShoppingCartSidebar;
