import { PricingModel } from '../types/__generated__/globalTypes';

interface PriceGroup {
  minQuantity: number;
  unitPrice: string;
}

export function calculatePrice(
  pricingModel: PricingModel,
  priceGroups: PriceGroup[],
  quantity: number,
  numPhotos?: number,
  freePhotos: number = 0,
): number {
  let paidQuantity: number = quantity;
  if (pricingModel === PricingModel.unit) {
    if (freePhotos > 0) {
      paidQuantity -= freePhotos;
    }
    if (paidQuantity <= 0) {
      return 0;
    }

    let unitPrice = null;
    for (const priceGroup of priceGroups) {
      if (priceGroup.minQuantity <= quantity) {
        unitPrice = priceGroup.unitPrice;
      }
    }
    if (unitPrice) {
      return parseFloat(unitPrice) * paidQuantity;
    }
  } else if (pricingModel === PricingModel.bracket) {
    let totalPrice = 0;
    priceGroups = [...priceGroups]; // don't want to mutate this
    let currentPriceGroup = priceGroups.shift();
    if (!currentPriceGroup) {
      return NaN;
    }
    let nextPriceGroup = priceGroups.shift();
    for (let i = 1; i <= quantity; i++) {
      if (nextPriceGroup && i >= nextPriceGroup.minQuantity) {
        currentPriceGroup = nextPriceGroup;
        nextPriceGroup = priceGroups.shift();
      }
      totalPrice += parseFloat(currentPriceGroup.unitPrice);
    }
    return totalPrice;
  } else if (
    pricingModel === PricingModel.per_photo_unit ||
    pricingModel === PricingModel.per_photo_bracket
  ) {
    if (!numPhotos) {
      return NaN;
    }
    const price = calculatePrice(
      pricingModel === PricingModel.per_photo_unit
        ? PricingModel.unit
        : PricingModel.bracket,
      priceGroups,
      numPhotos,
      0,
      freePhotos,
    );
    return price * paidQuantity;
  }

  return NaN;
}
