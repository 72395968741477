import * as React from 'react';
import Badge from 'react-bootstrap/Badge';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from '@reach/router';
import { gql, useApolloClient, useQuery } from '@apollo/client';

import * as GetSiteHeaderDataTypes from './__generated__/GetSiteHeaderData';
import Logo from './Logo';
import { SHOPPING_CART_FRAGMENT } from '../types/shoppingCart';
import { CURRENT_USER_FRAGMENT } from '../types/users';
import shoppingBasketIcon from '../img/icons/shopping-basket.svg';
import './SiteHeader.scss';
import { customerServiceModalOpenVar, shoppingCartOpenVar } from 'uiState';

const GET_SITE_HEADER_DATA = gql`
  query GetSiteHeaderData {
    me {
      ...CurrentUser
    }
    shoppingCart {
      ...ShoppingCart
    }
  }
  ${CURRENT_USER_FRAGMENT}
  ${SHOPPING_CART_FRAGMENT}
`;

const SiteHeader = () => {
  const { data } =
    useQuery<GetSiteHeaderDataTypes.GetSiteHeaderData>(GET_SITE_HEADER_DATA);
  const apolloClient = useApolloClient();

  const currentUser = data?.me;
  const shoppingCart = data?.shoppingCart;

  return (
    <header className="SiteHeader" role="banner">
      <div className="navbar-wrapper">
        <Navbar expand="lg">
          <Navbar.Brand as={Link} to="/">
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="siteheader-nav" />
          <Navbar.Collapse id="siteheader-nav">
            <Nav className="nav-left mr-auto">
              <Nav.Link as={Link} to="/kuvat">
                Omat kuvat
              </Nav.Link>
              <Nav.Link as={Link} to="/kuvatuotteet">
                Kuvatuotteet
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="#"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  customerServiceModalOpenVar(true);
                }}
              >
                Asiakaspalvelu
              </Nav.Link>
            </Nav>
            <Nav className="nav-right">
              <Nav.Link as={Link} to="/tilaa" className="highlighted">
                Tilaa
              </Nav.Link>
              <Nav.Link
                className="shopping-cart"
                onClick={() => {
                  shoppingCartOpenVar(!shoppingCartOpenVar());
                }}
              >
                <span className="d-sm-none">Ostoskori </span>
                <img
                  src={shoppingBasketIcon}
                  className="icon"
                  alt="Ostoskori"
                />
                {shoppingCart?.quantity ? (
                  <Badge variant="dark">{shoppingCart.quantity}</Badge>
                ) : null}
              </Nav.Link>
              {currentUser && (
                <NavDropdown id="user-dropdown" title={currentUser.displayName}>
                  {currentUser.isAdmin && (
                    <NavDropdown.Item as={Link} to="/hallinta">
                      Hallinta
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Link} to="/omat-tiedot">
                    Omat tiedot
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      localStorage.removeItem('omapsv-auth-token');
                      apolloClient.cache.reset();
                      window.location.href = '/';
                    }}
                  >
                    Kirjaudu ulos
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default SiteHeader;
