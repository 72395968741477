import * as React from 'react';
import { Link } from '@reach/router';
import IconButton from 'components/elements/IconButton';
import { GetPhotoshoot_photoshoot as Photoshoot } from 'components/__generated__/GetPhotoshoot';
import { Photo } from 'types/__generated__/Photo';
import { PhotoColor } from 'types/__generated__/globalTypes';
import { Position, useMousePosition } from 'utils/hooks';

import './PhotoDisplay.scss';
import classNames from 'classnames';

const MAX_ZOOM_LEVEL = 20; //x10 Percent
const ESCAPE_KEY = 'Escape';
const keyEventListener =
  (code: String, action: () => void) => (event: KeyboardEvent) => {
    switch (event.code) {
      case code:
        action();
        break;
      default:
        break;
    }
  };

interface PhotoDisplayProps {
  selectedPhoto: Photo;
  previousPhoto?: Photo;
  nextPhoto?: Photo;
  photoColor?: PhotoColor | null;
  photoshoot?: Photoshoot;
}
const PhotoDisplay = ({
  selectedPhoto,
  previousPhoto,
  nextPhoto,
  photoColor,
  photoshoot,
}: PhotoDisplayProps) => {
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
  const [lastPosition, setLastPosition] = React.useState<Position>({
    x: 0,
    y: 0,
  });
  const [draggingFrom, setDraggingFrom] = React.useState<Position | undefined>(
    undefined,
  );
  const cursorPosition: Position = useMousePosition();
  const [zoomLevel, setZoomLevel] = React.useState<number>(0);
  const toggleFullscreen = (value: boolean) => {
    setIsFullscreen(value);
    setZoomLevel(0);
  };
  const escapeAction = keyEventListener(ESCAPE_KEY, () =>
    toggleFullscreen(false),
  );

  const zoomPct: number = 100 + zoomLevel * 10;
  const backgroundSize: string = zoomLevel <= 0 ? 'contain' : `${zoomPct}%`;
  const backgroundX: number = draggingFrom
    ? cursorPosition.x - draggingFrom.x + lastPosition.x
    : lastPosition.x;
  const backgroundY: number = draggingFrom
    ? cursorPosition.y - draggingFrom.y + lastPosition.y
    : lastPosition.y;

  const backgroundPosition: string =
    zoomLevel <= 0 ? 'center' : `${backgroundX}px ${backgroundY}px`;

  React.useEffect(() => {
    // A "catch-all" handler to stop dragging if the cursor is out of bounds
    const onMouseUp = () => {
      setDraggingFrom(undefined);
    };

    // Bind ESC to exit full-screen mode
    document.addEventListener('keydown', escapeAction);
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('keydown', escapeAction);
      document.removeEventListener('mouseup', onMouseUp);
    };

    /**
     * Do not include escapeAction as dependencies, as it will cause rerenders after every mouse move.
     * This effect only needs to get triggered by the full-screen mode change.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);

  return (
    <div className="PhotoDisplay">
      <div
        className={classNames('image-container', {
          'photocolor-sepia': photoColor === PhotoColor.sepia,
          'photocolor-bw': photoColor === PhotoColor.bw,
          fullscreen: isFullscreen,
        })}
        style={{
          backgroundImage: `url(${selectedPhoto.fullSizeUrL})`,
          backgroundSize,
          backgroundPosition,
          // Disable touch-based page scroll for mobile devices when zooming in on a photo
          touchAction: zoomLevel > 0 ? 'none' : 'auto',
        }}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          return zoomLevel && setDraggingFrom({ x: e.clientX, y: e.clientY });
        }}
        onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => {
          if (!zoomLevel) {
            return;
          }
          setDraggingFrom({
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
          });
        }}
        onMouseUp={() => {
          setDraggingFrom(undefined);
          setLastPosition({ x: backgroundX, y: backgroundY });
        }}
        onTouchEnd={() => {
          setDraggingFrom(undefined);
          setLastPosition({ x: backgroundX, y: backgroundY });
        }}
      >
        <div className="image-controls">
          <IconButton
            icon={isFullscreen ? 'arrows-contract' : 'arrows-fullscreen'}
            onClick={() => toggleFullscreen(!isFullscreen)}
          />
          <IconButton
            icon="plus"
            onClick={() =>
              zoomLevel < MAX_ZOOM_LEVEL && setZoomLevel(zoomLevel + 1)
            }
          />
          <IconButton
            icon="minus"
            onClick={() => {
              if (zoomLevel <= 0) {
                return;
              }
              // Reset all previous dragging when zoom is disabled
              if (zoomLevel - 1 === 0) {
                setLastPosition({ x: 0, y: 0 });
              }
              setZoomLevel(zoomLevel - 1);
            }}
          />
          <div className="photo-code">{selectedPhoto.code}</div>
        </div>
        {previousPhoto && !zoomLevel && (
          <Link
            to={`/kuvat/${photoshoot?.slug}/${previousPhoto.code}`}
            className="photo-switcher previous"
            aria-label="Edellinen"
          />
        )}
        {nextPhoto && !zoomLevel && (
          <Link
            to={`/kuvat/${photoshoot?.slug}/${nextPhoto.code}`}
            className="photo-switcher next"
            aria-label="Seuraava"
          />
        )}
      </div>
    </div>
  );
};
export default PhotoDisplay;
